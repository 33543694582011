import { FormControl, FormHelperText, OutlinedInput, Typography } from '@material-ui/core';
import { Error, InfoOutlined } from '@material-ui/icons';
import { Tooltip } from 'components/Display';
import React, { useEffect, useRef, useState } from 'react';
import { ChangeEvent } from 'react-transition-group/node_modules/@types/react';
import { useTextStyles } from 'styles';

const defaultProps = {
  error: null,
  placeholder: null,
  maxLength: 100,
  id: '',
  disabled: false,
  tooltip: false,
  tooltipMessage: '',
  type: 'text',
  maxValue: 999,
  oldValue: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleBlur: () => {},
};

const TextField = ({
  label,
  value,
  type,
  error,
  placeholder,
  handleChange,
  handleBlur,
  maxLength,
  maxValue,
  oldValue,
  id,
  disabled,
  tooltip,
  tooltipMessage,
}: {
  label: string;
  id?: string;
  value: string;
  error?: string;
  type?: string;
  placeholder?: string;
  maxLength?: number;
  maxValue?: number;
  oldValue?: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: boolean;
  tooltipMessage?: string;
}): JSX.Element => {
  const classes = useTextStyles();
  const [isMaxLength, setIsMaxLength] = useState(false);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  // @ts-expect-error maxLength will always be defined
  const valueLength = value ? value.length : 0 / maxLength;
  // Highlights the total letter count to red when typing past maxLength characters
  const handleInputValidation = () => {
    setIsMaxLength(true);
    setTimeout(() => {
      setIsMaxLength(false);
    }, 1000);
  };

  useEffect(() => {
    if (error) {
      inputRef.current.focus();
    }
  }, [error]);
  return (
    <>
      <Typography
        color={error ? 'error' : 'primary'}
        data-testid="text-field-label"
        variant="body1"
        classes={{
          root: classes.label,
        }}
        {...(!!error && {
          style: {
            marginBottom: 0,
          },
        })}
      >
        {label}
        {tooltip && (
          <Tooltip message={tooltipMessage as string}>
            <InfoOutlined color="disabled" fontSize="small" style={{ marginLeft: 8 }} />
          </Tooltip>
        )}
        {!error && type !== 'number' && (
          <Typography
            variant="overline"
            title="character-counter"
            style={{
              color: isMaxLength ? '#E02B27' : '',
              position: 'relative',
              margin: 0,
              marginLeft: 'auto',
            }}
          >
            {`${valueLength} / ${maxLength}`}
          </Typography>
        )}
        {type === 'number' && (
          <Typography
            variant="overline"
            title="character-counter"
            style={{
              color: '',
              position: 'relative',
              margin: 0,
              marginLeft: 'auto',
            }}
          >
            {`${oldValue} / ${maxValue}`}
          </Typography>
        )}
      </Typography>
      <FormControl error={!!error} fullWidth disabled={disabled}>
        {!!error && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormHelperText
              data-testid="text-field-helper-text"
              style={{
                position: 'relative',
                margin: 0,
                bottom: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Error style={{ fontSize: 'inherit', marginRight: 4 }} />
              {error}
            </FormHelperText>
            <FormHelperText
              style={{
                position: 'relative',
                margin: 0,
                bottom: 2,
                marginLeft: 'auto',
              }}
            >
              {`${valueLength} / ${maxLength}`}
            </FormHelperText>
          </div>
        )}
        <OutlinedInput
          data-testid={id}
          id={id}
          inputRef={inputRef}
          margin="dense"
          value={value}
          type={type}
          placeholder={placeholder}
          inputProps={{
            'data-testid': 'text-field-input',
            'aria-valuetext': value,
            'data-cy': `text-field-input-${id}`,
            maxLength,
          }}
          onKeyPress={valueLength === maxLength ? handleInputValidation : () => undefined}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormControl>
    </>
  );
};

TextField.defaultProps = defaultProps;

export default TextField;
