import { makeStyles } from '@material-ui/core';
import { EditMode } from 'context/list-context';
import { BG_GRAY, WHITE } from 'themes/customTheme';

type StyleProps = {
  level: number;
  isRoot?: boolean;
  isChildless: boolean;
  isParentSelected?: boolean;
  editMode: EditMode | null;
};

const useCategoryListItemStyles = makeStyles({
  // Hide details button by default
  container: {
    '& div > button.details-button': {
      display: 'none',
    },
    // Show details button on hover
    '&:hover': {
      '& div > button.details-button': {
        display: 'flex',
        // Apply background on hover to obscure status indicator
        background: '#F0F0F0',
      },
      '& .badge.badge-normal > span': {
        backgroundColor: WHITE,
      },
    },
  },
  root: ({ level, isParentSelected, editMode }: StyleProps) => {
    let paddingLeft = 32 * level;
    if (editMode === EditMode.sort) {
      paddingLeft = 0;
    }

    return {
      width: 'auto',
      height: 50,
      paddingLeft,
      paddingRight: 48,
      // Apply highlighting to child items of selected category
      ...(isParentSelected && { backgroundColor: BG_GRAY }),
      '& .category-item-text': {
        padding: '0 8px 0 0',
      },
      '& .list-item-icon': {
        minWidth: 24,
      },
    };
  },
});

export default useCategoryListItemStyles;
