import { makeStyles } from '@material-ui/core';

const useTextStyles = makeStyles({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 2,
  },
  value: {
    fontSize: 16,
    fontWeight: 'normal',
    marginBottom: 24,
  },
});

export default useTextStyles;
