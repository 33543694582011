import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: 'unset !important',
    margin: '0 !important',
    flexGrow: 0.5,
  },
  form: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  gridItem: {},
  outlinedInput: {
    marginBottom: '0',
    paddingRight: 0,
  },
  input: {
    padding: '10px 4px 10px 4px',
    whiteSpace: 'nowrap',
  },
  clearButton: {
    right: 0,
    top: 3,
    height: 'auto',
    '& button': {
      textTransform: 'none',
      color: '#ACA9A9',
      fontWeight: 400,
    },
  },
});

const useStylesProductsPage = makeStyles({
  container: {
    width: '100% !important',
    margin: '0 !important',
    flex: 1,
  },
  form: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  gridItem: {
    width: '100%',
    paddingLeft: '0 !important',
  },
  outlinedInput: {
    marginBottom: '0',
    width: '100%',
  },
  input: {
    padding: '10px 4px 10px 4px',
    whiteSpace: 'nowrap',
  },
  clearButton: {
    right: 0,
    top: 3,
    height: 'auto',
    '& button': {
      textTransform: 'none',
      color: '#ACA9A9',
      fontWeight: 400,
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useStyles, useStylesProductsPage };
