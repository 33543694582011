import { useAnalyticsService } from 'admin-portal-shared-services';
import config from 'countries.config.json';
import { CountryCode, ItemSelection } from 'types';

import segmentAnalytics, {
  CategoryPosition,
  CategoryStatus,
  CategoryType,
  ProductsItem1,
  CategoryLevel,
  CategoryItem4,
  CategoryItem9,
  CountryName,
  CategoryCountry,
  CategoryItem,
  ScreenName,
} from '../../analytics';

type CategoriesItem9 = {
  level: CategoryLevel;
  status: string;
  name: string;
};

type SegmentEventProps = {
  newCategoryStarted: (props?: any) => void;
  newCategoryCreated: (props?: any) => void;
  productsAssignStarted: (props?: any) => void;
  productsAssignApplied: (props?: any) => void;
  categoryEditionStarted: (props?: any) => void;
  categoryEditionApplied: (props?: any) => void;
  categoryProductsEditionStarted: (props?: any) => void;
  categoryProductsEditionApplied: (props?: any) => void;
  productFilterCategorized: (props?: any) => void;
};

const SegmentEvents: SegmentEventProps = {
  newCategoryStarted: segmentAnalytics.newCategoryStarted,
  newCategoryCreated: segmentAnalytics.newCategoryCreated,
  productsAssignStarted: segmentAnalytics.productsAssignStarted,
  productsAssignApplied: segmentAnalytics.productsAssignApplied,
  categoryEditionStarted: segmentAnalytics.categoryEditionStarted,
  categoryEditionApplied: segmentAnalytics.categoryEditionApplied,
  categoryProductsEditionStarted: segmentAnalytics.categoryProductsEditionStarted,
  categoryProductsEditionApplied: segmentAnalytics.categoryProductsEditionApplied,
  productFilterCategorized: segmentAnalytics.productFilterCategorized,
};

const useSegmentAnalytics = (): {
  initSegment: (segmentKey: string) => void;
  triggerEvent: <T>(eventName: keyof typeof SegmentEvents, props?: T) => void;
  parseCountry: (code: CountryCode) => CountryName;
  parseCategoryPosition: (position: string) => CategoryPosition;
  parseCategoryEnabled: (enabled: boolean) => string;
  parseCategoryType: (type: string) => string;
  parseCategoryProducts: (products: any[]) => ProductsItem1[];
  parseCategoryLevel: (level: number) => CategoryLevel;
  parseCategoryLevelByParents: (parentCategories: any[]) => CategoryLevel;
  parseCategoryItem4: (categories: any[]) => CategoryItem4[];
  parseCategoryItem: (category: any) => CategoryItem[];
  parseCategoryLevelByPath: (parentCategories: any[]) => CategoryLevel;
  getScreenName: () => string;
  parseCategoryItem9: ({
    categories,
    country,
    store,
  }: {
    categories: CategoriesItem9[];
    country: CategoryCountry;
    store: string;
  }) => CategoryItem9[];
} => {
  const initSegment = (segmentKey: string): void => {
    const analyticsService = useAnalyticsService();
    analyticsService.load(segmentKey);
  };

  const triggerEvent = <T>(eventName: keyof typeof SegmentEvents, props?: T): void => {
    const event = SegmentEvents[eventName];
    if (props) {
      event(props);
    } else {
      event();
    }
  };

  const parseCountry = (code: CountryCode): CountryName => {
    const countryName = config[code].name as keyof typeof CountryName;
    return CountryName[countryName];
  };

  const parseCategoryPosition = (position: string): CategoryPosition => {
    return position === 'top' ? CategoryPosition.Top : CategoryPosition.Bottom;
  };

  const parseCategoryEnabled = (enabled: boolean): CategoryStatus => {
    return enabled ? CategoryStatus.Enable : CategoryStatus.Disable;
  };

  const parseCategoryType = (type: string): string => {
    switch (type) {
      case 'club_b_max':
        return CategoryType.ClubBMax;
      case 'premium':
        return CategoryType.Premium;
      default:
        return CategoryType.Default;
    }
  };

  const parseCategoryProducts = (products: ItemSelection[]): ProductsItem1[] => {
    const result: ProductsItem1[] = [];

    for (const product of products) {
      result.push({
        platform_id: product.itemPlatformId || '',
        vendor_id: product.vendorId || '',
        vendor_item_id: product.vendorItemId || '',
      });
    }

    return result;
  };

  const parseCategoryLevel = (level: number): CategoryLevel => {
    switch (level) {
      case 1:
        return CategoryLevel.Level1;
      case 2:
        return CategoryLevel.Level2;
      default:
        return CategoryLevel.Level3;
    }
  };

  const parseCategoryLevelByParents = (parentCategories: any[]): CategoryLevel => {
    const [parentCategory] = parentCategories;

    const level = parentCategory?.level ? parentCategory?.level : 0;

    switch (level) {
      case 2:
        return CategoryLevel.Level3;
      case 1:
        return CategoryLevel.Level2;
      default:
        return CategoryLevel.Level1;
    }
  };

  const parseCategoryItem9 = ({
    categories,
    country,
    store,
  }: {
    categories: CategoriesItem9[];
    country: CategoryCountry;
    store: string;
  }): CategoryItem9[] => {
    const result: CategoryItem9[] = [];

    for (const category of categories) {
      result.push({
        category_country: parseCountry(country),
        category_level: category.level,
        category_name: category.name,
        category_status:
          category.status === 'Default.enabled' ? CategoryStatus.Enable : CategoryStatus.Disable,
        category_store: store,
      });
    }

    return result;
  };

  const parseCategoryItem4 = (categories: any[]): CategoryItem4[] => {
    return categories?.map((category) => {
      return {
        category_name: category.name,
        category_status: parseCategoryEnabled(category.enabled),
        category_level: parseCategoryLevel(category.level),
      } as any as CategoryItem4;
    });
  };

  const parseCategoryLevelByPath = (parentCategories: any[]): CategoryLevel => {
    const parent = parentCategories.reduce((acc, curr) => {
      return acc.level > curr.level ? acc : curr;
    }, 0);

    switch (parent?.level) {
      case 0:
        return CategoryLevel.Level2;
      case 1:
        return CategoryLevel.Level3;
      default:
        return CategoryLevel.Level1;
    }
  };

  const parseCategoryItem = (category: any): CategoryItem[] => {
    return {
      category_name: category?.name,
      category_store: category?.storeCategoryId,
      category_translation: category?.translations?.map((translation: any) => {
        return {
          translation_name: translation.name,
          translation_language: translation.language,
        };
      }),
    } as any as CategoryItem[];
  };

  const getScreenName = (): string => {
    return ScreenName?.CategoryAdmin;
  };

  return {
    initSegment,
    triggerEvent,
    parseCountry,
    parseCategoryPosition,
    parseCategoryEnabled,
    parseCategoryType,
    parseCategoryProducts,
    parseCategoryLevel,
    parseCategoryLevelByParents,
    parseCategoryItem4,
    parseCategoryItem9,
    parseCategoryItem,
    parseCategoryLevelByPath,
    getScreenName,
  };
};

export default useSegmentAnalytics;
