import { makeStyles } from '@material-ui/core';
import { BORDER_GRAY, DISABLED_RED, NOT_FOUND_WARNING, WHITE } from 'themes/customTheme';

const useTableStyles = makeStyles({
  root: {
    // Overrides right alignment of last cell from default theme
    '& .left-aligned': {
      textAlign: 'left',
    },
    '& .center-aligned': {
      textAlign: 'center',
    },
    '& .right-aligned': {
      textAlign: 'right',
    },
  },
  productsPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 7.5rem)',
  },
});

const useHeaderStyles = makeStyles({
  root: {
    '& th': {
      // Override stickyHeader styling
      borderTop: `1px solid ${BORDER_GRAY}`,
      borderBottom: `1px solid ${BORDER_GRAY}`,
      backgroundColor: WHITE,
      fontWeight: 500,
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: 14,
      // Remove styling for checkboxes in header
      '& span': {
        color: '#000',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

const useRowStyles = makeStyles({
  root: {
    borderBottom: `1.02px solid ${BORDER_GRAY}`,
    '& td > button:focus:hover': {
      color: '#4D6EDB ',
    },
    '& td': {
      padding: '8px 16px',
      // Override maxWidth: 0 from default theme
      maxWidth: 'none',
      '& button:hover': {
        background: 'transparent',
      },
    },
  },
  deleted: {
    '& td': {
      color: '#A7A7A7',
    },
    '& div': {
      color: '#A7A7A7',
      '& img': {
        opacity: 0.5,
      },
      '& span': {
        color: DISABLED_RED,
      },
    },
  },
  notFound: {
    '& td': {
      color: '#A7A7A7',
    },
    '& div': {
      color: '#A7A7A7',
      '& img': {
        opacity: 0.5,
      },
      '& span': {
        color: NOT_FOUND_WARNING,
      },
    },
  },
  statusDefault: {},
  skuCell: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  notFoundSkuCell: {
    position: 'relative',
    left: '-24px',
  },
  notFoundIcon: {
    color: NOT_FOUND_WARNING,
    paddingRight: '5px',
  },
});

const usePaginationStyles = makeStyles({
  root: {
    margin: '20px 0',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${BORDER_GRAY}`,
    borderBottom: `1px solid ${BORDER_GRAY}`,
  },
  infoAllVendors: {
    width: '100%!important',
    marginTop: '16px',
  },
});

const useTablePaperStyles = makeStyles({
  paper: {
    height: 'calc(100% - 8rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export { useHeaderStyles, useTableStyles, useRowStyles, usePaginationStyles, useTablePaperStyles };
