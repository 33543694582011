/* istanbul ignore file */
import { useSidebar } from 'admin-portal-shared-services';
import React, { ReactNode } from 'react';

export interface SideBarItem {
  id: string;
  title: string;
  icon: React.ElementType;
  path: string;
}

interface AppbarConfigProps {
  children: ReactNode;
  sidebarItems: Array<SideBarItem>;
}

const AppbarConfig = ({ children, sidebarItems }: AppbarConfigProps): JSX.Element => {
  useSidebar({
    utils: [],
    items: sidebarItems,
  });

  return <>{children}</>;
};

export default AppbarConfig;
