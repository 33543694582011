import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { ItemSelection, MarketplaceCategory, Props } from 'types';
import { useToggleContext } from './toggle-context';

const initialState: NewCategoryState = {
  name: '',
  enabled: true,
  categoryLevel: 1,
  imgFile: null,
  position: 'bottom',
  parentCategories: [],
  items: [],
  translations: {},
  status: {
    categoryNameError: false,
    parentCategoryError: false,
    premiumError: false,
    statusError: false,
    storeCategoryIdError: false,
    success: false,
    hasChanged: false,
  },
  categoryType: 'default',
  categoryGroup: 'MAIN',
  storeCategoryId: '',
  premium: '',
};

const NewCategoryContext = createContext<{
  state: NewCategoryState;
  dispatch: Dispatch<NewCategoryActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});
NewCategoryContext.displayName = 'NewCategoryContext';

type NewCategoryStateKeys = {
  [name: string]: any;
};

export type NewCategoryState = {
  name: string;
  enabled: boolean;
  imgFile: File | null;
  categoryLevel: number;
  position: string;
  parentCategories: MarketplaceCategory[];
  items: ItemSelection[];
  translations: CategoryName;
  status: {
    // Separate errors to allow for correct reference when focusing
    categoryNameError: boolean;
    parentCategoryError: boolean;
    premiumError: boolean;
    storeCategoryIdError: boolean;
    success: boolean;
    hasChanged: boolean;
    statusError: boolean;
    errorMessageKey?: string;
  };
  categoryType: string;
  categoryGroup: string;
  premium: string;
  storeCategoryId: string;
} & NewCategoryStateKeys;

type CategoryName = {
  [name: string]: string;
};

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type UpdateFieldAction = Action & {
  payload: { name: string; value: string | number | boolean | File | null };
};

type ErrorAction = Action & {
  payload: string[];
};

type UpdateParentCategoryAction = Action & {
  type: string;
  payload: MarketplaceCategory;
};

type UpdateCategoryNamesAction = Action & {
  type: string;
  payload: { language: string; name: string };
};

type NewCategoryActions =
  | UpdateFieldAction
  | ErrorAction
  | Action
  | UpdateParentCategoryAction
  | UpdateCategoryNamesAction;

const stateHasChanged = (fieldName: string, fieldValue: any): boolean =>
  Array.isArray(fieldValue)
    ? fieldValue.length === initialState[fieldName].length
    : fieldValue === initialState[fieldName];

const newCategoryReducer = (
  state: NewCategoryState,
  action: NewCategoryActions
): NewCategoryState => {
  const { type, payload } = action;
  switch (type) {
    case 'field updated': {
      const fieldName = payload?.name;
      const fieldValue = payload?.value;
      const isPremium = fieldName === 'categoryType' && fieldValue === 'premium';
      const isClubB = fieldName === 'categoryType' && fieldValue.includes('club_b');
      return {
        ...state,
        // Set category level to 1 on premium type categories
        ...(isPremium && { categoryLevel: 1, premium: '' }),
        // Set premium value to club_b on select
        ...(isClubB && { premium: fieldValue }),
        [`${fieldName}`]: fieldValue,
        status: {
          ...state.status,
          hasChanged: !stateHasChanged(fieldName, fieldValue),
        },
      };
    }
    case 'fields reset':
      return initialState;
    case 'field level reset':
      return {
        ...state,
        categoryLevel: 1,
      };
    case 'empty field error':
      return {
        ...state,
        status: {
          // TODO: Come up with a better way to handle field validation errors
          ...state.status,
          categoryNameError: payload?.error === 'categoryNames',
          parentCategoryError: payload?.error === 'parentCategories',
          premiumError: payload?.error === 'premium',
          storeCategoryIdError: payload?.error === 'storeCategoryId',
          ...(payload?.error && { errorMessageKey: 'Default.requiredField' }),
        },
      };
    case 'invalid premium key':
      return {
        ...state,
        status: {
          ...state.status,
          premiumError: payload?.error === 'premium',
          errorMessageKey: 'CategoryDetails.invalidPremiumKey',
        },
      };
    case 'update parent category': {
      let newCategories = [...state.parentCategories];
      if (newCategories.find((item) => item.id === payload?.id)) {
        newCategories = newCategories.filter((item) => item.id !== payload?.id);
      } else {
        newCategories = [...newCategories, payload];
      }
      return {
        ...state,
        status: {
          ...state.status,
          parentCategoryError: false,
          hasChanged: true,
        },
        parentCategories: newCategories,
      };
    }
    case 'update category names': {
      const { language, name } = payload;
      const { categoryNameError } = state.status;
      return {
        ...state,
        translations: { ...state.translations, [language]: name },
        status: {
          ...state.status,
          hasChanged: !!name.trim().length,
          // Clear error state if valid input is made
          categoryNameError: categoryNameError && !name.trim().length,
        },
      };
    }
    case 'max enabled collection categories error':
      return {
        ...state,
        status: {
          ...state.status,
          statusError: payload?.error === 'status',
          errorMessageKey: 'CategoryCreation.maxCollectionsEnabledError',
        },
      };
    case 'status error reset':
      return {
        ...state,
        status: {
          ...state.status,
          statusError: false,
        },
      };
    case 'success':
      return {
        ...state,
        status: {
          categoryNameError: false,
          parentCategoryError: false,
          premiumError: false,
          storeCategoryIdError: false,
          statusError: false,
          success: true,
          hasChanged: true,
        },
      };
    default:
      return state;
  }
};

const useNewCategoryContext = (): {
  state: NewCategoryState;
  dispatch: Dispatch<NewCategoryActions>;
} => {
  return useContext(NewCategoryContext);
};

const NewCategoryProvider = ({ children }: Props): JSX.Element => {
  const {
    state: { retrocompabilityActive },
  } = useToggleContext();
  initialState.categoryGroup = retrocompabilityActive ? 'NAVIGATION' : 'MAIN';
  const [state, dispatch] = useReducer(newCategoryReducer, initialState);
  return (
    <NewCategoryContext.Provider value={{ state, dispatch }}>
      {children}
    </NewCategoryContext.Provider>
  );
};

const updateCategoryField = (
  dispatch: Dispatch<UpdateFieldAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatedField: { name: string; value: any }
): void => {
  dispatch({ type: 'field updated', payload: { ...updatedField } });
};

const resetNewCategoryFields = (dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'fields reset' });
};

const resetCategoryLevelField = (dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'field level reset' });
};

const resetStatusErrorField = (dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'status error reset' });
};

const updateParentCategories = (
  dispatch: Dispatch<UpdateParentCategoryAction>,
  parentCategory: MarketplaceCategory
): void => {
  dispatch({ type: 'update parent category', payload: { ...parentCategory } });
};

const updateCategoryNames = (
  dispatch: Dispatch<UpdateCategoryNamesAction>,
  categoryName: { language: string; name: string }
): void => {
  dispatch({ type: 'update category names', payload: { ...categoryName } });
};

export {
  NewCategoryContext,
  NewCategoryProvider,
  newCategoryReducer,
  resetCategoryLevelField,
  resetNewCategoryFields,
  resetStatusErrorField,
  updateCategoryField,
  updateCategoryNames,
  updateParentCategories,
  useNewCategoryContext,
};
