import { Button } from '@material-ui/core';
import { Check, Close, Edit } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditButtons = ({
  disabled = false,
  editing,
  handleEdit,
  handleCancel,
  handleSave,
  dataTestId = 'edit-button',
}: {
  disabled?: boolean;
  editing: boolean;
  handleEdit: () => void;
  handleCancel: () => void;
  handleSave: () => void;
  dataTestId?: string;
}): JSX.Element => {
  const { t: translate } = useTranslation();
  return !editing ? (
    <Button
      data-testid={dataTestId}
      onClick={handleEdit}
      disabled={disabled}
      color="primary"
      style={{ fontWeight: 'normal' }}
      startIcon={<Edit />}
    >
      {translate('Default.edit')}
    </Button>
  ) : (
    <>
      <Button
        data-testid="cancel-button"
        onClick={handleCancel}
        color="primary"
        style={{ fontWeight: 'normal' }}
        startIcon={<Close />}
      >
        {translate('Default.cancel')}
      </Button>
      <Button
        data-testid="save-button"
        onClick={handleSave}
        color="primary"
        style={{ fontWeight: 'normal' }}
        startIcon={<Check />}
      >
        {translate('Default.saveChanges')}
      </Button>
    </>
  );
};

export default EditButtons;
