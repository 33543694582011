import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ChangeEvent } from 'react-transition-group/node_modules/@types/react';
import { Pagination } from 'types';

const usePagination = (): {
  pagination: Pagination;
  handleChangePage: (event: ChangeEvent<unknown>, p: number) => void;
  handleChangePageSize: (event: ChangeEvent<unknown>) => void;
  setInitialPaginationState: (pagination: Pagination) => void;
  resetPagination: () => void;
} => {
  const defaultPaginationState: Pagination = useMemo(
    () => ({
      page: 0,
      pageSize: 25,
      totalPages: 1,
      totalElements: 0,
    }),
    []
  );
  const [pagination, setPagination] = useState(defaultPaginationState);
  const setInitialPaginationState = useCallback((newPagination: Pagination) => {
    setPagination(newPagination);
  }, []);
  const resetPagination = useCallback(() => {
    setPagination(defaultPaginationState);
  }, [defaultPaginationState]);
  const handleChangePage = _.debounce(
    useCallback(
      (_e, newPage) => {
        // API pagination is zero indexed
        setPagination({ ...pagination, page: newPage - 1 });
      },
      [pagination]
    ),
    250
  );
  const handleChangePageSize = useCallback(
    (e) => {
      const newPageSize = e.target.value;
      setPagination({ ...pagination, pageSize: newPageSize, page: 0 });
    },
    [pagination]
  );
  return {
    pagination,
    handleChangePage,
    handleChangePageSize,
    setInitialPaginationState,
    resetPagination,
  };
};

export default usePagination;
