import { Badge } from '@hexa-ui/components';
import React from 'react';
import { BadgeCategoryTypes } from 'types';

type Props = {
  label?: string;
  type?: 'default' | 'interactive';
  color?: BadgeCategoryTypes;
  disabled?: boolean;
};

const StatusBadge = ({ label, type, color, disabled = false }: Props): JSX.Element => {
  const { Status } = Badge;

  return (
    <Status
      data-testid="status-badge"
      label={label}
      type={type}
      color={color}
      disabled={disabled}
    />
  );
};

export default StatusBadge;
