import _ from 'lodash';

/**
 * Get the sort order of an item based on its desired position within the array and the current number of elements
 * @param items An array of objects
 * @param position Where a new item is meant to be placed in the array (top: index zero, bottom: last index)
 * @returns A number
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSortOrder = (items: any[], position?: string): number => {
  if (items.length) {
    if (position === 'bottom') {
      return (_.max(items.map((item) => item.sortOrder)) as number) + 1;
    }
    return 0;
  }
  return 0;
};

export default getSortOrder;
