export const CLUB_B_KEY = 'club_b';
export const CLUB_B_MAX_KEY = 'club_b_max';

// eslint-disable-next-line import/prefer-default-export
export const isCategoryClubB = (premiumKeys: string[]): boolean => {
  if (!premiumKeys) {
    return false;
  }

  return premiumKeys.some(
    (key) => key.toLowerCase() === CLUB_B_KEY || key.toLowerCase() === CLUB_B_MAX_KEY
  );
};
