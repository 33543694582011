import React, { createContext, Dispatch, useContext, useReducer } from 'react';

type MainToast = {
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
  show: boolean;
  duration: number;
};

type Toast = Pick<MainToast, 'severity' | 'text'>;

type Payload = { key?: number } & Partial<Pick<MainToast, 'severity' | 'text'>>;

export type ToastState = {
  toasts: Omit<MainToast, 'duration'>[];
} & Pick<MainToast, 'duration'>;

export type ToastActions = {
  type: string;
  payload: Payload;
};

const DURATION = 5000;
const initialState: ToastState = {
  toasts: [],
  duration: DURATION,
};

const ToastContext = createContext<{ state: ToastState; dispatch: Dispatch<ToastActions> }>({
  state: {} as ToastState,
  dispatch: () => undefined,
});
ToastContext.displayName = 'ToastContext';

const toastReducer = (state: ToastState, action: ToastActions): ToastState => {
  const { type, payload } = action;
  const response: ToastState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case 'show toast': {
      response.toasts.push({
        show: true,
        text: payload.text || '',
        severity: payload.severity || 'info',
      });
      return response;
    }
    case 'hide toast': {
      response.toasts.splice(payload.key || 0, 1);
      return response;
    }
    default:
      return response;
  }
};

const ToastProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const { children } = props;
  return <ToastContext.Provider value={{ state, dispatch }}>{children}</ToastContext.Provider>;
};

const useToastContext = (): { state: ToastState; dispatch: Dispatch<ToastActions> } => {
  return useContext(ToastContext);
};

const showToast = (dispatch: Dispatch<ToastActions>, toast: Toast): void => {
  dispatch({ type: 'show toast', payload: toast });
};

const hideToast = (dispatch: Dispatch<ToastActions>, key?: number): void => {
  dispatch({ type: 'hide toast', payload: { key } });
};

export { ToastProvider, useToastContext, showToast, hideToast, toastReducer, ToastContext };
