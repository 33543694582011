import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, FormatListBulleted, StarBorder } from '@material-ui/icons';
import { KeyIcon } from 'assets/icons';
import { Badge, CategoryBadge, StatusIndicator } from 'components/Display';
import { CategoryName } from 'components/MultiLanguage';
import SortableItem from 'components/SortableItem/SortableItem';
import { EditMode, useListContext } from 'context/list-context';
import { useCategoryListItem, useSubcategories } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketplaceCategory } from 'types';
import StatusBadge from 'components/Display/StatusBadge';
import { useToggleContext } from '../../context/toggle-context';

export type Props = {
  category: MarketplaceCategory;
  handleSelect?: (id: string) => void;
  setUpdatedSubcategories?: (array: MarketplaceCategory[]) => void;
  isEditing?: boolean;
  level?: number;
  isParentSelected?: boolean;
  parentId?: string;
  selected?: string[];
  dragRef?: undefined;
};

const defaultProps = {
  level: 1,
  isParentSelected: false,
  isEditing: false,
};

// TODO: Break up the list item into smaller components to reduce the cognitive complexity
const CategoryListItem = ({
  category,
  handleSelect,
  setUpdatedSubcategories,
  selected,
}: Props): JSX.Element => {
  const { t: translate } = useTranslation();
  const { id, status, root, restricted, subcategories, itemsCount } = category;
  const {
    state: { editMode },
  } = useListContext();
  const { handleMove, sortedSubcategories } = useSubcategories(
    subcategories,
    setUpdatedSubcategories
  );
  const isEmpty = !itemsCount && !subcategories?.length;
  const isPremium =
    Array.isArray(restricted) && !_.intersection(restricted, ['club_b', 'club_b_max']).length;
  const { isOpen, isSelected, handleClick, handleDetailsClick, listClasses } = useCategoryListItem({
    category,
    handleSelect,
    setUpdatedSubcategories,
    selected,
  });
  const {
    state: { bulkUpdateStatus },
  } = useToggleContext();

  const isSortingMode = editMode === EditMode.sort;
  // FIXME: melhorar a legibilidade
  const isSelectionMode =
    editMode === EditMode.select && selected && !subcategories?.length && !!handleSelect;

  const isEditingMode = bulkUpdateStatus && isSortingMode;

  return (
    <div style={{ width: '100%' }}>
      <ListItem
        id={`category-list-item-${id}`}
        data-testid="category-list-item"
        classes={listClasses}
        // @ts-expect-error TypeScript expects the shorthand
        button={!editMode}
        onClick={handleClick}
        selected={isSelected}
      >
        {(isSelectionMode || isEditingMode) && (
          <ListItemIcon className="list-item-icon">
            <Checkbox
              checked={selected?.includes(id as string)}
              color="primary"
              data-testid={`category-item-checkbox-${category.id}`}
              size="small"
              onChange={() => handleSelect && handleSelect(id as string)}
              onClick={(event) => {
                event.stopPropagation();
              }}
              // Enforce unselected checkbox styling
              {...(!selected?.includes(id as string) &&
                !isEditingMode && {
                  style: { color: '#B9BCC1' },
                })}
            />
          </ListItemIcon>
        )}
        {/* Only hide expand arrow in select mode on categories with no children */}
        {!(editMode === EditMode.select && !subcategories?.length) && (
          <IconButton style={{ padding: 8 }} disabled={!subcategories?.length}>
            {isOpen ? (
              <ExpandLess fontSize="small" data-testid={`expand-less-icon-${category.id}`} />
            ) : (
              <ExpandMore
                fontSize="small"
                style={{ color: !subcategories?.length ? '#DFDEDE' : '' }}
                data-testid={`expand-more-icon-${category.id}`}
              />
            )}
          </IconButton>
        )}
        <ListItemText
          primary={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="category-item-text">
                {/* TODO: Replace with translations */}
                <CategoryName category={category} />
              </span>
              {isPremium && (
                <Badge
                  value={<StarBorder />}
                  colors={{ defaultColor: '#D1F7F6' }}
                  tooltipContent={translate('CategoryHome.premiumCategory')}
                />
              )}
              {Array.isArray(restricted) &&
                !!_.intersection(restricted, ['club_b', 'club_b_max']).length && (
                  <Badge
                    colors={{ defaultColor: '#FFEBD0', hover: '#FFD7A0' }}
                    value="B"
                    tooltipContent={translate('CategoryHome.clubBProgram')}
                  />
                )}
              {(!!subcategories?.length || (root && isEmpty)) && (
                <CategoryBadge value={subcategories?.length || 0} type="category" />
              )}
              {(!root || !!itemsCount || (root && isEmpty)) && (
                <CategoryBadge
                  value={itemsCount}
                  error={!!subcategories?.length && !!itemsCount}
                  type="product"
                />
              )}
              {isPremium && (
                <>
                  <KeyIcon />
                  <span style={{ color: '#939191', fontSize: 16, marginLeft: 4, marginRight: 4 }}>
                    {restricted}
                  </span>
                </>
              )}
              {category.stagedBadge && (
                <StatusBadge
                  label={translate(category.stagedBadge.label) || ''}
                  color={category.stagedBadge.color}
                />
              )}
            </div>
          }
        />
        <StatusIndicator
          dataTestId={`status-indicator-${category.id}`}
          text={translate(status?.translation)}
          color={status?.color}
        />
        {!editMode && (
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              disableElevation
              data-testid={`details-button-${category.id}`}
              className="details-button"
              startIcon={<FormatListBulleted />}
              onClick={() => handleDetailsClick(id as string)}
            >
              {translate('Default.details')}
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={isOpen} unmountOnExit>
        <SortableItem
          categories={sortedSubcategories}
          handleMove={handleMove}
          handleUpdateSubcategories={setUpdatedSubcategories}
          disabled={!isSortingMode}
          handleSelect={handleSelect}
          selected={selected}
        />
      </Collapse>
    </div>
  );
};

CategoryListItem.defaultProps = defaultProps;

export default CategoryListItem;
