import { Typography } from '@material-ui/core';
import React from 'react';
import useStatusIndicatorStyles from 'styles/StatusIndicator.styles';

type StatusIndicatorProps = {
  text: string;
  color: string;
  dataTestId?: string;
  className?: string;
};

const StatusIndicator = ({
  text,
  color,
  className,
  dataTestId = 'status-indicator',
}: StatusIndicatorProps): JSX.Element => {
  const classes = useStatusIndicatorStyles();

  return (
    <div id={dataTestId} data-testid={dataTestId} className={className || classes.wrapper}>
      <div
        id="status-indicator-dot-color-id"
        data-testid="status-indicator-dot-color-data-testid"
        className={classes.indicator}
        style={{ backgroundColor: color }}
      />

      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

export default StatusIndicator;
