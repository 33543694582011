/* istanbul ignore file */
// TODO: Find solution for testing static context
import { useUserContext } from 'context/user-context';
import config from 'countries.config.json';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { CountryCode } from 'types';

type ZoneState = {
  country: CountryCode;
  defaultLanguage: string;
  acceptedLanguages: string[];
  isLoading: boolean;
};

type ZoneAction = { type: 'zone updated'; payload: CountryCode };

const ZoneContext = createContext<{ state: ZoneState; dispatch: Dispatch<ZoneAction> }>({
  state: {} as ZoneState,
  dispatch: () => undefined,
});
ZoneContext.displayName = 'ZoneContext';

const zoneReducer = (state: ZoneState, action: ZoneAction): ZoneState => {
  const { type, payload } = action;
  switch (type) {
    case 'zone updated': {
      // Allows access to selected country outside component scope (utility functions, API calls, etc.)
      localStorage.setItem('selectedCountry', payload);
      return {
        ...state,
        ...config[payload],
        country: payload,
      };
    }
    default:
      return state;
  }
};

const ZoneProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { supportedCountries } = useUserContext();

  if (!supportedCountries.includes(localStorage.getItem('selectedCountry') as string)) {
    localStorage.setItem('selectedCountry', supportedCountries[0]);
  }
  const defaultCountry = localStorage.getItem('selectedCountry');
  const initialState = {
    country: defaultCountry,
    // @ts-expect-error country should never be falsy
    ...config[defaultCountry],
  };
  const [state, dispatch] = useReducer(zoneReducer, initialState);

  const { children } = props;
  return <ZoneContext.Provider value={{ state, dispatch }}>{children}</ZoneContext.Provider>;
};

const useZoneContext = (): { state: ZoneState; dispatch: Dispatch<ZoneAction> } => {
  return useContext(ZoneContext);
};

const setZone = (dispatch: Dispatch<ZoneAction>, zone: CountryCode): void => {
  dispatch({ type: 'zone updated', payload: zone });
};

export { ZoneProvider, useZoneContext, setZone };
