import { MarketplaceCategory } from 'types';

/**
 * Find category element in an array of MarketplaceCategory
 *
 * @param categories the tree of categories
 * @param categoryId the id of category to be found
 * @return the category found or undefined otherwise
 */
const findCategoryInTree = (
  categories: MarketplaceCategory[],
  categoryId: string | undefined
): MarketplaceCategory | undefined => {
  let foundCategory;

  for (const category of categories) {
    if (foundCategory) {
      break;
    }

    if (category.id === categoryId) {
      foundCategory = category;
      break;
    }

    if (category?.subcategories?.length) {
      foundCategory = findCategoryInTree(category.subcategories, categoryId);
    }
  }

  return foundCategory;
};

/**
 * Flatten all levels of a tree recursively
 *
 * @param categories the tree of categories
 */
const flattenCategories = (categories: MarketplaceCategory[]): MarketplaceCategory[] => {
  return categories.flatMap((category) => {
    if (category.subcategories) {
      return [category, ...flattenCategories(category.subcategories)];
    }
    return [category];
  });
};

/**
 * Remove a stagedBadge property in all levels of a tree recursively
 *
 * @param categories the tree of categories
 */
const removeStagedBadge = (categories: MarketplaceCategory[]) => {
  for (const category of categories) {
    delete category.stagedBadge;
    if (category.subcategories) {
      removeStagedBadge(category.subcategories);
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { findCategoryInTree, flattenCategories, removeStagedBadge };
