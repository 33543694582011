import { Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const defaultProps = {
  actions: null,
  caption: '',
  children: null,
  headerContent: null,
  heading: '',
  subtitle: '',
  hasWarning: false,
  maxWidth: 'xl',
  showHeaderDivider: true,
  subtitle2: '',
};

type ModalProps = {
  actions?: JSX.Element | JSX.Element[];
  caption?: string;
  children?: JSX.Element | JSX.Element[];
  handleClose: () => void;
  headerContent?: JSX.Element | JSX.Element[];
  heading?: string;
  isOpen: boolean;
  hasWarning?: boolean;
  subtitle?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  showHeaderDivider?: boolean;
  subtitle2?: string;
};

const Modal = ({
  actions,
  caption,
  children,
  handleClose,
  headerContent,
  heading,
  hasWarning,
  isOpen,
  subtitle,
  maxWidth,
  showHeaderDivider,
  subtitle2,
}: ModalProps): JSX.Element => {
  return (
    <Dialog
      data-testid="modal"
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth
      onClose={handleClose}
      transitionDuration={0}
    >
      {(heading || headerContent) && (
        <section
          data-testid="modal-header-content"
          style={{
            padding: '32px 32px 16px 32px',
            ...(!showHeaderDivider && { paddingBottom: 0 }),
          }}
        >
          {heading && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
              <div>
                <Typography
                  data-testid="modal-heading"
                  role="heading"
                  variant="h6"
                  style={{
                    display: 'flex',
                    alignItems: hasWarning ? 'center' : 'baseline',
                    flexWrap: 'wrap',
                  }}
                >
                  {hasWarning && (
                    <ErrorIcon
                      htmlColor="#E02B27"
                      fontSize="large"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  {heading}
                  <Typography
                    data-testid="modal-subtitle"
                    variant="subtitle1"
                    style={{
                      fontWeight: 'normal',
                      paddingLeft: hasWarning ? 8 : 4,
                    }}
                  >
                    {subtitle}
                  </Typography>
                </Typography>
                <Typography
                  style={{ marginBottom: '8px' }}
                  paragraph
                  data-testid="modal-subtitle2"
                  variant="subtitle2"
                >
                  {subtitle2}
                </Typography>
                <Typography paragraph data-testid="modal-caption" variant="caption">
                  {caption}
                </Typography>
              </div>
              <IconButton
                style={{ position: 'absolute', top: 16, right: 16 }}
                data-testid="modal-cancel-button"
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </div>
          )}
          {headerContent}
        </section>
      )}
      <DialogContent style={{ padding: 0, ...(!showHeaderDivider && { borderTop: 0 }) }} dividers>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions data-testid="modal-actions" style={{ padding: 32 }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
