import { useAuthenticationService } from 'admin-portal-shared-services';
import { isCountryInEuropeanCluster } from 'utils/countries-in-european-cluster';

type ENDPOINTS = {
  categories: string;
  categoriesV2: string;
  uploadImage: string;
  items: string;
};

const GET_API_ENDPOINTS = (): ENDPOINTS => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const selectedCountry = localStorage.getItem('selectedCountry');
  const country = selectedCountry || userCountry;
  const cluster = isCountryInEuropeanCluster(country as string) ? '/eu' : '';

  return {
    categories: `/api${cluster}/categories`,
    categoriesV2: `/api${cluster}/categories/v2/`,
    uploadImage: `/api${cluster}/cms-content-edit-images/api/v1`,
    items: `/api${cluster}/items/items`,
  };
};

export default GET_API_ENDPOINTS;
