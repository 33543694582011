import { ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import { MarketplaceCategory } from 'types';

const CategoryPath = ({ category }: { category: MarketplaceCategory }): JSX.Element => {
  const { name, categoryPath } = category;
  return (
    <span data-testid="category-path" style={{ display: 'flex', alignItems: 'center' }}>
      {categoryPath?.map((cat) => (
        <React.Fragment key={`category-path-level-${cat.level}`}>
          <span data-testid={`category-path-level-${cat.level}`}>{cat.name}</span>
          <ArrowForwardIos fontSize="small" style={{ margin: '0 6px', fontSize: 14 }} />
        </React.Fragment>
      ))}
      <span data-testid="category-last-path">{name}</span>
    </span>
  );
};

export default CategoryPath;
