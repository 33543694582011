import useZoneLanguages from 'hooks/useZoneLanguages';
import React from 'react';
import { DARK_GRAY } from 'themes/customTheme';

const LanguageCodes = (): JSX.Element => {
  const { defaultLanguage, acceptedLanguages } = useZoneLanguages();
  const secondaryLanguages = acceptedLanguages.filter((lang) => lang !== defaultLanguage);
  return (
    <>
      <span data-testid="language-codes" style={{ fontWeight: 'normal', color: DARK_GRAY }}>
        {`${defaultLanguage.toUpperCase()}`}
        {!!secondaryLanguages.length && ` (${secondaryLanguages.map((l) => l.toUpperCase())})`}
      </span>
    </>
  );
};

export default LanguageCodes;
