import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { getAll } from 'services/categories/CategoryService';
import { Category, MarketplaceCategory } from 'types/categories';

interface CategoryActions {
  type: string;
  // TODO: Resolve type errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type LanguageData = {
  [languageCode: string]: {
    name: string;
    id: string;
    parentId: string | null;
    sortOrder: number;
    categories: MarketplaceCategory[];
  };
};

type CategoryWithLanguageCode = MarketplaceCategory & LanguageData;

type CategoryState = {
  categories: {
    [key: string]: CategoryWithLanguageCode;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
};

const initialState: CategoryState = { categories: {}, status: 'idle', error: null };

const CategoryContext = createContext<{
  state: CategoryState;
  dispatch: Dispatch<CategoryActions>;
}>({
  state: initialState,
  dispatch: /* istanbul ignore next */ () => undefined,
});
CategoryContext.displayName = 'CategoryContext';

const categoryReducer = (state: CategoryState, action: CategoryActions): CategoryState => {
  const { type, payload } = action;
  switch (type) {
    case 'categories received': {
      return { ...state, categories: payload, status: 'idle' };
    }

    case 'status updated':
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
};

const useCategoryContext = (): {
  state: CategoryState;
  dispatch: Dispatch<CategoryActions>;
} => {
  return useContext(CategoryContext);
};

// TODO: Refactor to extract initialization
const getCategories = async (
  dispatch: Dispatch<CategoryActions>,
  zoneData: {
    country: string;
    defaultLanguage: string;
    acceptedLanguages: string[];
  }
): Promise<void | AxiosResponse<any>[]> => {
  dispatch({ type: 'status updated', payload: 'loading' });
  const { country, acceptedLanguages } = zoneData;
  const requestsByLanguage = acceptedLanguages.map((code) => getAll(`${code}-${country}`));
  Promise.all(requestsByLanguage).then((res: Category[][]) => {
    // Add corresponding language code to each category to leverage during reduce method
    const categories = res.map((r, i) => {
      // Iterate of the responses (r) for each language and add language code to data for each category (c) and subcategory (s)
      return r.map((c) => {
        return {
          ...c,
          // Select language by code by response index (i)
          language: acceptedLanguages[i],
          // Add language code to each subcategory (s)
          categories: c.categories.map((s) => ({
            ...s,
            language: acceptedLanguages[i],
          })),
        };
      });
    });
    // TODO: Clean up flattening logic
    // Flatten categories into a single level array and reduce to aggregate data with disparate values stored by language key
    const flattenedCategories = _.flattenDeep([
      ...categories,
      ..._.flatten(categories).map((d) => d.categories),
    ]);
    const categoriesById = flattenedCategories.reduce(
      (acc: { [key: string]: Category }, obj: Category & { language: string }) => {
        const {
          vendorCategoryId,
          id,
          language,
          name,
          parentId,
          sortOrder,
          categories: subCategories,
        } = obj;
        const category = acc[vendorCategoryId];
        const parent = flattenedCategories.find((c) => c.id === parentId);
        if (category) {
          return {
            ...acc,
            [vendorCategoryId]: {
              ...category,
              ...(parent && {
                parentVendorCategoryId: parent.vendorCategoryId,
              }),
              [language]: {
                id,
                parentId,
                sortOrder,
                name,
                categories: subCategories,
              },
            },
          };
        }
        return {
          ...acc,
          [vendorCategoryId]: {
            ...obj,
            ...(parent && {
              parentVendorCategoryId: parent.vendorCategoryId,
            }),
            [language]: {
              id,
              parentId,
              sortOrder,
              name,
              categories: subCategories,
            },
          },
        };
      },
      {}
    );
    dispatch({
      type: 'categories received',
      payload: categoriesById,
    });
  });
};

const CategoryProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(categoryReducer, initialState);
  const { children } = props;

  return (
    <CategoryContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryProvider, categoryReducer, useCategoryContext, getCategories, CategoryContext };
export type { CategoryState, CategoryWithLanguageCode };
