import React, { createContext, Dispatch, useContext, useEffect, useReducer } from 'react';

export type Vendor = {
  vendorId: string | undefined;
  displayName: string | undefined;
};

type VendorData = {
  vendors: Vendor[];
  selectedVendor?: Vendor | undefined;
};

interface VendorActions {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

/* istanbul ignore next */
const VendorContext = createContext<{ state: VendorData; dispatch: Dispatch<VendorActions> }>({
  state: {} as VendorData,
  dispatch: () => undefined,
});

VendorContext.displayName = 'VendorContext';

const vendorReducer = (state: VendorData, action: VendorActions): VendorData => {
  const { type, payload } = action;

  switch (type) {
    case 'set vendors': {
      return {
        ...state,
        vendors: payload,
        selectedVendor:
          (payload as Vendor[]).length > 0 ? payload[0] : /* istanbul ignore next */ '',
      };
    }
    case 'update selected vendor': {
      const { vendors } = state;
      const selectedVendor = vendors.find((vendor: Vendor) => vendor.vendorId === payload);
      localStorage.setItem('vendor_Id', selectedVendor?.vendorId as string);
      return {
        ...state,
        selectedVendor,
      };
    }
    default:
      return state;
  }
};

const VendorProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;

  const initialState = {
    vendors: [],
    selectedVendor: undefined,
  };

  const initializeFromLocalStorage = () => {
    const vendorState = localStorage.getItem('vendor_state');
    if (vendorState) return JSON.parse(vendorState);
    return initialState;
  };

  const [state, dispatch] = useReducer(vendorReducer, initialState, initializeFromLocalStorage);

  useEffect(() => {
    localStorage.setItem('vendor_state', JSON.stringify(state));
  }, [state]);

  return <VendorContext.Provider value={{ state, dispatch }}>{children}</VendorContext.Provider>;
};

const useVendorContext = (): { state: VendorData; dispatch: Dispatch<VendorActions> } => {
  return useContext(VendorContext);
};
/* istanbul ignore next */
const setVendors = (dispatch: Dispatch<VendorActions>, vendors: Vendor[]): void => {
  dispatch({ type: 'set vendors', payload: vendors });
};

/* istanbul ignore next */
const setSelectedVendor = (
  dispatch: Dispatch<VendorActions>,
  vendorId: string | undefined
): void => {
  dispatch({ type: 'update selected vendor', payload: vendorId });
};

export {
  VendorProvider,
  useVendorContext,
  vendorReducer,
  VendorContext,
  setVendors,
  setSelectedVendor,
};
