import { gql, useQuery } from '@apollo/client';
import { useStoreContext } from 'context/store-context';
import useZoneLanguages from 'hooks/useZoneLanguages';
import { useEffect } from 'react';
import { MarketplaceCategory } from 'types';

export const GET_CATEGORY = gql`
  query GetCategory($id: String, $store: String, $zone: String) {
    category(id: $id, store: $store, zone: $zone) {
      id
      name
      storeCategoryId
      enabled
      root
      group
      image
      isRootCategoryWithItemsAndSubCategories
      translations {
        language
        name
      }
      items {
        vendorItemId
        sortOrder
        vendorId
        itemPlatformId
      }
      restricted
      parent {
        id
        name
      }
      categoryPath(store: $store, zone: $zone) {
        id
        level
        name
      }
    }
  }
`;

/**
 * Get category from global context based on passed Category ID
 * @param id Category ID
 * @returns A category and refetch callback
 */
const useCategoryById = (id: string): { category: MarketplaceCategory; refetch: () => void } => {
  const { country: selectedZone } = useZoneLanguages();
  const { state: storeState } = useStoreContext();

  const { data, refetch } = useQuery(GET_CATEGORY, {
    variables: {
      id,
      store: storeState.selectedStore?.storeId,
      zone: selectedZone,
    },
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    refetch();
  }, [id, refetch]);
  return { category: data?.category, refetch };
};

export default useCategoryById;
