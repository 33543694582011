import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { BeeLoader } from 'assets/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
};

const LoadingModal = ({ isOpen }: ModalProps): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <Dialog data-testid="loading-modal" open={isOpen}>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '58px 120px',
        }}
      >
        <BeeLoader />
        <Typography variant="h6" style={{ paddingTop: 16 }}>
          {translate('Default.loading')}...
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
