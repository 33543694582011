/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface CategoryCountrySelectionApplied {
	/**
	 * The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
	 */
	country_name: CountryName
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryCountrySelectionStarted {
	/**
	 * The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
	 */
	country_name: CountryName
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryTranslationsItem {
	/**
	 * The language consists of a 2-3 letter base language tag that indicates a language, optionally followed by additional subtags separated by '-'.
	 */
	translation_language: string | null
	/**
	 * The translation name of category
	 */
	translation_name: string | null
}
export interface CategoryEditionApplied {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Was the Save changes button clicked
	 */
	category_products_save: boolean | null
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The array of category translations. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category_translations: CategoryTranslationsItem[] | null
	/**
	 * Tie the category to a certain portion of users
	 */
	premium_key: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryTranslationsItem1 {
	/**
	 * The language consists of a 2-3 letter base language tag that indicates a language, optionally followed by additional subtags separated by '-'.
	 */
	translation_language: string | null
	/**
	 * The translation name of category
	 */
	translation_name: string | null
}
export interface CategoryEditionStarted {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The array of category translations. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category_translations: CategoryTranslationsItem1[] | null
	/**
	 * Tie the category to a certain portion of users
	 */
	premium_key: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryItem {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface CategoryPositionApplied {
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem[] | null
	/**
	 * If the save was given or not in the context. It's a boolean. If the save was given, set True. If not, set False
	 */
	save_changes_modal: boolean
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryItem1 {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface CategoryPositionDiscard {
	/**
	 * If the cancel was given or not in the context. It's a boolean. If the cancel was given, set True. If not, set False
	 */
	cancel_changes_modal: boolean
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem1[] | null
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryItem2 {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface CategoryPositionEdit {
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem2[] | null
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryProductsEditionApplied {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Was the Save changes button clicked
	 */
	category_products_save: boolean | null
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The amount of edited products
	 */
	products_count: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryProductsEditionStarted {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The amount of edited products
	 */
	products_count: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryItem3 {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface CategorySearchSuccessful {
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem3[] | null
	/**
	 * Were there any items retrieved?​
	 */
	category_products_search: boolean | null
	/**
	 * The product in the category event
	 */
	products_category: ProductsCategory
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryItem4 {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface CategoryThreeView {
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem4[] | null
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface CategoryVendorSelectionApplied {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
	 */
	vendor_name: VendorName
}
export interface CategoryVendorSelectionStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
	 */
	vendor_name: VendorName
}
export interface MenuInteractionCategoryCreation {
	/**
	 * Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
	 */
	menu_category: string | null
	/**
	 * What is the link of the page when clicks in the menu.
	 */
	menu_link: string | null
	/**
	 * The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
	 */
	menu_subcategory: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface MenuInteractionCategoryUsers {
	/**
	 * Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
	 */
	menu_category: string | null
}
export interface MenuInteractionCategoryView {
	/**
	 * Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
	 */
	menu_category: string | null
	/**
	 * What is the link of the page when clicks in the menu.
	 */
	menu_link: string | null
	/**
	 * The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
	 */
	menu_subcategory: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface MenuInteractionProductsList {
	/**
	 * Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
	 */
	menu_category: string | null
	/**
	 * What is the link of the page when clicks in the menu.
	 */
	menu_link: string | null
	/**
	 * The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
	 */
	menu_subcategory: string | null
}
export interface ProductsItem {
	/**
	 * The Id of the Platform
	 */
	platform_id: string | null
	/**
	 * The ID that allows us know the vendor name that sells that item​
	 */
	vendor_id: string | null
	/**
	 * The ID that allows us know the SKU number  ​
	 */
	vendor_item_id: string | null
}
export interface NewCategoryCanceled {
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * The position a category is set to be displayed at Categories list. Permited values: Top, Bottom
	 */
	category_position: CategoryPosition
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	products: ProductsItem[] | null
}
export interface ProductsItem1 {
	/**
	 * The Id of the Platform
	 */
	platform_id: string | null
	/**
	 * The ID that allows us know the vendor name that sells that item​
	 */
	vendor_id: string | null
	/**
	 * The ID that allows us know the SKU number  ​
	 */
	vendor_item_id: string | null
}
export interface NewCategoryCreated {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	products: ProductsItem1[] | null
}
export interface NewCategoryStarted {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
}
export interface ProductFilterCategorized {
	/**
	 * Was the categorized field selected?​
	 */
	category_filter_apply: boolean | null
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
	 */
	country_name: CountryName
	/**
	 * The amount of edited products
	 */
	products_count: number | null
}
export interface ProductsItem2 {
	/**
	 * The Id of the Platform
	 */
	platform_id: string | null
	/**
	 * The ID that allows us know the vendor name that sells that item​
	 */
	vendor_id: string | null
	/**
	 * The ID that allows us know the SKU number  ​
	 */
	vendor_item_id: string | null
}
export interface ProductsAssignApplied {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * If the confirm was given or not in the context. It's a boolean. If the confirm was given, set True. If not, set False
	 */
	confirm_assign_modal: boolean | null
	/**
	 * The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	products: ProductsItem2[] | null
}
export interface CategoryItem5 {
	/**
	 * The country that the given category is assigned.​
	 */
	category_country: CategoryCountry
	/**
	 * The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
	 */
	category_level: CategoryLevel
	/**
	 * The name given to a category. Sample: Beers
	 */
	category_name: string | null
	/**
	 * Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
	 */
	category_status: CategoryStatus
	/**
	 * The store that the category is stated​
	 */
	category_store: string | null
	/**
	 * The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
	 */
	category_type: CategoryType
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	products_assigned: string | null
}
export interface ProductsAssignCanceled {
	/**
	 * The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	category: CategoryItem5[] | null
	/**
	 * The position a category is set to be displayed at Categories list.
	 */
	confirm_assign: string | null
	/**
	 * If the confirm was given or not in the context. It's a boolean. If the confirm was given, set True. If not, set False
	 */
	confirm_assign_modal: boolean | null
	/**
	 * Toggle that shows if a category is anabled or not. Permited Values: Disabled, Enabled
	 */
	product_status: CategoryStatus
	/**
	 * Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
	 */
	product_title: string | null
	/**
	 * The SKU number a product is enrolled to. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: ProductsCategory
}
export interface ProductsItem3 {
	/**
	 * The Id of the Platform
	 */
	platform_id: string | null
	/**
	 * The ID that allows us know the vendor name that sells that item​
	 */
	vendor_id: string | null
	/**
	 * The ID that allows us know the SKU number  ​
	 */
	vendor_item_id: string | null
}
export interface ProductsAssignStarted {
	/**
	 * The country that the given category is assigned
	 */
	category_country: CategoryCountry
	/**
	 * The store that the category is stated
	 */
	category_store: string | null
	/**
	 * The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	products: ProductsItem3[] | null
}
export interface ProductsCountrySelectionApplied {
	/**
	 * The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
	 */
	country_name: CountryName
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface ProductsCountrySelectionStarted {
	/**
	 * The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
	 */
	country_name: CountryName
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface ProductsListCategoryCreation {
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number | null
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
}
export interface ProductsVendorSelectionApplied {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
	 */
	vendor_name: VendorName
}
export interface ProductsVendorSelectionStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
	 */
	vendor_name: VendorName
}
export interface SkuItem {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchCategoryPageApplied {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem[] | null
}
export interface SkuItem1 {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchCategoryPageCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem1[] | null
}
export interface SkuItem2 {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchCategoryPageStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem2[] | null
}
export interface SkuItem3 {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchProductsApplied {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem3[] | null
}
export interface SkuItem4 {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchProductsCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem4[] | null
}
export interface SkuItem5 {
	/**
	 * The SKU number search by ta disctint match. Sample: 1234xx.
	 */
	sku_free_text: string | null
	/**
	 * The SKU number search by the exact match. Sample: 1234.
	 */
	sku_number: number | null
	/**
	 * The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

	 */
	sku_vendor: string | null
}
export interface SkuSearchProductsStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
	 */
	screen_name: ScreenName
	/**
	 * The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	sku: SkuItem5[] | null
}
export interface UsersItem {
	/**
	 * The user access level in a given context. Permited Values: Zone Manager, Global Manager, Zone Category Manager, Global Category Manager
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: ' '
	 */
	activaction_date: string | null
	/**
	 * The date in which a user was added to the list of category admin's allowed users. Samples: ' '
	 */
	created_date: string | null
	/**
	 * The supported countries that an user access is related. Permited Values: AR, BR, CA, CO, DO, EC, GB, HN,MX, PN, PER, PY, UY, UK, TZ, SA, SV
	 */
	supported_country: SupportedCountry
	/**
	 * The identificator of the user associated in Bees Hub. Samples:  51566223-21214
	 */
	user_id: string | null
	/**
	 * ID of the vendor or vendors an user account is attached to. Samples: d27407a1-84af-4d09-bb7b-29b1ae2ff3cb
	 */
	vendor_id: string | null
}
export interface UserRegistrationActivacted {
	/**
	 * The array of users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	users: UsersItem[] | null
}

export enum CountryName {
	Ar = 'AR',
	Be = 'BE',
	Bo = 'BO',
	Br = 'BR',
	Ca = 'CA',
	Co = 'CO',
	De = 'DE',
	Do = 'DO',
	Ec = 'EC',
	Gb = 'GB',
	Hn = 'HN',
	Ic = 'IC',
	Kr = 'KR',
	Mx = 'MX',
	Nl = 'NL',
	Pa = 'PA',
	Pe = 'PE',
	Ph = 'PH',
	Py = 'PY',
	Sv = 'SV',
	Tz = 'TZ',
	Ug = 'UG',
	Uy = 'UY',
	Null = 'null',
}
export enum ScreenName {
	CategoryAdmin = 'CATEGORY_ADMIN',
	Null = 'null',
}
export enum CategoryCountry {
	Ar = 'AR',
	Be = 'BE',
	Bo = 'BO',
	Br = 'BR',
	Ca = 'CA',
	Co = 'CO',
	De = 'DE',
	Do = 'DO',
	Ec = 'EC',
	Gb = 'GB',
	Hn = 'HN',
	Ic = 'IC',
	Kr = 'KR',
	Mx = 'MX',
	Nl = 'NL',
	Pa = 'PA',
	Pe = 'PE',
	Ph = 'PH',
	Py = 'PY',
	Sv = 'SV',
	Tz = 'TZ',
	Ug = 'UG',
	Uy = 'UY',
	Za = 'ZA',
	Null = 'null',
}
export enum CategoryLevel {
	Level1 = 'LEVEL_1',
	Level2 = 'LEVEL_2',
	Level3 = 'LEVEL_3',
	Null = 'null',
}
export enum CategoryStatus {
	Disable = 'DISABLE',
	Enable = 'ENABLE',
	Null = 'null',
}
export enum CategoryType {
	ClubBMax = 'CLUB_B_MAX',
	Default = 'DEFAULT',
	Premium = 'PREMIUM',
	Null = 'null',
}
export enum ProductsCategory {
	Null = 'null',
}
export enum VendorName {
	Abi = 'ABI',
	Brf = 'BRF',
	Null = 'null',
}
export enum CategoryPosition {
	Bottom = 'BOTTOM',
	Top = 'TOP',
	Null = 'null',
}
export enum AccessLevel {
	GlobalCategoryManager = 'GLOBAL_CATEGORY_MANAGER',
	GlobalManager = 'GLOBAL_MANAGER',
	ZoneCategoryManager = 'ZONE_CATEGORY_MANAGER',
	ZoneManager = 'ZONE_MANAGER',
	Null = 'null',
}
export enum SupportedCountry {
	Ar = 'AR',
	Br = 'BR',
	Ca = 'CA',
	Co = 'CO',
	Do = 'DO',
	Ec = 'EC',
	Gb = 'GB',
	Hn = 'HN',
	Mx = 'MX',
	Per = 'PER',
	Pn = 'PN',
	Py = 'PY',
	Sa = 'SA',
	Sv = 'SV',
	Tz = 'TZ',
	Uk = 'UK',
	Uy = 'UY',
	Null = 'null',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef CategoryCountrySelectionApplied
 * @property {CountryName} country_name - The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryCountrySelectionStarted
 * @property {CountryName} country_name - The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryTranslationsItem
 * @property {string | null} translation_language - The language consists of a 2-3 letter base language tag that indicates a language, optionally followed by additional subtags separated by '-'.
 * @property {string | null} translation_name - The translation name of category
 */
/**
 * @typedef CategoryEditionApplied
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {boolean | null} category_products_save - Was the Save changes button clicked
 * @property {string | null} category_store - The store that the category is stated
 * @property {CategoryTranslationsItem[] | null} category_translations - The array of category translations. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} premium_key - Tie the category to a certain portion of users
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryTranslationsItem1
 * @property {string | null} translation_language - The language consists of a 2-3 letter base language tag that indicates a language, optionally followed by additional subtags separated by '-'.
 * @property {string | null} translation_name - The translation name of category
 */
/**
 * @typedef CategoryEditionStarted
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {string | null} category_store - The store that the category is stated
 * @property {CategoryTranslationsItem1[] | null} category_translations - The array of category translations. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} premium_key - Tie the category to a certain portion of users
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryItem
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef CategoryPositionApplied
 * @property {CategoryItem[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean} save_changes_modal - If the save was given or not in the context. It's a boolean. If the save was given, set True. If not, set False
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryItem1
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef CategoryPositionDiscard
 * @property {boolean} cancel_changes_modal - If the cancel was given or not in the context. It's a boolean. If the cancel was given, set True. If not, set False
 * @property {CategoryItem1[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryItem2
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef CategoryPositionEdit
 * @property {CategoryItem2[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryProductsEditionApplied
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {boolean | null} category_products_save - Was the Save changes button clicked
 * @property {string | null} category_store - The store that the category is stated
 * @property {number | null} products_count - The amount of edited products
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryProductsEditionStarted
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {string | null} category_store - The store that the category is stated
 * @property {number | null} products_count - The amount of edited products
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryItem3
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef CategorySearchSuccessful
 * @property {CategoryItem3[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} category_products_search - Were there any items retrieved?​
 * @property {ProductsCategory} products_category - The product in the category event
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryItem4
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef CategoryThreeView
 * @property {CategoryItem4[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef CategoryVendorSelectionApplied
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {VendorName} vendor_name - The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
 */
/**
 * @typedef CategoryVendorSelectionStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {VendorName} vendor_name - The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
 */
/**
 * @typedef MenuInteractionCategoryCreation
 * @property {string | null} menu_category - Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
 * @property {string | null} menu_link - What is the link of the page when clicks in the menu.
 * @property {string | null} menu_subcategory - The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef MenuInteractionCategoryUsers
 * @property {string | null} menu_category - Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
 */
/**
 * @typedef MenuInteractionCategoryView
 * @property {string | null} menu_category - Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
 * @property {string | null} menu_link - What is the link of the page when clicks in the menu.
 * @property {string | null} menu_subcategory - The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef MenuInteractionProductsList
 * @property {string | null} menu_category - Top level Menu Category in the given context, always in English. Samples: 'Settings', 'Dashboards', 'Distribution Centers'
 * @property {string | null} menu_link - What is the link of the page when clicks in the menu.
 * @property {string | null} menu_subcategory - The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null.
 */
/**
 * @typedef ProductsItem
 * @property {string | null} platform_id - The Id of the Platform
 * @property {string | null} vendor_id - The ID that allows us know the vendor name that sells that item​
 * @property {string | null} vendor_item_id - The ID that allows us know the SKU number  ​
 */
/**
 * @typedef NewCategoryCanceled
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryPosition} category_position - The position a category is set to be displayed at Categories list. Permited values: Top, Bottom
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {ProductsItem[] | null} products - The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef ProductsItem1
 * @property {string | null} platform_id - The Id of the Platform
 * @property {string | null} vendor_id - The ID that allows us know the vendor name that sells that item​
 * @property {string | null} vendor_item_id - The ID that allows us know the SKU number  ​
 */
/**
 * @typedef NewCategoryCreated
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {ProductsItem1[] | null} products - The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef NewCategoryStarted
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_store - The store that the category is stated
 */
/**
 * @typedef ProductFilterCategorized
 * @property {boolean | null} category_filter_apply - Was the categorized field selected?​
 * @property {string | null} category_store - The store that the category is stated
 * @property {CountryName} country_name - The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
 * @property {number | null} products_count - The amount of edited products
 */
/**
 * @typedef ProductsItem2
 * @property {string | null} platform_id - The Id of the Platform
 * @property {string | null} vendor_id - The ID that allows us know the vendor name that sells that item​
 * @property {string | null} vendor_item_id - The ID that allows us know the SKU number  ​
 */
/**
 * @typedef ProductsAssignApplied
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated
 * @property {boolean | null} confirm_assign_modal - If the confirm was given or not in the context. It's a boolean. If the confirm was given, set True. If not, set False
 * @property {ProductsItem2[] | null} products - The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef CategoryItem5
 * @property {CategoryCountry} category_country - The country that the given category is assigned.​
 * @property {CategoryLevel} category_level - The level of a category is displayed. Permited Values: Level 1, Level 2, Level 3
 * @property {string | null} category_name - The name given to a category. Sample: Beers
 * @property {CategoryStatus} category_status - Toggle to show if a category is visible to the users. Permited values: Enabled, Disabled
 * @property {string | null} category_store - The store that the category is stated​
 * @property {CategoryType} category_type - The type of program a category is enrolled to. Permited Values: DEFAULT, PREMIUM, CLUB B MAX
 * @property {string | null} products_assigned - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 */
/**
 * @typedef ProductsAssignCanceled
 * @property {CategoryItem5[] | null} category - The array of category. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} confirm_assign - The position a category is set to be displayed at Categories list. 
 * @property {boolean | null} confirm_assign_modal - If the confirm was given or not in the context. It's a boolean. If the confirm was given, set True. If not, set False
 * @property {CategoryStatus} product_status - Toggle that shows if a category is anabled or not. Permited Values: Disabled, Enabled
 * @property {string | null} product_title - Listing of all products assignd to the category. Example: Budweiser, Antártica, Quilmes
 * @property {number | null} sku_number - The SKU number a product is enrolled to. Sample: 1234.
 * @property {ProductsCategory} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef ProductsItem3
 * @property {string | null} platform_id - The Id of the Platform
 * @property {string | null} vendor_id - The ID that allows us know the vendor name that sells that item​
 * @property {string | null} vendor_item_id - The ID that allows us know the SKU number  ​
 */
/**
 * @typedef ProductsAssignStarted
 * @property {CategoryCountry} category_country - The country that the given category is assigned
 * @property {string | null} category_store - The store that the category is stated
 * @property {ProductsItem3[] | null} products - The array of product. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef ProductsCountrySelectionApplied
 * @property {CountryName} country_name - The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef ProductsCountrySelectionStarted
 * @property {CountryName} country_name - The name of the country that was selected in the dropwdon. Permited Values:AR,BE,BO,BR,CA,IC,CO,DO,EC,SV,DE,HN,MX,NL,PA,PY,PE,PH,ZA,KR,TZ,UG,GB,UY
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef ProductsListCategoryCreation
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number | null} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number | null} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 */
/**
 * @typedef ProductsVendorSelectionApplied
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {VendorName} vendor_name - The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
 */
/**
 * @typedef ProductsVendorSelectionStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {VendorName} vendor_name - The Vendor name that was selected in Vendor Dropdown. Permited Values: ABI; BRF
 */
/**
 * @typedef SkuItem
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchCategoryPageApplied
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef SkuItem1
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchCategoryPageCanceled
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem1[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef SkuItem2
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchCategoryPageStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem2[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef SkuItem3
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchProductsApplied
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem3[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef SkuItem4
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchProductsCanceled
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem4[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef SkuItem5
 * @property {string | null} sku_free_text - The SKU number search by ta disctint match. Sample: 1234xx.
 * @property {number | null} sku_number - The SKU number search by the exact match. Sample: 1234.
 * @property {string | null} sku_vendor - The vendor the SKU is atacched. Example: Santa Anita, Freddo, Ovos Mantiqueira

 */
/**
 * @typedef SkuSearchProductsStarted
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: CATEGORY_ADMIN
 * @property {SkuItem5[] | null} sku - The array of sku. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */
/**
 * @typedef UsersItem
 * @property {AccessLevel} access_level - The user access level in a given context. Permited Values: Zone Manager, Global Manager, Zone Category Manager, Global Category Manager
 * @property {string | null} activaction_date - The date in which a user activated the registration and finished the process successfully. Samples: ' '
 * @property {string | null} created_date - The date in which a user was added to the list of category admin's allowed users. Samples: ' '
 * @property {SupportedCountry} supported_country - The supported countries that an user access is related. Permited Values: AR, BR, CA, CO, DO, EC, GB, HN,MX, PN, PER, PY, UY, UK, TZ, SA, SV
 * @property {string | null} user_id - The identificator of the user associated in Bees Hub. Samples:  51566223-21214
 * @property {string | null} vendor_id - ID of the vendor or vendors an user account is attached to. Samples: d27407a1-84af-4d09-bb7b-29b1ae2ff3cb
 */
/**
 * @typedef UserRegistrationActivacted
 * @property {UsersItem[] | null} users - The array of users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 */

/**
 * When the user choose the country in the country dropdown and applies the filter
 *
 * @param {CategoryCountrySelectionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryCountrySelectionApplied(
	props: CategoryCountrySelectionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Country Selection Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the country in the country dropdown
 *
 * @param {CategoryCountrySelectionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryCountrySelectionStarted(
	props: CategoryCountrySelectionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Country Selection Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks in “SAVE CHANGES” when saving an existing category’s name.
 *
 * @param {CategoryEditionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryEditionApplied(
	props: CategoryEditionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Edition Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when  the user clicks in edit in order to edit category name in Category Details page.
 *
 * @param {CategoryEditionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryEditionStarted(
	props: CategoryEditionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Edition Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the vendor clicks in save button to change the position of a category when in view page
 *
 * @param {CategoryPositionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryPositionApplied(
	props: CategoryPositionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Position Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the vendor clicks in cancel button to not change the position of a category when in view page
 *
 * @param {CategoryPositionDiscard} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryPositionDiscard(
	props: CategoryPositionDiscard,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Position Discard',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the vendor clicks in edit button to change the position of a category when in view page
 *
 * @param {CategoryPositionEdit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryPositionEdit(
	props: CategoryPositionEdit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Position Edit',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks in “SAVE CHANGES” when managing an existing category product.
 *
 * @param {CategoryProductsEditionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryProductsEditionApplied(
	props: CategoryProductsEditionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Products Edition Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks in ‘EDIT’ when managing products from an existing category.
 *
 * @param {CategoryProductsEditionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryProductsEditionStarted(
	props: CategoryProductsEditionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Products Edition Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user hits the search button in products assigned container either in Categories Detail Page or Categories Creation page and the application returns products.
 *
 * @param {CategorySearchSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categorySearchSuccessful(
	props: CategorySearchSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Search Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks to check the category three
 *
 * @param {CategoryThreeView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryThreeView(
	props: CategoryThreeView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Three View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the vendor in the vendor dropdown applies the filter
 *
 * @param {CategoryVendorSelectionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryVendorSelectionApplied(
	props: CategoryVendorSelectionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Vendor Selection Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the vendor in the vendor dropdown
 *
 * @param {CategoryVendorSelectionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryVendorSelectionStarted(
	props: CategoryVendorSelectionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Vendor Selection Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
When the user choose the country in the country dropdown and applies the filter
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function countrySelectionApplied(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Country Selection Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with any menu in the application
 *
 * @param {MenuInteractionCategoryCreation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteractionCategoryCreation(
	props: MenuInteractionCategoryCreation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction Category Creation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with any menu in the application
 *
 * @param {MenuInteractionCategoryUsers} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteractionCategoryUsers(
	props: MenuInteractionCategoryUsers,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction Category Users',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with any menu in the application
 *
 * @param {MenuInteractionCategoryView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteractionCategoryView(
	props: MenuInteractionCategoryView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction Category View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with any menu in the application
 *
 * @param {MenuInteractionProductsList} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteractionProductsList(
	props: MenuInteractionProductsList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction Products List',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in create category button and then clicks in "discard"
 *
 * @param {NewCategoryCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newCategoryCanceled(
	props: NewCategoryCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Category Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in create category button and clicks in "confirm"
 *
 * @param {NewCategoryCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newCategoryCreated(
	props: NewCategoryCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Category Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in create category button and begins to fill the page
 *
 * @param {NewCategoryStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newCategoryStarted(
	props: NewCategoryStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Category Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user is in Products page looking for the categorized products. Path will be Categorized and then Apply.
 *
 * @param {ProductFilterCategorized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productFilterCategorized(
	props: ProductFilterCategorized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Filter Categorized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in the checkbox placed in the left of each product in products list and adress it to one or more category, clicking in assign and then in confirm
 *
 * @param {ProductsAssignApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsAssignApplied(
	props: ProductsAssignApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Assign Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in the checkbox placed in the left of each product in products list and adress it to one or more category, clicking in assign
 *
 * @param {ProductsAssignCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsAssignCanceled(
	props: ProductsAssignCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Assign Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in the checkbox plaed in the left of each product in products list and clicks in assign products
 *
 * @param {ProductsAssignStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsAssignStarted(
	props: ProductsAssignStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Assign Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the country in the country dropdown and applies the filter
 *
 * @param {ProductsCountrySelectionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsCountrySelectionApplied(
	props: ProductsCountrySelectionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Country Selection Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the country in the country dropdown
 *
 * @param {ProductsCountrySelectionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsCountrySelectionStarted(
	props: ProductsCountrySelectionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Country Selection Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects new pagination row to list more/less items (10, 20, 50, 100)
 *
 * @param {ProductsListCategoryCreation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsListCategoryCreation(
	props: ProductsListCategoryCreation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products List Category Creation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the vendor in the vendor dropdown applies the filter
 *
 * @param {ProductsVendorSelectionApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsVendorSelectionApplied(
	props: ProductsVendorSelectionApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Vendor Selection Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user choose the vendor in the vendor dropdown
 *
 * @param {ProductsVendorSelectionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsVendorSelectionStarted(
	props: ProductsVendorSelectionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Vendor Selection Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in "search" in the search bar in the products list
 *
 * @param {SkuSearchCategoryPageApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchCategoryPageApplied(
	props: SkuSearchCategoryPageApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Category Page Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user cancel the search in the search bar in the products list
 *
 * @param {SkuSearchCategoryPageCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchCategoryPageCanceled(
	props: SkuSearchCategoryPageCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Category Page Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with the search bar in the products list
 *
 * @param {SkuSearchCategoryPageStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchCategoryPageStarted(
	props: SkuSearchCategoryPageStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Category Page Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in "search" in the search bar in the products list
 *
 * @param {SkuSearchProductsApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchProductsApplied(
	props: SkuSearchProductsApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Products Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user cancel the search in the search bar in the products list
 *
 * @param {SkuSearchProductsCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchProductsCanceled(
	props: SkuSearchProductsCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Products Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user interacts with the search bar in the products list
 *
 * @param {SkuSearchProductsStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skuSearchProductsStarted(
	props: SkuSearchProductsStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SKU Search Products Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the user registration is successful.
 *
 * @param {UserRegistrationActivacted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationActivacted(
	props: UserRegistrationActivacted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'User Registration Activacted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user choose the country in the country dropdown and applies the filter
	 *
	 * @param {CategoryCountrySelectionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryCountrySelectionApplied,
	/**
	 * When the user choose the country in the country dropdown
	 *
	 * @param {CategoryCountrySelectionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryCountrySelectionStarted,
	/**
	 * Triggered when the user clicks in “SAVE CHANGES” when saving an existing category’s name.
	 *
	 * @param {CategoryEditionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryEditionApplied,
	/**
	 * Triggered when  the user clicks in edit in order to edit category name in Category Details page.
	 *
	 * @param {CategoryEditionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryEditionStarted,
	/**
	 * When the vendor clicks in save button to change the position of a category when in view page
	 *
	 * @param {CategoryPositionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryPositionApplied,
	/**
	 * When the vendor clicks in cancel button to not change the position of a category when in view page
	 *
	 * @param {CategoryPositionDiscard} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryPositionDiscard,
	/**
	 * When the vendor clicks in edit button to change the position of a category when in view page
	 *
	 * @param {CategoryPositionEdit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryPositionEdit,
	/**
	 * Triggered when the user clicks in “SAVE CHANGES” when managing an existing category product.
	 *
	 * @param {CategoryProductsEditionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryProductsEditionApplied,
	/**
	 * Triggered when the user clicks in ‘EDIT’ when managing products from an existing category.
	 *
	 * @param {CategoryProductsEditionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryProductsEditionStarted,
	/**
	 * Triggered when the user hits the search button in products assigned container either in Categories Detail Page or Categories Creation page and the application returns products.
	 *
	 * @param {CategorySearchSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categorySearchSuccessful,
	/**
	 * When the user clicks to check the category three
	 *
	 * @param {CategoryThreeView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryThreeView,
	/**
	 * When the user choose the vendor in the vendor dropdown applies the filter
	 *
	 * @param {CategoryVendorSelectionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryVendorSelectionApplied,
	/**
	 * When the user choose the vendor in the vendor dropdown
	 *
	 * @param {CategoryVendorSelectionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryVendorSelectionStarted,
	/**
	 * 
	When the user choose the country in the country dropdown and applies the filter
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	countrySelectionApplied,
	/**
	 * When the user interacts with any menu in the application
	 *
	 * @param {MenuInteractionCategoryCreation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteractionCategoryCreation,
	/**
	 * When the user interacts with any menu in the application
	 *
	 * @param {MenuInteractionCategoryUsers} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteractionCategoryUsers,
	/**
	 * When the user interacts with any menu in the application
	 *
	 * @param {MenuInteractionCategoryView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteractionCategoryView,
	/**
	 * When the user interacts with any menu in the application
	 *
	 * @param {MenuInteractionProductsList} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteractionProductsList,
	/**
	 * When the user clicks in create category button and then clicks in "discard"
	 *
	 * @param {NewCategoryCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newCategoryCanceled,
	/**
	 * When the user clicks in create category button and clicks in "confirm"
	 *
	 * @param {NewCategoryCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newCategoryCreated,
	/**
	 * When the user clicks in create category button and begins to fill the page
	 *
	 * @param {NewCategoryStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newCategoryStarted,
	/**
	 * Triggered when the user is in Products page looking for the categorized products. Path will be Categorized and then Apply.
	 *
	 * @param {ProductFilterCategorized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productFilterCategorized,
	/**
	 * When the user clicks in the checkbox placed in the left of each product in products list and adress it to one or more category, clicking in assign and then in confirm
	 *
	 * @param {ProductsAssignApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsAssignApplied,
	/**
	 * When the user clicks in the checkbox placed in the left of each product in products list and adress it to one or more category, clicking in assign
	 *
	 * @param {ProductsAssignCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsAssignCanceled,
	/**
	 * When the user clicks in the checkbox plaed in the left of each product in products list and clicks in assign products
	 *
	 * @param {ProductsAssignStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsAssignStarted,
	/**
	 * When the user choose the country in the country dropdown and applies the filter
	 *
	 * @param {ProductsCountrySelectionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsCountrySelectionApplied,
	/**
	 * When the user choose the country in the country dropdown
	 *
	 * @param {ProductsCountrySelectionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsCountrySelectionStarted,
	/**
	 * Triggered when the user selects new pagination row to list more/less items (10, 20, 50, 100)
	 *
	 * @param {ProductsListCategoryCreation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsListCategoryCreation,
	/**
	 * When the user choose the vendor in the vendor dropdown applies the filter
	 *
	 * @param {ProductsVendorSelectionApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsVendorSelectionApplied,
	/**
	 * When the user choose the vendor in the vendor dropdown
	 *
	 * @param {ProductsVendorSelectionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsVendorSelectionStarted,
	/**
	 * When the user clicks in "search" in the search bar in the products list
	 *
	 * @param {SkuSearchCategoryPageApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchCategoryPageApplied,
	/**
	 * When the user cancel the search in the search bar in the products list
	 *
	 * @param {SkuSearchCategoryPageCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchCategoryPageCanceled,
	/**
	 * When the user interacts with the search bar in the products list
	 *
	 * @param {SkuSearchCategoryPageStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchCategoryPageStarted,
	/**
	 * When the user clicks in "search" in the search bar in the products list
	 *
	 * @param {SkuSearchProductsApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchProductsApplied,
	/**
	 * When the user cancel the search in the search bar in the products list
	 *
	 * @param {SkuSearchProductsCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchProductsCanceled,
	/**
	 * When the user interacts with the search bar in the products list
	 *
	 * @param {SkuSearchProductsStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skuSearchProductsStarted,
	/**
	 * Triggered when the process of the user registration is successful.
	 *
	 * @param {UserRegistrationActivacted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationActivacted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2AXGhlknD5prtZmPSjQUrghpdPr`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
