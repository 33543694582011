import React from 'react';

const ProductIcon = (): JSX.Element => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Sidemenu / Icon / Products / 16px</title>
    <g
      id="Sidemenu-/-Icon-/-Products-/-16px"
      stroke="none"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g
        id="package-(1)"
        transform="translate(5.000000, 4.000000)"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="10.7580709" y1="5.89538234" x2="3.58602362" y2="1.75950177" id="Path" />
        <path
          d="M14.3440945,11.1548836 L14.3440945,4.77973055 C14.343511,4.2109088 14.0398149,3.68551442 13.5472003,3.40110369 L7.96894137,0.213527141 C7.47582156,-0.0711757135 6.86827291,-0.0711757135 6.3751531,0.213527141 L0.796894137,3.40110369 C0.304279619,3.68551442 0.000583445039,4.2109088 0,4.77973055 L0,11.1548836 C0.000583445039,11.7237054 0.304279619,12.2490998 0.796894137,12.5335105 L6.3751531,15.7210871 C6.86827291,16.0057899 7.47582156,16.0057899 7.96894137,15.7210871 L13.5472003,12.5335105 C14.0398149,12.2490998 14.343511,11.7237054 14.3440945,11.1548836 Z"
          id="Path"
        />
        <polyline
          id="Path"
          points="0.215161417 3.95096064 7.17204724 7.97527604 14.1289331 3.95096064"
        />
        <line x1="7.17204724" y1="16" x2="7.17204724" y2="7.9673071" id="Path" />
      </g>
    </g>
  </svg>
);

export default ProductIcon;
