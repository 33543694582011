import { MarketplaceCategory, CategoryAPIResponse } from 'types';
import { ListDataDTO } from './ListDataDTO';

// eslint-disable-next-line import/prefer-default-export
export class CategoryListDTO extends ListDataDTO {
  static translation = 'Default';

  static toApp = (data: CategoryAPIResponse[] | undefined): MarketplaceCategory[] => {
    const response: MarketplaceCategory[] = [];

    for (const item of data || []) {
      const copyOfItem: MarketplaceCategory = JSON.parse(JSON.stringify(item));

      if (copyOfItem.subcategories) {
        copyOfItem.subcategories = CategoryListDTO.toApp(item.subcategories);
      }

      const newItem: MarketplaceCategory = {
        ...copyOfItem,
        status: { translation: '', color: '', description: '', className: 'statusDefault' },
      };

      newItem.status = ListDataDTO.formatStatus(
        {
          enabled: item.enabled,
        },
        CategoryListDTO.translation
      );

      response.push(newItem);
    }

    return response;
  };
}
