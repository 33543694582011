import { Button, Typography } from '@material-ui/core';
import React from 'react';
import Modal from './Modal';

const defaultProps = {
  hasWarning: false,
};

type Action = {
  text: string;
  action: () => void;
};

type Props = {
  isOpen: boolean;
  hasWarning?: boolean;
  heading: string;
  message: string;
  confirm: Action;
  cancel: Action;
};

/**
 * A modal for basic user feedback with confirm and cancel actions
 * @returns A JSX Element
 */
const SimpleModal = ({
  isOpen,
  hasWarning,
  heading,
  message,
  confirm,
  cancel,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      hasWarning={hasWarning}
      maxWidth="xs"
      handleClose={cancel.action}
      heading={heading}
      headerContent={<Typography variant="body1">{message}</Typography>}
      actions={
        <>
          <Button data-testid="cancel-modal" color="primary" onClick={cancel.action}>
            {cancel.text}
          </Button>
          <Button
            data-testid="save-changes-modal"
            variant="outlined"
            color="primary"
            onClick={confirm.action}
          >
            {confirm.text}
          </Button>
        </>
      }
    />
  );
};

SimpleModal.defaultProps = defaultProps;

export default SimpleModal;
