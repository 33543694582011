import { gql, NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { useAuditLog } from 'admin-portal-shared-services';
import { CategoryListDTO } from 'Api/dto/CategoryListDTO';
import { APP_NAME, AuditLogEntities, AuditLogOperations, CategoryPages } from 'constants/audit-log';
import { EditMode, setEditMode, useListContext } from 'context/list-context';
import { useStoreContext } from 'context/store-context';
import { hideToast, showToast, useToastContext } from 'context/toast-context';
import { useToggleContext } from 'context/toggle-context';
import useSort from 'hooks/useSort';
import useZoneLanguages from 'hooks/useZoneLanguages';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryAPIResponse, MarketplaceCategory, StagedCategoryBadge } from 'types';
import { findCategoryInTree, flattenCategories, removeStagedBadge } from 'utils/category-tree';
import { shouldBulkUpdateCategory } from 'utils/shouldBulkUpdateCategory/shouldBulkUpdateCategory';
import { getUpdatedSortOrders } from 'utils/sortOrder';

// eslint-disable-next-line no-shadow
export enum GroupType {
  NAVIGATION,
  COLLECTION,
}

export type StateResponse = {
  id?: string;
  sortOrder?: number;
  parent?: string | null;
  enabled?: boolean;
};

type PrevAndPostStates = {
  prevState: StateResponse;
  postState: StateResponse;
};

export const GET_CATEGORIES = gql`
  fragment CategoryFragment on CategoryListItem {
    id
    name
    enabled
    root
    group
    level
    group
    translations {
      language
      name
    }
    restricted
    sortOrder
    itemsCount
    lastSortOrder
  }

  fragment SubcategoryFragment on CategoryListItem {
    ...CategoryFragment
    parent {
      id
      name
    }
  }

  query GetCategories($store: String, $zone: String, $group: String, $includeDisabled: Boolean) {
    categories(store: $store, zone: $zone, group: $group, includeDisabled: $includeDisabled) {
      ...CategoryFragment
      subcategories {
        ...SubcategoryFragment
        subcategories {
          ...SubcategoryFragment
        }
      }
    }
  }
`;

export const GET_DEPRECATED_CATEGORIES = gql`
  fragment CategoryFragment on CategoryListItem {
    id
    name
    enabled
    root
    group
    level
    group
    translations {
      language
      name
    }
    restricted
    sortOrder
    itemsCount
    lastSortOrder
  }

  fragment SubcategoryFragment on CategoryListItem {
    ...CategoryFragment
    parent {
      id
      name
    }
  }

  query GetCategories($store: String, $zone: String, $group: GroupType, $includeDisabled: Boolean) {
    categories(store: $store, zone: $zone, group: $group, includeDisabled: $includeDisabled) {
      ...CategoryFragment
      subcategories {
        ...SubcategoryFragment
        subcategories {
          ...SubcategoryFragment
        }
      }
    }
  }
`;

export const UPDATE_CATEGORIES_MUTATION = gql`
  mutation UpdateCategories($categories: [CategoryUpdateInput!], $store: String, $zone: String) {
    updateCategories(categories: $categories, store: $store, zone: $zone) {
      code
      success
      message
    }
  }
`;

const useCategoryHome = (
  categoryGroup: string
): {
  categories: MarketplaceCategory[];
  defaultCategories: MarketplaceCategory[];
  updatedParentCategories: MarketplaceCategory[];
  updatedSubcategories: MarketplaceCategory[];
  editing: boolean;
  loading: boolean;
  mutationLoading: boolean;
  handleCloseToast: () => void;
  handleEdit: () => void;
  handleSave: () => void;
  handleCancel: () => void;
  handleMove: (dragIndex: number, hoverIndex: number) => void;
  handleUpdateSubcategories: (array: MarketplaceCategory[]) => void;
  isCancelModalOpen: boolean;
  handleCloseCancelModal: () => void;
  handleOpenCancelModal: () => void;
  isSaveModalOpen: boolean;
  handleCloseSaveModal: () => void;
  handleOpenSaveModal: () => void;
  handleBulkUpdate: (changes: any) => void;
} => {
  const { dispatch } = useToastContext();
  const {
    state: { selectedStore },
  } = useStoreContext();
  const { t: translate } = useTranslation();
  const {
    dispatch: listDispatch,
    state: { editMode },
  } = useListContext();
  const {
    state: { bulkUpdateStatus, retrocompabilityActive },
  } = useToggleContext();
  const [categoriesData, setCategoriesData] = useState<MarketplaceCategory[]>([]);
  const [defaultCategories, setDefaultCategories] = useState<MarketplaceCategory[]>([]);
  const [categories, setCategories] = useState<MarketplaceCategory[]>([]);
  const [updatedParentCategories, setUpdatedParentCategories] = useState<MarketplaceCategory[]>([]);
  const [updatedSubcategories, setUpdatedSubcategories] = useState<MarketplaceCategory[]>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [categoriesToBulkUpdate, setCategoriesToBulkUpdate] = useState<MarketplaceCategory[]>([]);
  const { country: selectedZone, countryCodeForExternalServices } = useZoneLanguages();
  const auditLog = useAuditLog(APP_NAME);
  const getCategoriesQuery = retrocompabilityActive ? GET_DEPRECATED_CATEGORIES : GET_CATEGORIES;
  const collectionGroup = retrocompabilityActive ? 'COLLECTION' : 'FEATURED';

  // istanbul ignore next
  const handleCategoriesReceived = ({
    categories: newCategories,
  }: {
    categories: CategoryAPIResponse[];
  }) => {
    if (!Array.isArray(newCategories)) return;
    const originalCategories = _.sortBy(CategoryListDTO.toApp(newCategories), (c) => c.sortOrder);
    setCategoriesData(originalCategories);
    const filteredCategories = originalCategories.filter(
      (category) => category.group === categoryGroup
    );
    setDefaultCategories(filteredCategories);
    setCategories(filteredCategories);
    setUpdatedParentCategories([]);
    setUpdatedSubcategories([]);
  };

  useEffect(() => {
    const filteredCategories = categoriesData.filter(
      (category) => category.group === categoryGroup
    );
    setDefaultCategories(filteredCategories);
    setCategories(filteredCategories);
    setUpdatedParentCategories([]);
    setUpdatedSubcategories([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryGroup]);

  const { refetch, loading, networkStatus } = useQuery(getCategoriesQuery, {
    variables: {
      store: selectedStore?.storeId,
      zone: selectedZone,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: handleCategoriesReceived,
  });
  const { data: enabledCollectionCategories, refetch: refetchEnabledCollection } = useQuery(
    getCategoriesQuery,
    {
      variables: {
        store: selectedStore?.storeId,
        zone: selectedZone,
        group: collectionGroup,
        includeDisabled: false,
      },
      notifyOnNetworkStatusChange: true,
      skip: !selectedStore?.storeId || categoryGroup !== collectionGroup,
      fetchPolicy: 'network-only',
    }
  );

  const isLoading = loading || networkStatus === NetworkStatus.refetch;
  // istanbul ignore next
  const handleSuccess = () => {
    const hasChildUpdate = (
      updatedSubcategory: MarketplaceCategory,
      category: MarketplaceCategory
    ) => updatedSubcategory.parent?.id === category.id;

    const shouldUpdateSubcategories = (category: MarketplaceCategory) =>
      updatedSubcategories.length &&
      category.subcategories?.length &&
      updatedSubcategories.some(
        (updatedSubcategory) =>
          hasChildUpdate(updatedSubcategory, category) ||
          category.subcategories?.some((subcategoryChild) =>
            hasChildUpdate(updatedSubcategory, subcategoryChild)
          )
      );

    const mapUpdatedSubcategories = (category: MarketplaceCategory): MarketplaceCategory => {
      if (shouldUpdateSubcategories(category)) {
        return {
          ...category,
          subcategories: _.unionBy(
            updatedSubcategories.filter((s) => s.parent?.id === category.id),
            category.subcategories,
            'id'
          ).map(mapUpdatedSubcategories),
        };
      }
      return category;
    };

    const updatedCategories = _.unionBy(updatedParentCategories, categories, 'id').map(
      mapUpdatedSubcategories
    );

    const sortCategories = (array: MarketplaceCategory[]) =>
      array.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

    const updatedCategoriesValue = (updatedValue: MarketplaceCategory[]) => {
      const newCategories: MarketplaceCategory[] = categoriesData.map((category) => {
        const newValue = updatedValue.find((updatedCategory) => updatedCategory.id === category.id);
        return newValue ?? category;
      });
      setCategoriesData(sortCategories(newCategories));
    };

    setEditMode(listDispatch, null);
    setUpdatedParentCategories([]);
    setUpdatedSubcategories([]);

    if (bulkUpdateStatus && categoriesToBulkUpdate) {
      const updatedStatusCategories = CategoryListDTO.toApp(updatedCategories);
      const sortedCategories = sortCategories(updatedStatusCategories);
      removeStagedBadge(sortedCategories);
      setDefaultCategories(updatedStatusCategories);
      setCategories(sortedCategories);
      setCategoriesToBulkUpdate([]);
      updatedCategoriesValue(sortedCategories);
    } else {
      const sortedCategories = sortCategories(updatedCategories);
      setDefaultCategories(updatedCategories);
      updatedCategoriesValue(sortedCategories);
      setCategories(sortedCategories);
    }
    handleCloseSaveModal();
  };
  const [update, { data: mutationData, loading: mutationLoading }] = useMutation(
    UPDATE_CATEGORIES_MUTATION,
    {
      onCompleted: handleSuccess,
      onError: () =>
        showToast(dispatch, {
          severity: 'error',
          text: translate('Toasts.errorUpdateCategory'),
        }),
    }
  );
  const { handleSort } = useSort();
  useEffect(() => {
    // istanbul ignore else
    if (selectedStore?.storeId) {
      refetch();
    }
  }, [refetch, selectedStore]);

  // istanbul ignore next
  useEffect(() => {
    if (mutationData?.updateCategories) {
      const { success: isSuccessful, message } = mutationData?.updateCategories;
      showToast(dispatch, {
        severity: isSuccessful ? 'success' : 'error',
        text: isSuccessful ? translate('CategoryHome.successfulUpdatedCategories') : message,
      });
    }
  }, [mutationData, dispatch, translate]);

  useEffect(() => {
    updateAllCategoriesInMemory(categoriesToBulkUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesToBulkUpdate]);

  // istanbul ignore next
  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const sortedCategories = handleSort(categories, dragIndex, hoverIndex);
      const sortedCategoriesWithSortOrderUpdated = getUpdatedSortOrders(
        defaultCategories,
        sortedCategories
      );

      setCategories(sortedCategoriesWithSortOrderUpdated);
      setUpdatedParentCategories(sortedCategoriesWithSortOrderUpdated);
    },
    [handleSort, categories, defaultCategories]
  );
  // istanbul ignore next
  const handleEdit = useCallback(() => {
    setEditMode(listDispatch, EditMode.sort);
  }, [listDispatch]);

  // Callback passed down to subcategories to get updated sortOrders
  const handleUpdateSubcategories = (array: MarketplaceCategory[]) => {
    const subcategoriesWithoutUpdate = updatedSubcategories.filter(
      (subcategory) =>
        !array.some((newUpdatedSubcategory) => newUpdatedSubcategory.id === subcategory.id)
    );
    setUpdatedSubcategories([...subcategoriesWithoutUpdate, ...array]);

    updateCategoryTreeWithSubcategories([...array]);
  };

  const updateCategoryTreeWithSubcategories = (
    currentUpdatedSubCategories: MarketplaceCategory[]
  ) => {
    const copyOfCategories: MarketplaceCategory[] = JSON.parse(JSON.stringify(categories));
    const [firstSubcategory] = currentUpdatedSubCategories;
    const categoryToUpdate = findCategoryInTree(copyOfCategories, firstSubcategory?.parent?.id);

    if (categoryToUpdate) {
      // updates the category element in the copyOfCategories
      categoryToUpdate.subcategories = [...currentUpdatedSubCategories];
      setCategories(copyOfCategories);
    }
  };

  const handleCloseCancelModal = useCallback(() => {
    setIsCancelModalOpen(false);
  }, []);
  const handleOpenCancelModal = useCallback(() => {
    setIsCancelModalOpen(true);
  }, []);

  const handleCloseSaveModal = useCallback(() => {
    setIsSaveModalOpen(false);
  }, []);

  const handleOpenSaveModal = useCallback(() => {
    if (updatedParentCategories.length || updatedSubcategories.length) {
      setIsSaveModalOpen(true);
    } else {
      setEditMode(listDispatch, null);
    }
  }, [listDispatch, updatedParentCategories, updatedSubcategories]);

  const findCategoryBySameIdAndDiffSortOrder = (
    updatedCategory: MarketplaceCategory,
    originalCategories: MarketplaceCategory[]
  ): MarketplaceCategory | undefined => {
    const isSameIdDiffSortOrder = ({ id, sortOrder, enabled }: MarketplaceCategory): boolean =>
      id === updatedCategory.id &&
      (sortOrder !== updatedCategory.sortOrder || enabled !== updatedCategory.enabled);
    return originalCategories.find(isSameIdDiffSortOrder);
  };

  const buildPrevPostStateResponse = (
    updatedCategory: MarketplaceCategory,
    foundedCategory: MarketplaceCategory
  ): PrevAndPostStates => {
    const prevState = {
      id: foundedCategory.id,
      sortOrder: foundedCategory.sortOrder,
      enabled: foundedCategory.enabled,
    };
    const postState = {
      id: updatedCategory.id,
      sortOrder: updatedCategory.sortOrder,
      enabled: updatedCategory.enabled,
    };

    return { prevState, postState };
  };

  const buildStatesOfCategories = (
    updatedCategory: MarketplaceCategory,
    originalCategories: MarketplaceCategory[]
  ): PrevAndPostStates | null => {
    let response = null;
    const foundedCategory = findCategoryBySameIdAndDiffSortOrder(
      updatedCategory,
      originalCategories
    );

    if (foundedCategory) {
      response = buildPrevPostStateResponse(updatedCategory, foundedCategory);
    }

    return response;
  };

  const buildStatesOfSubcategories = (
    updatedCategory: MarketplaceCategory,
    originalCategories: MarketplaceCategory[]
  ): PrevAndPostStates | null => {
    let response = null;

    for (const originalCategory of originalCategories) {
      if (originalCategory.subcategories) {
        const foundedCategory = findCategoryBySameIdAndDiffSortOrder(
          updatedCategory,
          originalCategory.subcategories
        );

        if (foundedCategory) {
          response = buildPrevPostStateResponse(updatedCategory, foundedCategory);
          break;
        }

        response = buildStatesOfSubcategories(updatedCategory, originalCategory.subcategories);
        if (response) break;
      }
    }

    return response;
  };

  // Coverage file says all branches are covered but yarn coverage returns missing coverage on 67
  const handleSave = () /* istanbul ignore next */ => {
    refetchEnabledCollection();
    if (updatedParentCategories.length || updatedSubcategories.length) {
      // All categories with new sortOrders
      const prevStateArr: StateResponse[] = [];
      const postStateArr: StateResponse[] = [];

      updatedParentCategories.forEach((itemOfUpdatedCategories) => {
        const response = buildStatesOfCategories(itemOfUpdatedCategories, defaultCategories);
        if (response) {
          prevStateArr.push(response.prevState);
          postStateArr.push(response.postState);
        }
      });

      updatedSubcategories.forEach((itemOfUpdatedSubcategories) => {
        const response = buildStatesOfSubcategories(itemOfUpdatedSubcategories, defaultCategories);

        if (response) {
          prevStateArr.push(response.prevState);
          postStateArr.push(response.postState);
        }
      });
      const shouldUpdate = shouldBulkUpdateCategory({
        categoryGroup,
        postStateArr,
        prevStateArr,
        enabledCollectionCategories,
        collectionGroup,
      });
      if (shouldUpdate) {
        update({
          variables: {
            store: selectedStore?.storeId,
            zone: selectedZone,
            categories: postStateArr,
          },
        }).then(async () => {
          await auditChanges(prevStateArr, postStateArr);
        });
      } else {
        showToast(dispatch, {
          severity: 'error',
          text: translate('UseCategoryHome.toastError'),
        });
        handleCloseSaveModal();
        removeStagedBadge(updatedParentCategories);
        removeStagedBadge(updatedSubcategories);
        setEditMode(listDispatch, null);
        setCategories(defaultCategories);
        setUpdatedParentCategories([]);
        setUpdatedSubcategories([]);
        setCategoriesToBulkUpdate([]);
        return;
      }
    }
  };

  const auditChanges = async (prevState: StateResponse[], postState: StateResponse[]) => {
    await auditLog({
      entity: AuditLogEntities.CATEGORIES,
      entityId: '-',
      metadata: {
        sourcePage: CategoryPages.CATEGORY_HOME,
        storeId: selectedStore?.storeId as string,
        previousState: getPreviousState(),
        postState: getPostState(),
      },
      operation: AuditLogOperations.UPDATE,
      country: countryCodeForExternalServices,
    });

    function getPreviousState() {
      return JSON.stringify(prevState);
    }

    function getPostState() {
      return JSON.stringify(postState);
    }
  };

  const handleCancel = useCallback(() => {
    setEditMode(listDispatch, null);
    setCategories(defaultCategories);
    setUpdatedParentCategories([]);
    setUpdatedSubcategories([]);
    handleCloseCancelModal();
    setCategoriesToBulkUpdate([]);
  }, [
    defaultCategories,
    listDispatch,
    setCategories,
    setUpdatedParentCategories,
    setUpdatedSubcategories,
    setCategoriesToBulkUpdate,
    handleCloseCancelModal,
  ]);

  const handleCloseToast = () => {
    hideToast(dispatch);
  };

  const handleBulkUpdate = ({
    selectedValue,
    selectedCategoryIds,
  }: {
    selectedValue: string;
    selectedCategoryIds: string[];
  }): void => {
    const stagedBadge: StagedCategoryBadge =
      selectedValue === 'enabled'
        ? { label: 'CategoryHome.toEnabled', color: 'green' }
        : { label: 'CategoryHome.toDisabled', color: 'red' };

    const copyOfCategories: MarketplaceCategory[] = JSON.parse(JSON.stringify(categories));
    const stagedCategories: MarketplaceCategory[] = [];

    for (const categoryId of selectedCategoryIds) {
      const stagedCategory = findCategoryToUpdate({
        categoryId,
        currentCategories: copyOfCategories,
        selectedValue,
      });

      if (stagedCategory) {
        const shouldAddBadge = isUndefined(stagedCategory.stagedBadge);
        const booleanStatus = selectedValue === 'enabled';
        const isStatusChanged = stagedCategory.enabled !== booleanStatus;

        stagedCategory.enabled = booleanStatus;

        const categoryFromBulkUpdate = categoriesToBulkUpdate.find(
          (cat: MarketplaceCategory) => cat.id === stagedCategory?.id
        );

        if (categoryFromBulkUpdate) {
          setCategoriesToBulkUpdate((prevCategories) => [
            ...prevCategories.filter((category) => category.id !== stagedCategory?.id),
          ]);
        } else {
          stagedCategories.push(stagedCategory);
          setCategoriesToBulkUpdate((prevCategories) => [...prevCategories, stagedCategory]);
        }

        if (shouldAddBadge && isStatusChanged) {
          stagedCategory.stagedBadge = stagedBadge;
        } else {
          delete stagedCategory?.stagedBadge;
        }
      }
    }

    setCategories(copyOfCategories);
  };

  const updateAllCategoriesInMemory = (allCategoriesToBulkUpdate: MarketplaceCategory[]) => {
    const hasSubcategoryUpdates = allCategoriesToBulkUpdate.some(
      (category) => category?.level !== 1
    );
    const hasParentCategoryUpdates = allCategoriesToBulkUpdate.some(
      (category) => category?.level === 1
    );

    const rootCategories = hasParentCategoryUpdates
      ? categories.filter((category) => category?.level === 1)
      : [];
    const flattenedCategories = flattenCategories(categories);
    const subCategories = hasSubcategoryUpdates
      ? flattenedCategories.filter((category) => category?.level !== 1)
      : [];

    setUpdatedParentCategories(rootCategories);
    setUpdatedSubcategories(subCategories);
  };

  const findCategoryToUpdate = ({
    categoryId,
    currentCategories,
    selectedValue,
  }: {
    categoryId: string;
    currentCategories: MarketplaceCategory[];
    selectedValue: string;
  }): MarketplaceCategory | undefined => {
    const toBeStatus = selectedValue === 'enabled';
    let stagedCategory = currentCategories.find(
      (category: MarketplaceCategory) =>
        category.id === categoryId && category.enabled !== toBeStatus
    );

    if (!stagedCategory) {
      for (const category of currentCategories) {
        if (category.subcategories) {
          stagedCategory = findCategoryToUpdate({
            categoryId,
            currentCategories: category.subcategories,
            selectedValue,
          });
          if (stagedCategory) break;
        }
      }
    }

    return stagedCategory;
  };

  return {
    categories,
    defaultCategories,
    updatedParentCategories,
    updatedSubcategories,
    editing: !!editMode,
    loading: isLoading,
    mutationLoading,
    handleCloseToast,
    handleEdit,
    handleSave,
    handleCancel,
    handleMove,
    handleUpdateSubcategories,
    handleCloseCancelModal,
    handleOpenCancelModal,
    handleCloseSaveModal,
    handleOpenSaveModal,
    isCancelModalOpen,
    isSaveModalOpen,
    handleBulkUpdate,
  };
};

function isUndefined(value: unknown) {
  return typeof value === 'undefined';
}

export default useCategoryHome;
