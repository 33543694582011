import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ChangeEvent } from 'react-transition-group/node_modules/@types/react';
import { ItemSelection, ItemsResponse, MarketplaceItem, Pagination } from 'types';
import { useTranslation } from 'react-i18next';
import { useStoreContext } from 'context/store-context';
import { useToggleContext } from 'context/toggle-context';
import { showToast, useToastContext } from 'context/toast-context';
import { useVendorContext } from 'context/vendor-context';
import { ProductTableDTO } from 'Api/dto/ProductTableDTO';
import { ALL_VENDORS, GET_VENDORS_BY_STORE, VendorsByStoreResponse } from 'hooks/useVendors';
import usePagination from './usePagination';
import useSearch from './useSearch';
import useZoneLanguages from './useZoneLanguages';

export const GET_ITEMS = gql`
  query GetItems(
    $searchTerms: [String!]
    $platformIds: [String!]
    $page: Int
    $pageSize: Int
    $vendorId: [String!]
    $store: String
    $zone: String
    $categorized: Boolean
  ) {
    items(
      searchTerms: $searchTerms
      platformIds: $platformIds
      page: $page
      pageSize: $pageSize
      vendorId: $vendorId
      store: $store
      zone: $zone
      categorized: $categorized
    ) {
      items {
        id
        name
        sku
        enabled
        vendorItemId
        vendorName
        image
        itemPlatformId
        vendorId
        expirationGroup
        deleted
      }
      totalOfItems
    }
  }
`;

export const GET_ALL_ITEMS = gql`
  query SearchAllItems(
    $searchTerms: [String!]
    $platformIds: [String!]
    $page: Int
    $pageSize: Int
    $vendorId: [String!]
    $store: String
    $zone: String
  ) {
    items(
      searchTerms: $searchTerms
      platformIds: $platformIds
      page: $page
      pageSize: $pageSize
      vendorId: $vendorId
      store: $store
      zone: $zone
    ) {
      items {
        id
        name
        sku
        enabled
        vendorItemId
        vendorName
        image
        itemPlatformId
        vendorId
        expirationGroup
        deleted
      }
      totalOfItems
    }
  }
`;

export const GET_CATEGORIES_BY_VENDOR = gql`
  fragment CategoryByVendorFragment on CategoryListItem {
    id
    items {
      vendorId
      vendorItemId
    }
    name
    itemsCount
    enabled
  }

  query GetCategoriesByVendor($vendorIds: [String], $store: String, $zone: String) {
    categoriesByVendor(vendorIds: $vendorIds, store: $store, zone: $zone) {
      ...CategoryByVendorFragment
      subcategories {
        ...CategoryByVendorFragment
        subcategories {
          ...CategoryByVendorFragment
        }
      }
    }
  }
`;

/**
 * Use the Item API to paginate through an list of passed items with vendorItemIds
 * @param itemSelection An array of currently selected/assigned items with vendorItemIds
 * @param showCategoriesColumn A booleand that controls when should show the categories column
 * @returns An array of items from the Items API
 */
const usePaginatedProducts = (
  itemSelection?: ItemSelection[],
  showCategoriesColumn?: boolean
): {
  products: MarketplaceItem[];
  pagination: Pagination;
  handleChangePage: (event: ChangeEvent<unknown>, page: number) => void;
  handleChangePageSize: (event: ChangeEvent<unknown>) => void;
  handleSearch: (searchItems: { id: string; value: string }[]) => void;
  setProductHasCategoryFilter: (hasCategory: boolean | null) => Promise<void>;
  loading: boolean;
  resetPagination: () => void;
  handleClearSearch: () => void;
  beeMessage: string;
  loadingCategoriesColumn: boolean;
  refetchGetCategoriesByVendor: () => void;
  refetchGetItems: () => Promise<any>;
  searchItems: string[];
} => {
  const {
    pagination,
    handleChangePage,
    handleChangePageSize,
    setInitialPaginationState,
    resetPagination,
  } = usePagination();
  const {
    handleSearch,
    setProductHasCategoryFilter,
    handleClearSearch,
    searchItems,
    productHasCategoryFilter,
  } = useSearch(resetPagination);
  const { country } = useZoneLanguages();
  const { t: translate } = useTranslation();
  const {
    state: { selectedStore },
  } = useStoreContext();
  const {
    state: { selectedVendor, vendors },
  } = useVendorContext();
  const {
    state: { useCategoriesColumn, searchAllItems },
  } = useToggleContext();
  const isAllVendorsOptionSelected = selectedVendor?.vendorId === ALL_VENDORS;
  const { dispatch: toastDispatch } = useToastContext();
  const { pageSize, page } = pagination;
  /* istanbul ignore next */
  const associatedItems = itemSelection
    ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
    .slice(page * pageSize, page * pageSize + pageSize);

  const getVendorIds = (): (string | undefined)[] => {
    if (selectedVendor?.vendorId === ALL_VENDORS) {
      return vendors
        .filter((vendor) => vendor.vendorId !== ALL_VENDORS)
        .map(({ vendorId }) => vendorId);
    }

    return [selectedVendor?.vendorId];
  };

  const isSearchByAllVendorsInvalid = () => {
    return isAllVendorsOptionSelected && searchItems?.length !== 1;
  };

  const getBeeMessage = () => {
    if (searchItems?.length > 0) {
      return 'Products.noResults';
    }
    return isSearchByAllVendorsInvalid() ? 'Products.invalidSearch' : 'Products.noProducts';
  };

  const handleVendorIdsForDetailsPage = () => {
    return associatedItems ? [] : getVendorIds();
  };

  const {
    data: getVendorsByStoreData,
    error: getVendorsByStoreDataError,
    loading: isLoadingGetVendorsByStore,
  } = useQuery<VendorsByStoreResponse>(GET_VENDORS_BY_STORE, {
    notifyOnNetworkStatusChange: true,
    variables: {
      store: selectedStore?.storeId,
      zone: country,
    },
  });

  const getItemsVariables = {
    searchTerms: searchItems?.length ? searchItems : null,
    platformIds: associatedItems?.map(({ itemPlatformId }) => itemPlatformId),
    page,
    pageSize,
    vendorId: handleVendorIdsForDetailsPage(),
    store: selectedStore?.storeId,
    zone: country,
    categorized: productHasCategoryFilter,
  };

  const getAllItemsVariables = {
    searchTerms: searchItems,
    platformIds: [],
    page,
    pageSize,
    vendorId: getVendorsByStoreData?.vendorsByStore.map(({ vendorId }) => vendorId),
    store: selectedStore?.storeId,
    zone: country,
  };

  const isDetailsPageAndThereIsASearch = !!(searchAllItems && itemSelection && searchItems?.length);

  const {
    loading: isLoadingGetItems,
    error: getItemsError,
    data: getItemsData,
    networkStatus,
    refetch: refetchGetItems,
  } = useQuery(isDetailsPageAndThereIsASearch ? GET_ALL_ITEMS : GET_ITEMS, {
    variables: isDetailsPageAndThereIsASearch ? getAllItemsVariables : getItemsVariables,
    notifyOnNetworkStatusChange: true,
    skip: !selectedVendor && isLoadingGetVendorsByStore,
    onError: () => {
      showToast(toastDispatch, {
        severity: 'error',
        text: translate('Toasts.errorProducts'),
      });
    },
  });

  const skipGetCategoriesByVendor =
    isLoadingGetItems ||
    !useCategoriesColumn ||
    !!itemSelection?.length ||
    !showCategoriesColumn ||
    selectedVendor?.vendorId === ALL_VENDORS;

  const {
    data: getCategoriesByVendor,
    loading: isLoadingCategoriesByVendor,
    refetch: refetchGetCategoriesByVendor,
  } = useQuery(GET_CATEGORIES_BY_VENDOR, {
    variables: {
      vendorIds: getVendorIds(),
      store: selectedStore?.storeId,
      zone: country,
    },
    skip: skipGetCategoriesByVendor,
    notifyOnNetworkStatusChange: true,
  });

  const thereIsErrors = getItemsError || getVendorsByStoreDataError;
  const isLoading =
    isLoadingGetItems || isLoadingGetVendorsByStore || networkStatus === NetworkStatus.refetch;
  const [products, setProducts] = useState<MarketplaceItem[]>([]);

  useEffect(() => {
    if (selectedVendor?.vendorId) {
      resetPagination();

      if (!skipGetCategoriesByVendor) {
        refetchGetCategoriesByVendor().then(() => {
          // do nothing
        });
      }
    }
  }, [country, selectedVendor?.vendorId, resetPagination, refetchGetCategoriesByVendor]);

  // TODO: Handle reset to page one if new page size does not include current page
  // TODO: Perhaps move pagination to GQL server
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (thereIsErrors) {
      setInitialPaginationState({
        page: 0,
        totalElements: 0,
        totalPages: 1,
        pageSize,
      });
      setProducts([]);
      return;
    }

    if (getItemsData && getVendorsByStoreData) {
      const { items, totalOfItems } = getItemsData?.items as ItemsResponse;
      const formattedItems = ProductTableDTO.toApp(
        items,
        getCategoriesByVendor?.categoriesByVendor,
        productHasCategoryFilter
      );

      const productsCount = ProductTableDTO.calculateTotalOfItems(
        itemSelection?.length ?? totalOfItems,
        getCategoriesByVendor?.categoriesByVendor,
        productHasCategoryFilter
      );

      const totalPages = Math.ceil(productsCount / pageSize) || 1;
      // On page size change, if new page is out of range, reset to next lowest page (zero-indexed pages)
      const newPage = page >= totalPages ? totalPages - 1 : page;

      if (!isDetailsPageAndThereIsASearch) {
        setInitialPaginationState({
          totalElements: productsCount,
          totalPages,
          page: newPage,
          pageSize,
        });
      }

      // itemsFromCategoryApi has value only on category details page
      if (associatedItems && !isDetailsPageAndThereIsASearch) {
        const productsWithNotFound = ProductTableDTO.toAppNotFound({
          formattedProductsFromItemsApi: formattedItems,
          itemsFromCategoryApi: associatedItems,
          vendorsByStore: getVendorsByStoreData.vendorsByStore,
        });
        setProducts(productsWithNotFound);
      } else if (isDetailsPageAndThereIsASearch) {
        const itemsFilteredByPlatformId = itemSelection?.map(
          ({ itemPlatformId }) => itemPlatformId
        );
        setProducts(
          formattedItems.filter(({ itemPlatformId }) =>
            itemsFilteredByPlatformId?.includes(itemPlatformId)
          )
        );
      } else {
        setProducts(formattedItems);
      }
    }
  }, [
    getItemsData,
    getVendorsByStoreData,
    isLoading,
    thereIsErrors,
    itemSelection,
    page,
    pageSize,
    searchItems,
    setInitialPaginationState,
    getCategoriesByVendor,
    isAllVendorsOptionSelected,
  ]);

  /* istanbul ignore next */
  const handleRefetchGetItems = async () => {
    await refetchGetCategoriesByVendor();
    await refetchGetItems();
  };

  /* istanbul ignore next */
  const handleSetProductHasCategoryFilter = async (hasCategory: boolean | null) => {
    setProductHasCategoryFilter(hasCategory);
    await handleRefetchGetItems();
  };

  return {
    products,
    handleChangePage,
    handleChangePageSize,
    pagination,
    handleSearch,
    setProductHasCategoryFilter: handleSetProductHasCategoryFilter,
    searchItems,
    resetPagination,
    handleClearSearch,
    loading: isLoading,
    beeMessage: getBeeMessage(),
    loadingCategoriesColumn: isLoadingCategoriesByVendor,
    refetchGetCategoriesByVendor,
    refetchGetItems: handleRefetchGetItems,
  };
};

export default usePaginatedProducts;
