/* istanbul ignore file */
import { EditMode, useListContext } from 'context/list-context';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MarketplaceCategory } from 'types';
import { getUpdatedSortOrders } from 'utils/sortOrder';
import useSort from './useSort';

/**
 * Return all children with the passed id as parentVendorCategoryId
 * @param subcategories A category's subcategories
 * @param setUpdatedSubcategories Callback for updating sort order
 * @returns Category array
 */
const useSubcategories = (
  subcategories: MarketplaceCategory[] | undefined,
  setUpdatedSubcategories: ((array: MarketplaceCategory[]) => void) | undefined
): {
  sortedSubcategories: MarketplaceCategory[];
  handleMove: (dragIndex: number, hoverIndex: number) => void;
} => {
  const {
    state: { editMode },
  } = useListContext();
  const [defaultSubcategories, setDefaultSubcategories] = useState<MarketplaceCategory[]>([]);
  const [sortedSubcategories, setSortedSubcategories] = useState<MarketplaceCategory[]>([]);
  const { handleSort } = useSort();

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedSubcategories(handleSort(sortedSubcategories, dragIndex, hoverIndex));
      const updatedSubcategories = getUpdatedSortOrders(
        defaultSubcategories,
        handleSort(sortedSubcategories, dragIndex, hoverIndex)
      );
      if (!setUpdatedSubcategories) return;
      setUpdatedSubcategories(updatedSubcategories);
      setSortedSubcategories(updatedSubcategories);
    },
    [handleSort, sortedSubcategories]
  );

  useEffect(() => {
    const sorted = _.sortBy(subcategories || [], (c) => c.sortOrder);

    setDefaultSubcategories(sorted);
    setSortedSubcategories(sorted);
  }, [subcategories, setDefaultSubcategories]);

  // resets the subcategory tree if cancel button clicked
  useEffect(() => {
    if (editMode !== EditMode.sort) {
      setSortedSubcategories(defaultSubcategories);
    }
  }, [editMode, defaultSubcategories]);

  return {
    sortedSubcategories,
    handleMove,
  };
};

export default useSubcategories;
