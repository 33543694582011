import { makeStyles } from '@material-ui/core';

const useMultiSelectStyles = makeStyles({
  formControl: {
    display: 'flex',
    fontSize: 16,
    padding: '5px 0 12px 0',

    '& .toolTipHeader': {
      display: 'flex',
    },

    '& label': {
      width: 'max-content',
    },
  },
});

export const useCheckBoxStyles = makeStyles({
  root: ({ error }: { error: string }) => {
    let color = '#0000003d';

    if (error) {
      color = '#E02B27';
    }

    return {
      color,
      '& svg': {
        fontSize: '1.9rem',
      },
    };
  },
  checked: {
    color: '#000',
  },
});

export default useMultiSelectStyles;
