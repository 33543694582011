/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const useSelect = (
  defaultSelected?: any[]
): {
  selected: any[];
  handleSelect: (value: any | any[]) => void;
  handleClearSelection: () => void;
} => {
  const [selected, setSelected] = useState<any[]>(defaultSelected || []);
  useEffect(() => {
    if (defaultSelected?.length) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);
  const handleSelect = useCallback(
    (value) => {
      if (Array.isArray(value)) {
        setSelected(value);
      } else if (selected.find((d) => _.isEqual(d, value))) {
        setSelected(selected.filter((d) => !_.isEqual(d, value)));
      } else {
        setSelected([...selected, value]);
      }
    },
    [selected, setSelected]
  );
  const handleClearSelection = useCallback(() => {
    setSelected([]);
  }, []);

  return {
    selected,
    handleSelect,
    handleClearSelection,
  };
};

export default useSelect;
