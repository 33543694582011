import { MAX_ENABLED_COLLECTION_CATEGORIES } from 'constants/collection';
import { StateResponse } from 'pages/CategoryHome/useCategoryHome';
import { MarketplaceCategory } from 'types';

type Props = {
  categoryGroup: string;
  postStateArr: StateResponse[];
  prevStateArr: StateResponse[];
  enabledCollectionCategories: { categories: MarketplaceCategory[] };
  collectionGroup: string;
};

export function shouldBulkUpdateCategory({
  categoryGroup,
  postStateArr,
  prevStateArr,
  enabledCollectionCategories,
  collectionGroup,
}: Props): boolean {
  let collectionToBeEnabled: StateResponse[] = [];
  let collectionToBeDisabled: StateResponse[] = [];
  let collectionsEnabledToBeDisabled: StateResponse[] = [];
  let collectionsToBeEnabledFiltred: StateResponse[] = [];
  if (categoryGroup === collectionGroup) {
    collectionToBeEnabled = postStateArr.filter((category) => category.enabled === true);
    collectionToBeDisabled = postStateArr.filter((category) => category.enabled === false);
    collectionsEnabledToBeDisabled = collectionToBeDisabled.filter((categoryToBeDisabled) =>
      enabledCollectionCategories.categories.some(
        (enabledCategory: MarketplaceCategory) => enabledCategory.id === categoryToBeDisabled.id
      )
    );
    collectionsToBeEnabledFiltred = collectionToBeEnabled.filter(
      (categoryToBeEnabled) =>
        !enabledCollectionCategories.categories.some(
          (enabledCategory: MarketplaceCategory) => enabledCategory.id === categoryToBeEnabled.id
        )
    );
  }
  const maxCollectionsToEnable =
    MAX_ENABLED_COLLECTION_CATEGORIES -
    enabledCollectionCategories?.categories?.length +
    collectionsEnabledToBeDisabled.length;
  const shouldAllowEnableCollection =
    collectionsToBeEnabledFiltred.length <= maxCollectionsToEnable;
  const prevIds = prevStateArr.map((category) => category.id);
  const postIds = postStateArr.map((category) => category.id);
  const idsMatchPrevAndPost =
    prevIds.length === postIds.length && prevIds.every((id) => postIds.includes(id));
  const isUpdatingStatus =
    idsMatchPrevAndPost &&
    prevStateArr.some((prevState) => {
      const postState = postStateArr.find((postObj) => postObj.id === prevState.id);
      return postState && postState.enabled !== prevState.enabled;
    });
  const isUpdatingSortOrder =
    idsMatchPrevAndPost &&
    prevStateArr.some((prevState) => {
      const postState = postStateArr.find((postObj) => postObj.id === prevState.id);
      return postState && postState.sortOrder !== prevState.sortOrder;
    });
  const isUpdatingOnlySortOrder = isUpdatingSortOrder && !isUpdatingStatus;
  const shouldUpdate =
    categoryGroup !== collectionGroup || isUpdatingOnlySortOrder || shouldAllowEnableCollection;
  return shouldUpdate;
}
