import _ from 'lodash';
import { ItemSelection, MarketplaceItem } from 'types';

const useProductTable = ({
  selected,
  handleSelect,
  items,
  storedProducts,
}: {
  selected: ItemSelection[];
  handleSelect: (value: ItemSelection | ItemSelection[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: MarketplaceItem[];
  storedProducts: MarketplaceItem[] | ItemSelection[];
}): {
  handleSelectAll: () => void;
  isSelectAllToggled: boolean;
  isSelectedOnPage: boolean;
} => {
  const itemValues = _.map(items, ({ itemPlatformId, vendorItemId, vendorId }) => ({
    itemPlatformId,
    vendorItemId,
    vendorId,
  }));
  const isSelectAllToggled =
    !!selected.length &&
    _.isEqual(
      _.intersection(_.map(itemValues, 'itemPlatformId'), _.map(selected, 'itemPlatformId')),
      _.map(itemValues, 'itemPlatformId')
    );
  const isSelectedOnPage = !!_.intersection(
    _.map(itemValues, 'itemPlatformId'),
    _.map(_.differenceBy(selected, storedProducts, 'itemPlatformId'), 'itemPlatformId')
  ).length;

  const handleSelectAll = () => {
    if (!isSelectAllToggled && !isSelectedOnPage) {
      handleSelect(_.uniqBy([...selected, ...itemValues, ...storedProducts], 'itemPlatformId'));
    } else {
      const selectedWithoutItemValues = _.differenceBy(selected, itemValues, 'itemPlatformId');
      handleSelect(_.uniqBy([...selectedWithoutItemValues, ...storedProducts], 'itemPlatformId'));
    }
  };

  return {
    handleSelectAll,
    isSelectAllToggled,
    isSelectedOnPage,
  };
};

export default useProductTable;
