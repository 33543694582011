import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Props } from 'components/CategoryListItem/CategoryListItem';
import useCategoryListItemStyles from 'components/CategoryListItem/CategoryListItem.styles';
import { toggleListItemOpen, useListContext } from 'context/list-context';
import { useHistory } from 'react-router-dom';

const useCategoryListItem = (
  props: Props
): {
  isOpen: boolean;
  isSelected: boolean;
  handleClick: () => void;
  handleDetailsClick: (id: string) => void;
  listClasses: ClassNameMap;
} => {
  const { category, isParentSelected } = props;
  const {
    state: { openListIds, selectedListId, editMode },
    dispatch,
  } = useListContext();
  const { id, subcategories, root } = category;
  const listClasses = useCategoryListItemStyles({
    level: category.level ?? 1,
    isRoot: root,
    isChildless: !subcategories?.length,
    isParentSelected,
    editMode,
  });
  const isOpen = openListIds.includes(id as string);
  const isSelected = selectedListId === id;
  const history = useHistory();
  const handleClick = () => {
    toggleListItemOpen(dispatch, category);
  };

  const handleDetailsClick = (categoryId: string) => {
    history.push(`/category-marketplace/edit-category/${categoryId}`);
  };

  return {
    isOpen,
    isSelected,
    handleClick,
    handleDetailsClick,
    listClasses,
  };
};

export default useCategoryListItem;
