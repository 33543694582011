import TranslationKeys from '../i18n.d';

const ENGLISH_TRANSLATIONS: TranslationKeys = {
  Default: {
    disabled: 'Disabled',
    enabled: 'Enabled',
    clear: 'Clear',
    categories: 'Categories',
    product: 'Product',
    saveChanges: 'Save changes',
    cancelChanges: 'Cancel changes',
    goBack: 'No, go back',
    cancelAffirmation: 'Yes, Cancel',
    deleteAffirmation: 'Yes, Delete',
    cancel: 'Cancel',
    details: 'Details',
    create: 'Create',
    discard: 'Discard',
    level: 'Level',
    requiredField: 'This field is required',
    rowsPerPage: 'Rows per page',
    search: 'Search',
    categoryPath: 'Category Path',
    name: 'Name',
    edit: 'Edit',
    assign: 'Assign',
    microserviceID: 'Microservice ID',
    selectedProduct: 'product',
    selectedProducts: 'products',
    status: 'Status',
    position: 'Position',
    loading: 'Loading',
    ok: 'Ok',
    confirm: 'Confirm',
    remove: 'Remove',
    reorder: 'Reorder',
    of: 'of',
    category: 'Category',
    newPosition: 'New Position',
    currentPosition: 'Current Position',
    filter: 'FILTER',
    apply: 'Apply',
    gotIt: 'Got it',
  },
  Menu: {
    categories: 'Categories',
    products: 'Products',
  },
  CategoryTypes: {
    default: 'Default',
    premium: 'Premium',
    club: 'Club B Max',
  },
  EditCategory: {
    alertMessageErrorCategoryLevel:
      'This category contains subcategories and products but it can only display the subcategories. Please unassign all products from this category and assign them to the subcategories.',
    alertMessageErrorCategoryLevelProductTable:
      'These products are not being displayed to the customer because the BEES Customer prioritize the subcategories. Please, unassign all products from this category and make sure they’re assigned to its subcategories.',
    alertMessageErrorAction: 'See Products',
  },
  Tooltips: {
    tooltipTrashCategoryEnabled: 'You need to disable the category in order to delete it.',
    tooltipTrashCategoryDisabled: 'Delete category.',
    tooltipCategoryType: 'The type of program this category is enrolled to.',
    tooltipPremium: 'The Premium Key will tie this category to a certain portion of users.',
    tooltipPosition:
      'Choose whether this category will appear first (top) or last (bottom) in the categories list.',
    tooltipGroup:
      'If the category belongs to the regular navigation categories or special collections.',
    tooltipStatus: 'Choose whether this category should be now visible to users.',
    tooltipParentCategories: 'The category or categories that are going to carry this subcategory.',
    tooltipCategoryPath: 'The branch that leads to this category',
    tooltipCategoryName: 'How it appears on BEES Customer App {{0}} version',
    tooltipCreateNewCategory: 'Create new category',
    tooltipAlreadyAssigned: 'This product is already assigned to this category',
    tooltipExpirationClose: 'This product is close to expire and has a special discount.',
    tooltipExpirationGood:
      'This product has a good time until expiration and has a moderated discount.',
    tooltipExpirationFar: 'This product is currently far from expiring.',
    tooltipExpirationNoInfo: "There's no information available of this product Expiration Date.",
    tooltipStoreCategoryId:
      'This field allows this category to be also managed via relay integration',
    tooltipNotFoundSku:
      "There's no register of this product SKU. We’re showing the Vendor Item ID instead.",
  },
  CategoryHome: {
    confirmAssignments: 'Confirm assignments',
    subcategoriesInCategory: 'Number of subcategories in this category.',
    title: 'Categories',
    selectCountry: 'Select a Country',
    newCategory: 'New Category',
    editPosition: 'Edit Positions',
    productsInCategory: 'Number of products in this category.',
    subcategoriesWontShow:
      'This is the number of products in this category that are not in any of its subcategories. This number won’t appear to users.',
    subcategoriesInside: 'Number of subcategories in this category',
    wantToCancel: 'Are you sure you want to cancel all the changes on Categories?',
    confirmChangesPositions: 'Do you confirm the changes on categories positions?',
    successUpdateCategories: 'You have successfully updated your categories',
    selectStore: 'Select a Store',
    activeStore: 'Active',
    inactiveStore: 'Inactive',
    inExperimentationStore: 'In Experimentation',
    premiumCategory: 'This is a Premium Category, it will only appear for some customers.',
    clubBProgram:
      'This category will only be displayed to customers enrolled in the Club B Max program',
    noSubCategories: 'This category does not have any subcategories',
    noProductsAssigned: 'This category doesn’t have any products assigned to it.',
    successfulUpdatedCategories: 'You have successfully updated your Categories!',
    noCategories: 'There are no categories created yet.',
    noCollections: 'There are no collections created yet.',
    createNew: 'Would you like to <2>create one</2>?',
    categorySelected: 'category selected',
    categoriesSelected: 'categories selected',
    categoriesTheseEnabled: 'Enable these categories',
    categoriesTheseDisabled: 'Disable these categories',
    toEnabled: 'To be enabled',
    toDisabled: 'To be disabled',
    bulkStatusOptionDefault: 'Change status to',
    bulkStatusOptionEnabled: 'Enabled',
    bulkStatusOptionDisabled: 'Disabled',
  },
  CategoryCreation: {
    title: 'New Category',
    confirmCreation: 'Confirm categories creation',
    confirmCreationMessage:
      'Do you confirm this category creation? Remember that you can always come back to edit details and images, and also manage products.',
    discardCreation: 'Discard Creation',
    discardCreationConfirmation:
      'Do you really want to discard this category creation and lose all the progress?',
    submitCategory: 'Submit category information and attributes required',
    mainInformation: 'Main Information',
    categoryName: 'Category Name',
    insertName: 'Insert name here...',
    categoryDetails: 'Category Details',
    categoryType: 'Category Type',
    categoryGroup: 'Group',
    categoryLevel: 'Level',
    categoryStatus: 'Status',
    categoryPosition: 'Position',
    categoryStatusHint: 'You can only have three enabled collections at a time.',
    top: 'Top',
    bottom: 'Bottom',
    insertKey: 'Insert a valid Key here...',
    selectAllCategories: 'Select all categories that apply.',
    selectAllCategoriesTo: 'Select all categories that apply to your {{0}}.',
    atLeastOneCategory: 'You need to select at least one category.',
    categoryList: 'Category List',
    parentCategories: 'Parent Categories',
    noCategories: 'No categories to display.',
    premiumKey: 'Premium Key',
    storeCategoryId: 'Store Category ID',
    insertValue: 'Insert a value here...',
    maxCollectionsEnabledError: 'You already have three collections enabled.',
  },
  Products: {
    assignProduct: 'Assign Products',
    title: 'Products',
    assignedProducts: 'Products Assigned',
    addProducts: 'Add Products',
    noProducts: 'There are no products added at the moment.',
    noResults: 'No results were found. Try searching for a different product.',
    invalidSearch: 'No product to assign was searched yet.',
    confirmProductsAssigned: 'Do you confirm all the changes on Products Assigned?',
    cancelProductsAssigned: 'Are you sure you want to cancel all the changes on Products Assigned?',
    productList: 'Product List',
    productListSubtitle:
      'Manage your assignments by selecting or unselecting products from this list.',
    searchBySKU: 'Search using enter or comma to separate SKUs',
    newSelectedProduct: 'new product selected',
    newSelectedProducts: 'new products selected',
    reorderTitle: 'Reorder',
    reorderSubtitle: 'Inform the new position of this product',
    allVendors: 'All vendors',
    searchBySingleSKU: 'Search by SKU',
    categorized: 'Categorized products',
    uncategorized: 'Uncategorized products',
  },
  ProductsTable: {
    headerPosition: 'Position',
    headerProductTitle: 'Product Title',
    headerSKU: 'SKU Number',
    headerVendor: 'Vendor',
    headerVendorItemID: 'Vendor Item ID',
    headerExpiration: 'Expiration Group',
    headerStatus: 'Status',
    headerCategories: 'Categories',
    loading: 'Loading Products',
    disabled: 'Disabled',
    enabled: 'Enabled',
    deleted: 'Deleted',
    deletedDescription: 'Deleted product. Remove it from this category.',
    notFoundDescription: "This product hasn't been registered yet.",
    notFound: 'Not Found',
    zeroCategories:
      'This product is not being displayed to any POCs because it’s not assigned to a category.',
    categories: 'Categories',
    iconXTooltip: 'Remove product from this category',
    confirmUnassign: 'Yes, unassign',
    unassignProduct: 'Unassign product?',
    unassignProductMessage: "You're about to unassign {{0}} from {{1}}",
  },
  CategoryDetails: {
    confirmAllChanges: 'Do you confirm all the changes on category details?',
    cancelAllChanges: 'Are you sure you want to cancel all the changes on category details?',
    enableCategory: 'Enable category',
    disableCategory: 'Disable category',
    enableCategoryMessage:
      'Once you enable it, this category will become visible and accessible to all customers if its whole branch is also enabled.',
    disableCategoryMessage:
      'Once you disable it, the customers won’t be able to see or access this category and its subcategories unless you enable it again.',
    invalidPremiumKey: 'Value is not allowed',
  },
  ImageContext: {
    uploadIcon: 'Upload icon (Optional)',
    uploadImage: 'Upload image (Optional)',
    iconsDesc: 'Icons must be JPG or PNG, and cannot exceed 1MB. See guidelines.',
    imagesDesc: 'Images must be JPG or PNG, and cannot exceed 1MB. See guidelines.',
    deleteImage: 'Delete image?',
    deleteImageConfirmation: 'Are you sure you want to delete the current image?',
    uploadGuide: 'Follow these recommendations to upload Category Images correctly:',
    resolutionRecommendation: 'Resolution Recommendation: {{0}} or {{1}}',
    alertMessage: "Make sure you're uploading an image (JPG or PNG format) that has less than 2MB.",
    needHelp: 'Need help?',
  },
  Messages: {
    wontAffect: 'This process will not affect previous assignments.',
    confirmationAssignments:
      'You are assigning {{0}} to these categories below. Do you confirm these assignments?',
  },
  Toasts: {
    errorGetStores: 'Unable to get the stores. Please try again.',
    errorProducts: 'Unable to get products. Please try again.',
    errorUpdateCategory: 'Unable to update your category. Please try again.',
    errorCreateCategory: 'Your category creation has failed. Please try again.',
    successfulCreateCategory: 'All good! You have created a new category.',
    successfulUpdatedCategoryThumbnail: 'You have successfully updated your Category Thumbnail!',
    successfulAssignProducts: 'You have successfully updated your Products Assigned list',
    errorAssignProducts: 'Unable to update your Products Assigned list.',
    successfulAssignment: 'All good! You have updated product assignments.',
    errorAssignment: 'Your assignment update has failed. Please try again.',
    successUnassignProduct: 'You have successfully unassigned {{0}} from {{1}}.',
    infoUnassignProduct: 'Unassigning {{0}} from {{1}}. It might take a few seconds.',
    errorUnassignProduct: 'Unable to unassigned the product. Please try again.',
    successDeleteCategory: 'You have successfully deleted your category.',
    errorDeleteCategory: 'Your category deletion has failed. Please try again.',
  },
  Alerts: {
    infoAllVendors: 'Only one SKU can be searched at a time when All vendors is selected.',
  },
  Search: {
    singleSearchPlaceholder: 'Insert a SKU',
  },
  CategoryGroups: {
    fieldTitle: 'Group',
    fieldTooltip:
      'If the category belongs to the regular navigation categories or special collections',
    NAVIGATION: 'Navigation',
    COLLECTION: 'Collection',
  },
  CollectionGuideline: {
    collectionPage: 'Collection page',
    exploreProducts: 'Explore products',
    recommendationMessage: 'Follow these recommendations to upload collection images correctly:',
    recommendedResolution: 'Recommended resolution: 296x296px',
    recommendedFormat: 'Recommended format: PNG',
    recommendedSize: 'Maximum image size: 2MB',
  },
  UseCategoryToggle: {
    toastError:
      "You can't enable this category because you already have three collections enabled.",
  },
  UseCategoryHome: {
    toastError: "You can't have more than 3 collections enabled at the same time.",
  },
};

export default ENGLISH_TRANSLATIONS;
