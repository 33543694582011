import { InfoOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';

const MessageBar = ({ message }: { message: string }): JSX.Element => {
  return (
    <Alert
      // -5px offsets default 12px margin on parent to match prototype
      icon={<InfoOutlined style={{ color: '#007A78', marginRight: -5 }} fontSize="small" />}
      style={{ backgroundColor: '#D1F7F6', color: '#000', padding: '2px 11px' }}
    >
      {message}
    </Alert>
  );
};

export default MessageBar;
