import TranslationKeys from '../i18n.d';

const KOREAN_TRANSLATIONS: TranslationKeys = {
  Default: {
    disabled: '비활성화됨',
    enabled: '활성화됨',
    clear: '분명한',
    categories: '카테고리',
    product: '제품',
    saveChanges: '변경사항 저장',
    cancelChanges: '변경 취소',
    goBack: '돌아가기',
    cancelAffirmation: '예, 취소',
    deleteAffirmation: '삭제하기',
    cancel: '취소',
    details: '세부',
    create: '새로 만들기',
    discard: '취소',
    level: '수준',
    requiredField: '이 필드는 필수 항목입니다.',
    rowsPerPage: '페이지당 행',
    search: '검색',
    categoryPath: '카테고리 경로',
    name: '이름',
    edit: '수정',
    assign: '양수인',
    microserviceID: '마이크로서비스 ID',
    selectedProduct: '제품',
    selectedProducts: '제품',
    status: '상태',
    position: '위치',
    loading: '로딩 중',
    ok: '확인',
    confirm: '확인하다',
    remove: '제거하다',
    reorder: '재 주문',
    of: '/',
    newPosition: '새로운 위치',
    currentPosition: '현재 위치',
    category: '종류',
    filter: '필터',
    apply: '적용하다',
    gotIt: '알았다',
  },
  Menu: {
    categories: '카테고리',
    products: '제품',
  },
  CategoryTypes: {
    default: '기본',
    premium: '프리미엄',
    club: '클럽 B 맥스',
  },
  EditCategory: {
    alertMessageErrorCategoryLevel:
      '이 카테고리에는 하위 카테고리와 제품이 포함되어 있지만 하위 카테고리 만 표시할 수 있습니다. 이 카테고리에서 모든 제품을 해제하고 하위 카테고리에 할당하십시오.',
    alertMessageErrorCategoryLevelProductTable:
      '이러한 제품은 BEES 고객이 하위 카테고리를 우선시하기 때문에 고객에게 표시되지 않습니다. 이 카테고리에서 모든 제품을 해제하고 하위 카테고리에 할당하십시오.',
    alertMessageErrorAction: '제품 보기',
  },
  Tooltips: {
    tooltipTrashCategoryEnabled: '카테고리를 삭제하려면 해당 카테고리를 비활성화해야 합니다.',
    tooltipTrashCategoryDisabled: '카테고리를 삭제하세요.',
    tooltipCategoryType: '이 카테고리가 등록된 프로그램 유형',
    tooltipPremium: '프리미엄 키는 이 범주를 사용자의 특정 부분과 연결합니다.',
    tooltipPosition:
      '이 범주가 범주 목록에서 처음(상단) 또는 마지막(하단)으로 표시되는지 선택합니다.',
    tooltipStatus: '이제 이 카테고리를 사용자에게 표시할지 여부를 선택합니다.',
    tooltipParentCategories: '이 하위 범주를 포함할 범주 또는 범주입니다.',
    tooltipCategoryPath: '이 카테고리로 연결되는 분기',
    tooltipGroup: '카테고리가 일반 탐색 카테고리 또는 특별 컬렉션에 속하는 경우.',
    tooltipCategoryName: 'BEES 영어 버전 앱에 표시되는 방식',
    tooltipCreateNewCategory: '새 카테고리 만들기',
    tooltipAlreadyAssigned: '이 제품은 이미 이 카테고리에 할당되어 있습니다.',
    tooltipExpirationClose: '이 제품은 만료가 임박했으며 특별 할인이 있습니다.',
    tooltipExpirationGood: '이 제품은 만료까지 좋은 시간이 있고 적당한 할인이 있습니다.',
    tooltipExpirationFar: '이 제품은 현재 만료가 얼마 남지 않았습니다.',
    tooltipExpirationNoInfo: `이 제품의 유효기간에 대한 정보가 없습니다.`,
    tooltipStoreCategoryId:
      '이 필드를 사용하면 릴레이 통합을 통해서도 이 범주를 관리할 수 있습니다.',
    tooltipNotFoundSku: '이 제품 SKU의 등록이 없습니다. 대신 공급업체 항목 ID를 표시하고 있습니다.',
  },
  CategoryHome: {
    confirmAssignments: '할당을 확인하시겠습니까?',
    subcategoriesInCategory: '이 범주의 하위 범주 수입니다.',
    title: '카테고리',
    selectCountry: '국가를 선택해주세요.',
    newCategory: '새 분류',
    editPosition: '위치 편집',
    productsInCategory: '이 카테고리의 제품 수입니다.',
    subcategoriesWontShow:
      '하위 카테고리에 없는 이 카테고리의 제품 수입니다.. 이 번호는 사용자에게 표시되지 않습니다.',
    subcategoriesInside: '이 범주의 하위 범주 수',
    wantToCancel: '카테고리의 모든 변경 사항을 취소하시겠습니까?',
    confirmChangesPositions: '카테고리 위치의 변경 사항을 확인하셨습니까?',
    successUpdateCategories: '카테고리를 성공적으로 업데이트했습니다.',
    selectStore: '매장 선택',
    activeStore: '활성',
    inactiveStore: '비활성',
    inExperimentationStore: '실험 중',
    premiumCategory: '이것은 프리미엄 카테고리이며 일부 고객에게만 표시됩니다.',
    clubBProgram: '이 카테고리는 클럽 B 맥스 프로그램에 등록한 고객에게만 표시됩니다.',
    noSubCategories: '이 카테고리에는 하위 카테고리가 없습니다.',
    noProductsAssigned: '이 카테고리에는 할당된 제품이 없습니다.',
    successfulUpdatedCategories: '카테고리를 성공적으로 업데이트했습니다!',
    noCategories: '아직 생성된 카테고리가 없습니다.',
    noCollections: '아직 생성된 카테고리가 없습니다.',
    createNew: '하나 만드시겠 <2>습니까</2>?',
    categorySelected: '카테고리 선택',
    categoriesSelected: 'X개의 카테고리가 선택됨',
    categoriesTheseEnabled: '이러한 카테고리를 활성화',
    categoriesTheseDisabled: '이러한 카테고리를 비활성화',
    toEnabled: '활성화',
    toDisabled: '비활성화',
    bulkStatusOptionDefault: '상태 변경',
    bulkStatusOptionEnabled: '가능',
    bulkStatusOptionDisabled: '장애가 있는',
  },
  CategoryCreation: {
    title: '새 분류',
    confirmCreation: '카테고리 생성 확인',
    confirmCreationMessage:
      '이 카테고리 생성을 확인합니까? 언제든지 다시 돌아와 세부 정보와 이미지를 편집하고 제품을 관리할 수 있습니다.',
    discardCreation: '생성 취소',
    discardCreationConfirmation: '이 카테고리 생성을 취소하고 모든 진행 상황을 잃게 하시겠습니까?',
    submitCategory: '필수 카테고리 정보 및 속성 제출',
    mainInformation: '주요 정보',
    categoryName: '카테고리 이름',
    insertName: '여기에 이름을 입력하세요...',
    categoryDetails: '카테고리 세부 정보',
    categoryType: '카테고리 유형',
    categoryGroup: '그룹',
    categoryLevel: '레벨',
    categoryStatus: '상태',
    categoryPosition: '위치',
    categoryStatusHint: '한 번에 삼 개의 컬렉션만 활성화할 수 있습니다.',
    top: '맨 위',
    bottom: '맨 아래',
    insertKey: '여기에 유효한 키를 삽입하십시오...',
    selectAllCategories: '해당하는 카테고리를 모두 선택하세요.',
    selectAllCategoriesTo: '{{0}} 개 제품에 적용되는 모든 카테고리를 선택하십시오',
    atLeastOneCategory: '카테고리를 하나 이상 선택해야 합니다.',
    categoryList: '카테고리 목록',
    parentCategories: '상위 카테고리',
    noCategories: '표시할 카테고리가 없습니다.',
    premiumKey: '프리미엄 키',
    storeCategoryId: '매장 카테고리 ID',
    insertValue: '여기에 값을 입력하세요...',
    maxCollectionsEnabledError: '이미 삼 개의 컬렉션이 활성화되어 있습니다.',
  },
  Products: {
    assignProduct: '제품 할당',
    title: '제품',
    assignedProducts: '할당된 제품',
    addProducts: '제품 추가',
    noProducts: '현재 추가된 상품이 없습니다',
    noResults: '검색된 결과가 없습니다. 다른 제품을 검색해 보십시오.',
    invalidSearch: '할당할 제품이 아직 검색되지 않았습니다.',
    confirmProductsAssigned: '할당된 제품의 모든 변경 사항을 확인하셨나요?',
    cancelProductsAssigned: '할당된 제품의 모든 변경 사항을 취소하시겠습니까?',
    productList: '상품 목록',
    productListSubtitle: '이 목록에서 제품을 선택하거나 선택 취소하여 할당을 관리합니다.',
    searchBySKU: '또는 쉼표를 사용하여 SKU를 구분하여 검색',
    newSelectedProduct: '새 제품이 선택됨',
    newSelectedProducts: '신제품 선정',
    reorderTitle: '재 주문',
    reorderSubtitle: '이 제품의 새로운 위치를 알립니다',
    allVendors: '모든 벤더',
    searchBySingleSKU: 'SKU로 검색',
    categorized: '분류된 제품',
    uncategorized: '분류되지 않은 제품',
  },
  ProductsTable: {
    headerPosition: '위치',
    headerProductTitle: '상품명',
    headerSKU: 'SKU 번호',
    headerVendor: '공급업체',
    headerExpiration: '만료 그룹',
    headerStatus: '상태',
    headerCategories: '카테고리',
    loading: '제품 로드 중',
    disabled: '비활성화됨',
    enabled: '활성화됨',
    deleted: '삭제됨',
    deletedDescription: '삭제된 상품입니다. 이 범주에서 제거하십시오.',
    notFoundDescription: '아직 등록되지 않은 상품입니다.',
    notFound: '찾을 수 없음',
    zeroCategories: '이 제품은 카테고리에 할당되지 않았기 때문에 어떤 POC에도 표시되지 않습니다.',
    categories: '카테고리',
    iconXTooltip: '이 범주에서 제품 제거',
    confirmUnassign: '예, 할당 해제합니다.',
    unassignProduct: '제품 할당을 취소하시겠습니까?',
    unassignProductMessage: '{{1}}에서 {{0}}의 할당을 해제하려고 합니다',
  },
  CategoryDetails: {
    confirmAllChanges: '카테고리 세부 사항에 대한 모든 변경 사항을 확인합니까?',
    cancelAllChanges: '카테고리 세부 정보의 모든 변경을 취소하시겠습니까?',
    enableCategory: '카테고리 활성화',
    disableCategory: '카테고리 비활성화',
    enableCategoryMessage:
      '활성화하면 전체 지점도 활성화된 경우 이 범주가 표시되고 모든 고객이 액세스할 수 있습니다.',
    disableCategoryMessage:
      '비활성화하면 다시 활성화하지 않는 한 고객이 이 카테고리와 하위 카테고리를 보거나 액세스할 수 없습니다.',
    invalidPremiumKey: '허용되지 않는 값',
  },
  ImageContext: {
    uploadIcon: '업로드 아이콘(선택사항)',
    uploadImage: '이미지 업로드(선택사항)',
    iconsDesc: '아이콘은 JPG, PNG 형식이어야 하며 1MB 미만이어야 합니다.',
    imagesDesc: '이미지는 JPG 또는 PNG여야 하며 1MB를 초과할 수 없습니다. 지침을 참조하십시오.',
    deleteImage: '이미지를 삭제하시겠습니까?',
    deleteImageConfirmation: '현재 이미지를 삭제하시겠습니까?',
    uploadGuide: '카테고리 이미지를 올바르게 업로드하려면 다음 권장 사항을 따르십시오.',
    resolutionRecommendation: '해결 권장 사항: {{0}} 또는 {{1}}',
    alertMessage: '2MB 미만의 이미지(JPG 또는 PNG 형식)를 업로드하고 있는지 확인하세요.',
    needHelp: '도움이 필요하다?',
  },
  Messages: {
    wontAffect: '이 프로세스는 이전 할당에 영향을 미치지 않습니다.',
    confirmationAssignments:
      '아래 카테고리에 {{0}} 을(를) 할당하고 있습니다. 이 할당을 확인합니까?',
  },
  Toasts: {
    errorGetStores: '상점을 가져올 수 없습니다. 다시 시도해 주세요.',
    errorProducts: '상품을 받을 수 없습니다. 다시 시도해 주세요.',
    errorUpdateCategory: '카테고리를 업데이트할 수 없습니다. 다시 시도해 주세요.',
    errorCreateCategory: '카테고리 생성에 실패했습니다. 다시 시도해 주세요.',
    successfulCreateCategory: '새 카테고리를 만들었습니다.',
    successfulUpdatedCategoryThumbnail: '카테고리 썸네일을 성공적으로 업데이트했습니다!',
    successfulAssignProducts: '제품 할당 목록을 성공적으로 업데이트했습니다.',
    errorAssignProducts: '제품 할당 목록을 업데이트할 수 없습니다.',
    successfulAssignment: '제품 할당을 업데이트했습니다.',
    errorAssignment: '할당 업데이트에 실패했습니다. 다시 시도해 주세요.',
    successUnassignProduct: '{{1}}에서 {{0}} 할당을 성공적으로 취소했습니다.',
    infoUnassignProduct: '{{1}}에서 {{0}} 할당을 해제합니다. 몇 초 정도 걸릴 수 있습니다.',
    errorUnassignProduct: '제품 할당을 취소할 수 없습니다. 다시 시도해 주세요.',
    successDeleteCategory: '카테고리를 성공적으로 삭제했습니다.',
    errorDeleteCategory: '카테고리 삭제에 실패했습니다. 다시 시도해 주세요.',
  },
  Alerts: {
    infoAllVendors: '모든 공급업체를 선택한 경우 한 번에 하나의 SKU만 검색할 수 있습니다.',
  },
  Search: {
    singleSearchPlaceholder: 'SKU 삽입',
  },
  CategoryGroups: {
    fieldTitle: '그룹',
    fieldTooltip: '카테고리가 일반 내비게이션 카테고리 또는 특별 컬렉션에 속하는 경우',
    NAVIGATION: '항해술',
    COLLECTION: '수집',
  },
  CollectionGuideline: {
    collectionPage: '컬렉션 페이지',
    exploreProducts: '제품 살펴보기',
    recommendationMessage: '컬렉션 이미지를 올바르게 업로드하려면 다음 권장 사항을 따르세요.',
    recommendedResolution: '권장 해상도: 296x296px',
    recommendedFormat: '권장 형식: PNG',
    recommendedSize: '최대 이미지 크기: 2MB',
  },
  UseCategoryToggle: {
    toastError: '이미 삼 개의 컬렉션이 활성화되어 있으므로 이 카테고리를 활성화할 수 없습니다.',
  },
  UseCategoryHome: {
    toastError: '동시에 3개 이상의 컬렉션을 활성화할 수 없습니다.',
  },
};

export default KOREAN_TRANSLATIONS;
