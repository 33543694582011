import { useCallback, useState } from 'react';

type SearchValue = {
  id: string;
  value: string;
};

type ProductCategoryFilter = boolean | null;

const useHandleSearch = (
  resetPagination: () => void
): {
  handleClearSearch: () => void;
  handleSearch: (searchValues: Array<SearchValue>) => void;
  setProductHasCategoryFilter: (hasCategory: ProductCategoryFilter) => void;
  searchItems: string[];
  productHasCategoryFilter: ProductCategoryFilter;
} => {
  const [searchItems, setSearchItems] = useState<string[]>([]);
  const [productHasCategoryFilter, setProductCategoryFilter] =
    useState<ProductCategoryFilter>(null);

  const handleSearch = useCallback(
    (searchValues: Array<SearchValue>) => {
      setSearchItems(searchValues.map((searchValue) => searchValue.value));
      resetPagination();
    },
    [resetPagination]
  );

  const handleClearSearch = useCallback(() => {
    setSearchItems([]);
    resetPagination();
  }, [resetPagination]);

  const setProductHasCategoryFilter = useCallback(
    (hasCategory: ProductCategoryFilter) => {
      setProductCategoryFilter(hasCategory);
      handleSearch([]);
      resetPagination();
    },
    [resetPagination]
  );

  return {
    handleClearSearch,
    handleSearch,
    setProductHasCategoryFilter,
    searchItems,
    productHasCategoryFilter,
  };
};

export default useHandleSearch;
