import React from 'react';
import { IconButton, IconButtonTypes } from '@hexa-ui/components';
import { Icon, IconsNames } from 'components/Icons';
import { Tooltip } from 'components/Display';

type Props = {
  id: string;
  name: IconsNames;
  onClickButton?(e?: any): void;
  variant?: 'tertiary' | 'inherit';
  message: string;
} & Omit<IconButtonTypes, 'icon' | 'variant' | 'onClick'>;

const IconButtonTooltip = ({
  id,
  name,
  variant = 'tertiary',
  onClickButton,
  size,
  elevation,
  loading,
  disabled,
  className,
  message,
}: Props): JSX.Element => {
  return (
    <Tooltip message={message}>
      <IconButton
        id={id}
        data-testid={id}
        icon={() => <Icon id={`icon-button-icon-${id}`} name={name} />}
        variant={variant}
        onClick={onClickButton}
        size={size}
        elevation={elevation}
        loading={loading}
        disabled={disabled}
        className={className}
      />
    </Tooltip>
  );
};

export default IconButtonTooltip;
