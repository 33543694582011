import { makeStyles } from '@material-ui/core';

const useStatusIndicatorStyles = makeStyles({
  wrapper: {
    minWidth: 87,
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginRight: 10,
  },
  text: {
    fontWeight: 100,
  },
});

export default useStatusIndicatorStyles;
