import { Typography } from '@material-ui/core';
import React from 'react';
import { useTextStyles } from 'styles';
import { InfoTooltip } from 'supplier-portal-shared-components';

const defaultProps = {
  tooltip: null,
};

const InfoField = ({
  label,
  value,
  tooltip,
}: {
  label: string;
  value: string | JSX.Element;
  tooltip?: string;
}): JSX.Element => {
  const classes = useTextStyles();
  return (
    <>
      <Typography
        data-testid="info-label"
        variant="body1"
        classes={{
          root: classes.label,
        }}
      >
        {label}
        {tooltip && <InfoTooltip infoMessage={tooltip} placement="top" />}
      </Typography>
      <Typography
        data-testid="info-value"
        variant="body1"
        classes={{
          root: classes.value,
        }}
      >
        {value}
      </Typography>
    </>
  );
};

InfoField.defaultProps = defaultProps;

export default InfoField;
