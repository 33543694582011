import React from 'react';

const KeyIcon = (): JSX.Element => (
  <svg
    width="16px"
    height="15px"
    viewBox="0 0 16 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icons / Key</title>
    <g
      id="💠-Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Categories-/-Level-1---Express-Delivery-/-Multilanguage"
        transform="translate(-441.000000, -17.000000)"
        stroke="#939191"
      >
        <g id="Group" transform="translate(60.000000, 13.000000)">
          <g id="Group-2" transform="translate(379.000000, 2.000000)">
            <g id="key-(1)" transform="translate(3.333333, 3.333333)">
              <path
                d="M12.6643895,0 L11.3265017,1.33788776 M6.23583875,6.4285507 C7.17568153,7.35588278 7.54656611,8.715717 7.20769092,9.99180981 C6.86881573,11.2679026 5.87214515,12.2645732 4.59605234,12.6034484 C3.31995954,12.9423236 1.96012531,12.571439 1.03279324,11.6315962 C-0.361518036,10.1879592 -0.341577424,7.89322589 1.07761103,6.47403744 C2.49679948,5.05484898 4.7915328,5.03490837 6.23516981,6.42921965 L6.23583875,6.4285507 Z M6.23583875,6.4285507 L8.9851981,3.67919135 M8.9851981,3.67919135 L10.9920297,5.68602299 L13.3333333,3.34471941 L11.3265017,1.33788776 M8.9851981,3.67919135 L11.3265017,1.33788776"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default KeyIcon;
