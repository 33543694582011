import React, { useState } from 'react';
import { ChangeEventBulkDropdown } from 'components/ChangeStatusInBulkDropdown/ChangeStatusInBulkDropdown';

const useChangeStatusInBulk = (): {
  selectedValue: string;
  handleChangeOnBulkDropdown: (event: React.ChangeEvent<{ value: unknown }>) => void;
  setDefaultStatus: () => void;
} => {
  const [selectedValue, setSelectedValue] = useState<string>('default');

  const handleChangeOnBulkDropdown = (event: ChangeEventBulkDropdown) => {
    const { value } = event.target;
    setSelectedValue(value as string);
  };

  const setDefaultStatus = () => {
    setSelectedValue('default');
  };

  return {
    selectedValue,
    handleChangeOnBulkDropdown,
    setDefaultStatus,
  };
};

export default useChangeStatusInBulk;
