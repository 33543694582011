import { makeStyles } from '@material-ui/core';

export const userSortableItemStyle = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  dragIconContainer: {
    alignSelf: 'baseline',
  },
  dragIcon: {
    marginLeft: 8,
    marginTop: 13,
  },
});

export default userSortableItemStyle;
