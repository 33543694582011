import React from 'react';
import {
  IconProps,
  ChevronDown,
  Trash2,
  ChevronUp,
  X,
  Search,
} from '@admin-portal-shared-components/icons';
import { DISABLED } from 'themes/customTheme';

// eslint-disable-next-line no-shadow
export enum IconsNames {
  CHEVRON_DOWN = 'ChevronDown',
  CHEVRON_UP = 'ChevronUp',
  TRASH = 'Trash2',
  // eslint-disable-next-line no-shadow
  X = 'X',
  SEARCH = 'Search',
}

const ICONS = Object.freeze({
  [IconsNames.CHEVRON_DOWN]: ChevronDown,
  [IconsNames.CHEVRON_UP]: ChevronUp,
  [IconsNames.X]: X,
  [IconsNames.TRASH]: Trash2,
  [IconsNames.SEARCH]: Search,
});

type Props = IconProps & {
  id: string;
  name: string;
  disabled?: boolean;
  className?: string;
};

const Icon = ({ id, name, size, disabled, className }: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const CurrentComponent = ICONS[name];

  const styleDisabled = disabled ? { color: DISABLED } : {};

  return (
    <CurrentComponent
      id={id}
      data-testid={id}
      size={size}
      className={className}
      style={styleDisabled}
    />
  );
};

export default Icon;
