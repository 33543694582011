import { makeStyles } from '@material-ui/core';
import { DARK_GRAY } from 'themes/customTheme';

const useMultipleSearchFieldStyles = makeStyles({
  // Hide details button by default
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    margin: 0,
    padding: '0 80px 0 40px',
    flexWrap: 'wrap',
    maxHeight: 76,
    // Prevent outline from scrolling with rest of the content
    position: 'unset',
    overflowY: 'auto',
  },
  input: {
    padding: '10px 14px 10px 4px',
    flex: '1 0 auto',
    whiteSpace: 'nowrap',
    width: 0,
  },
  chip: { backgroundColor: '#F1EDFC', alignSelf: 'start', marginTop: 4, fontSize: 16 },
  chipClear: { height: 18, width: 18, color: '#000' },
  searchIcon: { color: DARK_GRAY, position: 'absolute', left: 13, top: 9 },
  clearButton: {
    position: 'absolute',
    right: 0,
    top: 3,
    height: 'auto',
    '& button': {
      textTransform: 'none',
      color: '#ACA9A9',
      fontWeight: 400,
      marginRight: 14,
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useMultipleSearchFieldStyles };
