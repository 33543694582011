import React from 'react';
import { List, Typography } from '@material-ui/core';
import SortableItem from 'components/SortableItem/SortableItem';
import { EditMode, useListContext } from 'context/list-context';
import { useTranslation } from 'react-i18next';
import { MarketplaceCategory } from 'types';
import { EmptyState } from '@hexa-ui/components';

const defaultProps = {
  handleMove: undefined,
  handleUpdateSubcategories: undefined,
  handleSelect: undefined,
  selected: undefined,
};

type Props = {
  categories: MarketplaceCategory[];
  handleMove?: (dragIndex: number, hoverIndex: number) => void;
  handleSelect?: (vendorCategoryId: string) => void;
  handleUpdateSubcategories?: (array: MarketplaceCategory[]) => void;
  selected?: string[];
  isCollection?: boolean;
};

const CategoryList = ({
  categories,
  handleMove,
  handleSelect,
  handleUpdateSubcategories,
  selected,
  isCollection,
}: Props): JSX.Element => {
  const {
    state: { editMode },
  } = useListContext();

  const { t: translate } = useTranslation();

  return (
    <List data-testid="category-list" id="category-list" disablePadding>
      {categories.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '25px',
            gap: '16px',
          }}
        >
          <EmptyState.SectionLevel ilustrationName="beesNothingHere" />
          <Typography>
            {translate(isCollection ? 'CategoryHome.noCollections' : 'CategoryHome.noCategories')}
          </Typography>
        </div>
      )}
      <SortableItem
        categories={categories}
        handleMove={handleMove}
        handleUpdateSubcategories={handleUpdateSubcategories}
        handleSelect={handleSelect}
        selected={selected}
        disabled={editMode !== EditMode.sort}
      />
    </List>
  );
};

CategoryList.defaultProps = defaultProps;

export default CategoryList;
