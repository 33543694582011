import { Toast } from 'components';
import { ListProvider } from 'context/list-context';
import { NewCategoryProvider } from 'context/new-category';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const CategoryHome = lazy(() => import('pages/CategoryHome/CategoryHome'));
const NewCategory = lazy(() => import('pages/NewCategory/NewCategory'));
const EditCategory = lazy(() => import('pages/EditCategory/EditCategory'));
const Products = lazy(() => import('pages/Products/Products'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Toast />
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <ListProvider>
          <NewCategoryProvider>
            <Switch>
              <Route path="/category-marketplace/new-category" component={NewCategory} />
              <Route path="/category-marketplace/edit-category/:id" component={EditCategory} />
              <Route exact path="/category-marketplace/products" component={Products} />
              <Route path="/category-marketplace" component={CategoryHome} />
            </Switch>
          </NewCategoryProvider>
        </ListProvider>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
