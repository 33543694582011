import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { MarketplaceCategory, Props } from 'types';

// eslint-disable-next-line no-shadow
export enum EditMode {
  sort = 'sort',
  select = 'select',
}

const initialState: ListState = {
  selectedListId: '',
  openListIds: [],
  editMode: null,
};

type ListState = {
  selectedListId: string;
  openListIds: string[];
  editMode: EditMode | null;
};

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type ToggleAction = Action & {
  payload: { listItem: MarketplaceCategory };
};

type ListActions = ToggleAction | Action;

const ListContext = createContext<{ state: ListState; dispatch: Dispatch<ListActions> }>({
  state: initialState,
  dispatch: () => undefined,
});
ListContext.displayName = 'ListContext';

const listReducer = (state: ListState, action: ListActions): ListState => {
  const { type, payload } = action;
  switch (type) {
    case 'list item toggled': {
      const { listItem } = action.payload;
      const { id: listId, subcategories } = listItem;
      const { openListIds, selectedListId } = state;
      const isOpen = openListIds.find((id) => id === listId);
      let newOpenListIds = [...openListIds];
      let newSelectedListId = selectedListId;
      if (isOpen || selectedListId === listId) {
        newOpenListIds = newOpenListIds.filter((id) => id !== listId);
        if (newSelectedListId === listId) {
          newSelectedListId = '';
        }
        // Don't toggle open if list has no subcategories
      } else if (typeof listId === 'string') {
        if (subcategories && !!subcategories.length) {
          newOpenListIds.push(listId);
        }
        newSelectedListId = listId;
      }
      return {
        ...state,
        openListIds: newOpenListIds,
        selectedListId: newSelectedListId,
      };
    }
    case 'edit mode set': {
      return {
        ...state,
        editMode: payload,
      };
    }
    case 'list state reset':
      return initialState;
    default:
      return state;
  }
};
const useListContext = (): {
  state: ListState;
  dispatch: Dispatch<ListActions>;
} => {
  return useContext(ListContext);
};

const ListProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(listReducer, initialState);
  return <ListContext.Provider value={{ state, dispatch }}>{children}</ListContext.Provider>;
};

const toggleListItemOpen = (
  dispatch: Dispatch<ToggleAction>,
  listItem: MarketplaceCategory
): void => dispatch({ type: 'list item toggled', payload: { listItem } });

const setEditMode = (dispatch: Dispatch<Action>, mode: EditMode | null): void =>
  dispatch({ type: 'edit mode set', payload: mode });

export { ListProvider, useListContext, toggleListItemOpen, listReducer, setEditMode, ListContext };
