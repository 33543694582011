import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { DARK_GRAY } from 'themes/customTheme';

const useTooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: DARK_GRAY,
  },
  popper: {
    textAlign: 'center',
  },
});

type Props = {
  children: ReactElement;
  message: string;
};

const Tooltip = ({ children, message }: Props): JSX.Element => {
  const classes = useTooltipStyles();
  return (
    <MuiTooltip data-testid="tooltip" arrow placement="top" classes={classes} title={message}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
