import React, { createContext, Dispatch, useContext, useEffect, useReducer } from 'react';
import { Store, StoreActions, StoreData } from 'types/store';

interface StoreContextProps {
  state: StoreData;
  dispatch: Dispatch<StoreActions>;
}

export const STORE_STATE_KEY = 'store_state';

const initialState: StoreData = {
  stores: [],
  selectedStore: undefined,
  isSameStoreSelected: false,
};

const StoreContext = createContext<StoreContextProps>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => undefined,
});

const storeReducer = (state: StoreData, action: StoreActions): StoreData => {
  const { type, payload } = action;
  const selectedStore = localStorage.getItem('store_Id') as string;
  const vendorId = localStorage.getItem('vendor_Id');

  switch (type) {
    case 'set stores': {
      const selectedStoreFromPayload =
        payload.find((store: Store) => store.storeId === selectedStore) ||
        payload.find((store: Store) => store.defaultVendorId === vendorId);

      return {
        stores: payload,
        selectedStore: selectedStoreFromPayload,
        isSameStoreSelected: isSameStore(selectedStoreFromPayload),
      };
    }
    case 'update selected store': {
      const { stores } = state;
      const selectStore = stores.find((store: Store) => store.storeId === payload);
      localStorage.setItem('store_Id', selectStore?.storeId as string);
      return {
        ...state,
        selectedStore: selectStore,
        isSameStoreSelected: isSameStore(selectStore),
      };
    }
    default:
      return state;
  }
};

const StoreProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;

  const initializeFromLocalStorage = () => {
    const storeState = localStorage.getItem(STORE_STATE_KEY);
    return storeState ? JSON.parse(storeState) : initialState;
  };

  const [state, dispatch] = useReducer(storeReducer, initialState, initializeFromLocalStorage);

  useEffect(() => {
    // istanbul ignore next
    if (state) {
      localStorage.setItem(STORE_STATE_KEY, JSON.stringify(state));
    }
  }, [state]);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

const useStoreContext = (): StoreContextProps => {
  return useContext(StoreContext);
};

const setStores = (dispatch: Dispatch<StoreActions>, stores: Store[]): void => {
  dispatch({ type: 'set stores', payload: stores });
};

const setSelectedStore = (dispatch: Dispatch<StoreActions>, storeId: string): void => {
  dispatch({ type: 'update selected store', payload: storeId });
};

const isSameStore = (store: Store | undefined) => store?.name?.toLowerCase() === 'same store';

StoreContext.displayName = 'StoreContext';

export { StoreProvider, useStoreContext, storeReducer, StoreContext, setStores, setSelectedStore };
