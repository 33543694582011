import {
  CategoriesInfos,
  CategoryAPIResponse,
  ExpirationGroup,
  ItemAPIResponse,
  ItemSelection,
  MarketplaceCategory,
  MarketplaceItem,
} from 'types';
import { Vendor } from 'context/vendor-context';
import { ListDataDTO } from 'Api/dto/ListDataDTO';
import { DISABLED_RED, ENABLED_GREEN } from 'themes/customTheme';
import { flattenCategories } from 'utils/category-tree';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import unlink from 'assets/unlink.svg';

type CategoryDetailsPageParams = {
  itemsFromCategoryApi: ItemSelection[];
  formattedProductsFromItemsApi: MarketplaceItem[];
  vendorsByStore: Vendor[];
};

type CategoryDataParam = Pick<
  CategoryAPIResponse,
  'id' | 'items' | 'subcategories' | 'name' | 'enabled' | 'itemsCount'
>;

type ProductKey = {
  vendorId: string;
  vendorItemId: string;
};

type MountCategoriesInfosParams = {
  categoryData: CategoryDataParam[];
  categoriesInfosResponse?: Map<string, CategoriesInfos[]>;
  categoryPath?: string[];
};

type CategoriesInfosResponse = Map<string, CategoriesInfos[]>;

/**
 * Keep all rules of product table data
 */
// eslint-disable-next-line import/prefer-default-export
export class ProductTableDTO extends ListDataDTO {
  static translation = 'ProductsTable';

  /**
   * Main function to format product table data
   * Keep all rules
   * @param data arr of items coming from item api
   * @param categoryData arr of categories coming from category api
   * @return formatted product table data
   */
  static toApp = (
    data: ItemAPIResponse[],
    categoryData: CategoryDataParam[] = [],
    productHasCategoryFilter: boolean | null = null
  ): MarketplaceItem[] => {
    const response: MarketplaceItem[] = [];

    for (const item of data) {
      // add status property
      const newItem: MarketplaceItem = {
        ...item,
        status: { translation: '', color: '', description: '', className: 'statusDefault' },
      };
      newItem.status = ListDataDTO.formatStatus(
        {
          enabled: item.enabled,
          deleted: item.deleted,
        },
        ProductTableDTO.translation
      );

      ListDataDTO.removeProperty(newItem, 'enabled');
      ListDataDTO.removeProperty(newItem, 'deleted');

      if (productHasCategoryFilter !== false) {
        const categoriesInfos = ProductTableDTO.mountCategoriesInfos({ categoryData });
        newItem.categoriesInfos = ProductTableDTO.getCountUsingProductKey(categoriesInfos, item);
      } else {
        newItem.categoriesInfos = [];
      }

      response.push(newItem);
    }

    return response;
  };

  /**
   * Main function to format product table data where there is 'not found' products
   * @param itemsFromCategoryApi
   * @param formattedProductsFromItemsApi
   * @param vendorsByStore
   * @return formatted product table data
   */
  static toAppNotFound = ({
    itemsFromCategoryApi,
    formattedProductsFromItemsApi,
    vendorsByStore,
  }: CategoryDetailsPageParams): MarketplaceItem[] =>
    itemsFromCategoryApi.map((item) => {
      // replace existing products with item api response
      const toReplaceItem = formattedProductsFromItemsApi.find(
        ({ itemPlatformId }) => itemPlatformId === item.itemPlatformId
      );
      if (toReplaceItem) return toReplaceItem;

      // or build not found products
      const vendor = vendorsByStore.find(({ vendorId }) => vendorId === item.vendorId);
      const result = ProductTableDTO.buildNotFoundItem(item, vendor?.displayName || '');
      result.status = ListDataDTO.formatStatus({ notFound: true }, ProductTableDTO.translation);

      return result;
    });

  /**
   * Function to get the total of items
   * @param totalOfItemsFromAPI total of items coming from taxonomy api
   * @param categoryData arr of categories coming from category api
   * @param productHasCategoryFilter tells if the product has category filter
   * @return total of items
   */
  static calculateTotalOfItems = (
    totalOfItemsFromAPI: number,
    categoryData: MarketplaceCategory[] = [],
    productHasCategoryFilter: boolean | null = null
  ) => {
    const categories = flattenCategories(categoryData);
    const categorizedItems = new Set<string>();

    for (const category of categories) {
      if (category.items) {
        for (const item of category.items) {
          categorizedItems.add(item.vendorItemId);
        }
      }
    }

    let productsCount = totalOfItemsFromAPI;

    if (productHasCategoryFilter === true) {
      productsCount = categorizedItems.size;
    } else if (productHasCategoryFilter === false) {
      productsCount = totalOfItemsFromAPI - categorizedItems.size;
    }

    if (productsCount < 0) productsCount = 0;

    return productsCount;
  };

  /**
   * Build the string that represents the map key of a product to return the categories count
   * @param vendorId vendor id of the product
   * @param vendorItemId vendor item id of the product
   * @return the key to get the count
   */
  private static buildProductKey = ({ vendorId, vendorItemId }: ProductKey): string =>
    `${vendorId}:${vendorItemId}`;

  /**
   * Returns the categories count of the current product
   * @param categoriesInfos map of product key->'vendorId:vendorItemId' and its categories count
   * @param item a product containing vendor id and vendor item id
   * @return categories count of a product
   */
  private static getCountUsingProductKey = (
    categoriesInfos: CategoriesInfosResponse,
    item: ProductKey
  ): CategoriesInfos[] => {
    const productKey = ProductTableDTO.buildProductKey(item);
    return categoriesInfos.get(productKey) || [];
  };

  /**
   * Mount all categories infos related to existing products
   * @param categoryData arr of categories coming from category api
   * @param countResponse param that keeps the recursion response
   * @param categoryPath keep the category path from parent (category) to child (subcategory)
   * @return a map with key->'vendorId:vendorItemId' representing the product and value->the informations about the category
   */
  private static mountCategoriesInfos = ({
    categoryData,
    categoriesInfosResponse = new Map(),
    categoryPath = [],
  }: MountCategoriesInfosParams): Map<string, CategoriesInfos[]> => {
    let response: Map<string, CategoriesInfos[]> = new Map(categoriesInfosResponse);

    for (const category of categoryData) {
      const currentPath: string[] = categoryPath.slice();
      currentPath.push(category.name || '');

      if (category?.subcategories) {
        response = ProductTableDTO.mountCategoriesInfos({
          categoryData: category.subcategories,
          categoriesInfosResponse: response,
          categoryPath: currentPath,
        });
        // TODO: should have a break here when the parent categories no longer have related products
      }

      const categoryItems = category?.items || [];

      for (const categoryItem of categoryItems) {
        const productKey = ProductTableDTO.buildProductKey(categoryItem);
        const current: CategoriesInfos[] = response.get(productKey) || [];
        current.push({
          itemsCount: category.itemsCount,
          statusColor: category.enabled ? ENABLED_GREEN : DISABLED_RED,
          path: currentPath,
          id: category.id,
        });
        response.set(productKey, current);
      }
    }

    return response;
  };

  /**
   * Build a 'mocked' product as 'not found' when the current product has the status not found
   * @param item a product
   * @param vendorName vendor name related to the product
   * @return new product
   */
  private static buildNotFoundItem = (item: ItemSelection, vendorName: string): MarketplaceItem => {
    return {
      itemPlatformId: item.itemPlatformId || '',
      sortOrder: item.sortOrder || 0,
      vendorId: item.vendorId || '',
      vendorItemId: item.vendorItemId || '',
      sku: item.vendorItemId || '',
      id: item.vendorItemId ? `${item.vendorItemId}:${item.itemPlatformId}` : '',
      name: 'Name not found',
      expirationGroup: ExpirationGroup.REGULAR,
      image: unlink,
      status: {
        translation: '',
        color: '',
        description: '',
        className: 'statusDefault',
      },
      vendorName,
    };
  };
}
