import React, { useCallback, useEffect } from 'react';
import { Toast as HToast } from '@hexa-ui/components';
import { hideToast, useToastContext } from 'context/toast-context';

const Toast = (): JSX.Element => {
  const { state, dispatch } = useToastContext();
  const handleClose = useCallback(
    (key?: number) => {
      hideToast(dispatch, key);
    },
    [dispatch]
  );

  useEffect(() => {
    if (state.toasts.length) {
      const timer = setTimeout(() => {
        handleClose();
      }, state.duration);

      return () => clearTimeout(timer);
    }

    return () => {
      // do nothing
    };
  }, [handleClose, state.duration, state.toasts.length]);

  return (
    <HToast.Provider>
      {state.toasts.map(({ show, severity, text }, idx) => (
        <HToast.Root
          key={`${severity}-${text}`}
          data-testid={`toast-${severity}`}
          showCloseButton
          message={text}
          onClose={() => handleClose(idx)}
          position="top-right"
          open={show}
          type={severity}
        />
      ))}
    </HToast.Provider>
  );
};

export default Toast;
