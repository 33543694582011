/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export interface IRequest {
  url?: string;
  body?: any;
  headers?: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Api = (categoryLanguage?: string) => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const selectedCountry = localStorage.getItem('selectedCountry');
  const initialAxios = Axios.create();

  authentication.enhancedAxios(initialAxios, {
    headers: [
      {
        country: (selectedCountry || userCountry) as string,
        requestTraceId: uuidv4(),
        'Accept-Language': categoryLanguage as string,
        vendorId: localStorage.getItem('vendor_Id') as string,
      },
    ],
  });

  return {
    post: ({ url, body, headers }: IRequest): Promise<any> =>
      initialAxios.post(`${url}`, body, { headers }).catch((error) => ({
        hasError: true,
        ...error,
      })),
    delete: ({ url }: IRequest): Promise<any> =>
      initialAxios.delete(`${url}`).catch((error) => ({ hasError: true, ...error })),
    get: ({ url }: IRequest): Promise<any> =>
      initialAxios.get(`${url}`).catch((error) => ({ hasError: true, ...error })),
    put: ({ url, body, headers }: IRequest): Promise<any> =>
      initialAxios.put(`${url}`, body, { headers }).catch((error) => ({
        hasError: true,
        ...error,
      })),
  };
};

export default Api;
