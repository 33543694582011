import React from 'react';
import { Button as HButton, ButtonProps as HButtonProps } from '@hexa-ui/components';
import { useStyles } from './Button.styles';

export type ButtonProps = HButtonProps & { id: string };

const Button = ({
  children,
  variant,
  size,
  elevated,
  leading,
  trailing,
  icon,
  isLoading,
  id,
  onClick,
  disabled,
  style,
}: ButtonProps): JSX.Element => {
  return (
    <HButton
      id={id}
      data-testid={id}
      variant={variant}
      size={size}
      elevated={elevated}
      leading={leading}
      trailing={trailing}
      icon={icon}
      isLoading={isLoading}
      css={useStyles}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </HButton>
  );
};

export default Button;
