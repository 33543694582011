/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DARK_GRAY } from 'themes/customTheme';
import { useZoneLanguages } from '../../hooks';

const CategoryName = ({ category }: { category: any }): JSX.Element => {
  const { translations, name } = category;
  const { acceptedLanguages } = useZoneLanguages();

  // Get secondary translations
  const secondaryLanguages = translations?.map(
    ({ name: translation }: { name: string }) => translation
  );
  const isSecondaryNameVisible =
    !!secondaryLanguages && !!secondaryLanguages.length && acceptedLanguages.length > 1;

  return (
    <>
      <span style={{ whiteSpace: 'nowrap' }} data-testid="default-category-name">
        {name}
      </span>
      {isSecondaryNameVisible && (
        <span
          data-testid="secondary-category-name"
          style={{ color: DARK_GRAY, paddingLeft: 4, whiteSpace: 'nowrap' }}
        >
          {`(${secondaryLanguages.join(', ')})`}
        </span>
      )}
    </>
  );
};

export default CategoryName;
