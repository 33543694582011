import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useNewCategoryContext } from 'context/new-category';
import React, { useEffect, useRef } from 'react';
import { ChangeEvent } from 'react-transition-group/node_modules/@types/react';
import { MarketplaceCategory } from 'types/categories';
import useMultiSelectStyles, { useCheckBoxStyles } from './MultiSelect.styles';

const defaultProps = {
  error: '',
};

type Props = {
  title: string;
  subtitle: string;
  items: { id: string; name: string }[];
  handleSelect: (e: ChangeEvent) => unknown;
  error?: string;
};

const MultiSelect = ({ title, subtitle, items, handleSelect, error }: Props): JSX.Element => {
  const classes = useMultiSelectStyles();
  // @ts-expect-error hasError is always defined
  const checkBoxClasses = useCheckBoxStyles({ error });
  const selectRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const {
    state: { parentCategories: categoriesSelected },
  } = useNewCategoryContext();

  useEffect(() => {
    if (error && items.length) {
      selectRef.current.focus();
    }
  }, [error, items]);

  const isCategorySelected = (id: string | undefined): boolean => {
    return !!(id && categoriesSelected.some((category: MarketplaceCategory) => category.id === id));
  };

  return (
    <>
      <div className="topLevelCategories">
        <Typography
          id="topLevelCategoriesTitle"
          variant="h6"
          style={{ paddingTop: '0.8rem', fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Typography
          id="topLevelCategoriesSubtitle"
          variant="subtitle1"
          style={{ fontSize: '12px', lineHeight: '1.2rem' }}
        >
          {subtitle}
        </Typography>
        <FormControl required error={!!error} component="fieldset" className={classes.formControl}>
          {error && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormHelperText data-testid="text-field-helper-text">
                <Error style={{ fontSize: 'inherit', marginRight: 4 }} />
                {error}
              </FormHelperText>
            </div>
          )}
          <FormGroup>
            {items?.length > 0 &&
              items
                .filter((item) => !!item)
                .map((item: { id: string; name: string }, index) => {
                  return (
                    <FormControlLabel
                      inputRef={index === 0 ? selectRef : null}
                      key={item?.id}
                      control={
                        <Checkbox
                          checked={isCategorySelected(item?.id)}
                          id={item?.id}
                          name={item?.name}
                          onChange={handleSelect}
                          disableRipple
                          color="primary"
                          classes={{
                            root: checkBoxClasses.root,
                            checked: checkBoxClasses.checked,
                          }}
                        />
                      }
                      label={item?.name}
                    />
                  );
                })}
            {items?.length <= 0 && <span>No items to display</span>}
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
};

MultiSelect.defaultProps = defaultProps;

export default MultiSelect;
