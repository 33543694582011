import React from 'react';

const CategoryIcon = (): JSX.Element => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Sidemenu / Icon / Categories / 16px</title>
    <g
      id="Sidemenu-/-Icon-/-Categories-/-16px"
      stroke="none"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g id="Group" transform="translate(4.000000, 4.000000)" stroke="#000000">
        <rect id="Rectangle" x="0" y="0" width="6" height="6" rx="1" />
        <rect id="Rectangle" x="10" y="0" width="6" height="6" rx="1" />
        <rect id="Rectangle" x="0" y="10" width="6" height="6" rx="1" />
        <circle id="Oval" strokeLinejoin="round" cx="13" cy="13" r="3" />
      </g>
      <line
        x1="19.5"
        y1="19.5"
        x2="21.5"
        y2="21.5"
        id="Line-3"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CategoryIcon;
