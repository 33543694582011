import React from 'react';
import { IconButton as HIconButton, IconButtonTypes } from '@hexa-ui/components';
import { Icon } from 'components/Icons';

type Props = {
  id: string;
  name: string;
  type?: string;
  onClickButton?(e?: any): void;
  variant?: 'tertiary' | 'inherit';
} & Omit<IconButtonTypes, 'icon' | 'variant' | 'onClick'>;

const IconButton = ({
  id,
  name,
  variant = 'tertiary',
  onClickButton,
  size,
  elevation,
  loading,
  disabled,
  className,
  ...props
}: Props): JSX.Element => {
  return (
    <HIconButton
      id={id}
      data-testid={id}
      data-cy={`remove-button-${name}`}
      icon={() => <Icon id={`icon-button-icon-${id}`} name={name} />}
      variant={variant}
      onClick={onClickButton}
      size={size}
      elevation={elevation}
      loading={loading}
      disabled={disabled}
      className={className}
      {...props}
    />
  );
};

export default IconButton;
