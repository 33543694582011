import { useTheme } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import CategoryListItem from 'components/CategoryListItem/CategoryListItem';
import React, { FC } from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { MarketplaceCategory } from 'types';

import { BLUE } from '../../themes/customTheme';
import userSortableItemStyle from './SortableItem.style';

export type ItemProps = {
  categories: MarketplaceCategory[];
  handleMove?: (dragIndex: number, hoverIndex: number) => void;
  handleUpdateSubcategories?: (array: MarketplaceCategory[]) => void;
  disabled: boolean;
  handleSelect?: (vendorCategoryId: string) => void;
  selected?: string[];
};

const SortableItem: FC<ItemProps> = ({
  categories,
  handleMove,
  handleUpdateSubcategories,
  disabled,
  handleSelect,
  selected,
}) => {
  const theme = useTheme();
  const styles = userSortableItemStyle();

  /* istanbul ignore next */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !handleMove) {
      return;
    }

    handleMove(result.source.index, result.destination.index);
  };

  /* istanbul ignore next */
  const setDragIconColor = (snapshot: DraggableStateSnapshot) => {
    if (snapshot.isDragging) {
      return BLUE;
    }
    return theme.palette.primary.main;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {categories?.map((category, index) => (
              <Draggable
                key={category.id}
                data-testid={category?.id || 'sortable-item-draggable-id'}
                draggableId={category?.id || 'sortable-item-draggable-id'}
                index={index}
              >
                {/* eslint-disable-next-line no-shadow */}
                {(provided, snapshot) => (
                  <div
                    data-testid={`category-column-${index}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div className={styles.container}>
                      <div className={styles.dragIconContainer} {...provided.dragHandleProps}>
                        {!disabled && (
                          <DragIndicator
                            data-testid="row-drag-handle"
                            className={styles.dragIcon}
                            htmlColor={setDragIconColor(snapshot)}
                          />
                        )}
                      </div>
                      <CategoryListItem
                        data-testid="category-item"
                        key={category.id}
                        category={category}
                        parentId={category.id}
                        level={category.level}
                        setUpdatedSubcategories={handleUpdateSubcategories}
                        handleSelect={handleSelect}
                        selected={selected}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableItem;
