import TranslationKeys from '../i18n.d';

const PORTUGUESE_TRANSLATIONS: TranslationKeys = {
  Default: {
    ok: 'Ok',
    disabled: 'Desabilitada',
    clear: 'Limpar',
    enabled: 'Habilitada',
    categories: 'Categorias',
    product: 'Produto',
    saveChanges: 'Salvar alterações',
    cancelChanges: 'Cancelar alterações',
    goBack: 'Voltar',
    cancelAffirmation: 'Sim, cancelar',
    deleteAffirmation: 'Sim, deletar',
    cancel: 'Cancelar',
    details: 'Detalhes',
    create: 'Criar',
    discard: 'Descartar',
    level: 'Nível',
    requiredField: 'Este campo é obrigatório',
    rowsPerPage: 'Linhas por página',
    search: 'Buscar',
    categoryPath: 'Caminho(s) da categoria',
    name: 'Nome',
    edit: 'Editar',
    assign: 'Atribuir',
    microserviceID: 'ID do microsserviço',
    selectedProduct: 'produto',
    selectedProducts: 'produtos',
    status: 'Status',
    position: 'Posição',
    loading: 'Carregando',
    confirm: 'Confirmar',
    remove: 'Remover',
    reorder: 'Reordenar',
    of: 'de',
    newPosition: 'Nova posição',
    currentPosition: 'Posição atual',
    category: 'Categoria',
    filter: 'FILTRAR',
    apply: 'Aplicar',
    gotIt: 'Entendi',
  },
  Menu: {
    categories: 'Categorias',
    products: 'Produtos',
  },
  CategoryTypes: {
    default: 'Predefinido',
    premium: 'Premium',
    club: 'Club B Max',
  },
  EditCategory: {
    alertMessageErrorCategoryLevel:
      'Esta categoria contém subcategorias e produtos, mas só pode exibir as subcategorias. Remova todos os produtos desta categoria e atribua-os às subcategorias.',
    alertMessageErrorCategoryLevelProductTable:
      'Estes produtos não estão sendo exibidos ao cliente porque o BEES Customer prioriza as subcategorias. Por favor, desvincule todos os produtos desta categoria e certifique-se de que eles estejam atribuídos às suas subcategorias.',
    alertMessageErrorAction: 'Veja os produtos',
  },
  Tooltips: {
    tooltipTrashCategoryEnabled: 'Você precisa desabilitar a categoria para excluí-la.',
    tooltipTrashCategoryDisabled: 'Excluir categoria.',
    tooltipCategoryType: 'O tipo de programa em que esta Categoria está inscrita.',
    tooltipPremium:
      'A Chave Premium vinculará essa categoria a uma determinada parcela de usuários.',
    tooltipPosition:
      'Escolha se esta categoria aparecerá primeiro (superior) ou por último (inferior) na lista de categorias.',
    tooltipStatus: 'Escolha se essa categoria deve ficar visível para os usuários.',
    tooltipGroup:
      'Escolha se a categoria pertence à categorias de navegação regular ou coleções especiais.',
    tooltipParentCategories: 'A categoria ou categorias que vão carregar esta subcategoria.',
    tooltipCategoryPath: 'A seção que leva a esta categoria',
    tooltipCategoryName: 'Como aparece no BEES Customer App versão {{0}}',
    tooltipCreateNewCategory: 'Criar nova categoria',
    tooltipAlreadyAssigned: 'Este produto já está atribuído a esta categoria',
    tooltipExpirationClose: 'Este produto está perto de expirar e tem um desconto especial.',
    tooltipExpirationGood:
      'Este produto tem um bom tempo até o vencimento e tem um desconto moderado.',
    tooltipExpirationFar: 'Este produto está longe de expirar.',
    tooltipExpirationNoInfo:
      'Não há informações disponíveis sobre a data de validade deste produto.',
    tooltipStoreCategoryId:
      'Este campo permite que esta categoria também seja gerenciada via integração com relay',
    tooltipNotFoundSku:
      'Não há registro do SKU deste produto. Em vez disso, estamos exibindo o ID do item do fornecedor.',
  },
  CategoryHome: {
    confirmAssignments: 'Confirmar atribuições',
    subcategoriesInCategory: 'Número de subcategorias nesta categoria',
    title: 'Categorias',
    selectCountry: 'Selecione um país',
    newCategory: 'Nova categoria',
    editPosition: 'Editar posições',
    productsInCategory: 'Número de produtos nesta categoria.',
    subcategoriesWontShow:
      'Este é o número de produtos desta categoria que não está em nenhuma das suas subcategorias. Este número não aparecerá para os usuários.',
    subcategoriesInside: 'Número de subcategorias nesta categoria',
    wantToCancel: 'Tem certeza de que deseja cancelar todas as alterações feitas nas categorias?',
    confirmChangesPositions: 'Você confirma as alterações nas Posições das categorias?',
    successUpdateCategories: 'Você atualizou com sucesso os detalhes desta categoria!',
    selectStore: 'Selecione um estabelecimento',
    activeStore: 'Ativo',
    inactiveStore: 'Inativo',
    inExperimentationStore: 'Em experimentação',
    premiumCategory:
      'Esta é uma Categoria Premium. Ela aparecerá apenas para uma parte determinada de clientes.',
    clubBProgram:
      'Esta categoria só será exibida para clientes cadastrados no programa Club B Max.',
    noSubCategories: 'Esta categoria não tem subcategorias',
    noProductsAssigned: 'Esta categoria não possui produtos atribuídos a ela.',
    successfulUpdatedCategories: 'Você atualizou suas categorias com sucesso!',
    noCategories: 'Ainda não há categorias criadas.',
    noCollections: 'Ainda não há coleções criadas.',
    createNew: 'Gostaria de <2>criar uma nova</2>?',
    categorySelected: 'categoria selecionada',
    categoriesSelected: 'categorias selecionadas',
    categoriesTheseEnabled: 'Habilitar estas categorias',
    categoriesTheseDisabled: 'Desabilitar estas categorias',
    toEnabled: 'A ser habilitada',
    toDisabled: 'A ser desabilitada',
    bulkStatusOptionDefault: 'Alterar status para',
    bulkStatusOptionEnabled: 'Habilitada',
    bulkStatusOptionDisabled: 'Desabilitada',
  },
  CategoryCreation: {
    title: 'Nova Categoria',
    discardCreation: 'Descartar criação',
    discardCreationConfirmation:
      'Você realmente deseja descartar a criação desta categoria e perder todo o progresso?',
    confirmCreation: 'Confirme a criação de categorias',
    confirmCreationMessage:
      'Você confirma a criação desta categoria? Lembre-se que você sempre pode voltar para editar detalhes e imagens, e também gerenciar produtos.',
    submitCategory: 'Envia informações e atributos requeridos para criação da categoria',
    mainInformation: 'Informação principal',
    categoryName: 'Nome da categoria',
    insertName: 'Insira o nome aqui...',
    categoryDetails: 'Detalhes da categoria',
    categoryType: 'Tipo da categoria',
    categoryGroup: 'Grupo',
    categoryLevel: 'Nível da Categoria',
    categoryStatus: 'Status',
    categoryStatusHint: 'Você só pode ter três coleções habilitadas por vez.',
    categoryPosition: 'Posição',
    top: 'Topo',
    bottom: 'Base',
    insertKey: 'Insira uma chave válida...',
    selectAllCategories: 'Selecione todas as categorias aplicáveis.',
    selectAllCategoriesTo: 'Selecione todas as categorias que se aplicam ao seus {{0}}.',
    atLeastOneCategory: 'Você precisa selecionar pelo menos uma categoria',
    categoryList: 'Lista de categoria',
    parentCategories: 'Categorias Pai',
    noCategories: 'Sem categorias para mostrar.',
    premiumKey: 'Chave Premium',
    storeCategoryId: 'ID da categoria da loja',
    insertValue: 'Insira um valor aqui...',
    maxCollectionsEnabledError: 'Você já tem três coleções habilitadas.',
  },
  Products: {
    title: 'Produtos',
    addProducts: 'Adicionar Produtos',
    assignedProducts: 'Produtos Atribuídos',
    assignProduct: 'Atribuir Produtos',
    noProducts: 'Não existem produtos adicionados no momento.',
    noResults: 'Nenhum resultado foi encontrado. Tente pesquisar por um produto diferente.',
    invalidSearch: 'Nenhum produto para atribuir foi pesquisado ainda.',
    confirmProductsAssigned: 'Você confirma as alterações feitas nos produtos atribuídos?',
    cancelProductsAssigned:
      'Tem certeza de que deseja cancelar as alterações nos produtos atribuídos?',
    productList: 'Lista de Produtos',
    productListSubtitle: 'Gerencie suas atribuições marcando ou desmarcando produtos desta lista.',
    searchBySKU: 'Busque produtos usando enter ou vírgula para separar SKUs',
    newSelectedProduct: 'novo produto selecionado',
    newSelectedProducts: 'novos produtos selecionados',
    reorderTitle: 'Reordenar',
    reorderSubtitle: 'Informe a nova posição deste produto',
    allVendors: 'Todos os fornecedores',
    searchBySingleSKU: 'Buscar por SKU',
    categorized: 'Produtos categorizados',
    uncategorized: 'Produtos sem categoria',
  },
  ProductsTable: {
    headerPosition: 'Posição',
    headerProductTitle: 'Título do produto',
    headerSKU: 'Número do SKU',
    headerVendor: 'Fornecedor',
    headerExpiration: 'Grupo de Validade',
    headerStatus: 'Status',
    headerCategories: 'Categorias',
    loading: 'Carregando produtos',
    disabled: 'Desabilitado',
    enabled: 'Habilitado',
    deleted: 'Deletado',
    deletedDescription: 'Produto excluído. Remova-o desta categoria.',
    notFoundDescription: 'Este produto não foi registrado ainda.',
    notFound: 'Não encontrado',
    zeroCategories:
      'Esse produto não está sendo mostrado para nenhuma POCs, pois ele não está associado à categoria.',
    categories: 'Categorias',
    iconXTooltip: 'Remova o produto desta categoria',
    confirmUnassign: 'Sim, remova',
    unassignProduct: 'Remover produto?',
    unassignProductMessage: 'Você está prestes a remover {{0}} de {{1}}',
  },
  CategoryDetails: {
    confirmAllChanges: 'Você confirma todas as alterações feitas nos detalhes desta categoria?',
    cancelAllChanges:
      'Tem certeza de que deseja cancelar todas as alterações feitas nas categorias?',
    enableCategory: 'Habilitar categoria',
    disableCategory: 'Desabilitar categoria',
    enableCategoryMessage:
      'Depois de habilitá-la, esta categoria ficará visível e acessível a todos os clientes se toda a sua filial também estiver habilitada.',
    disableCategoryMessage:
      'Depois de desativá-la, os clientes não poderão ver ou acessar essa categoria e suas subcategorias, a menos que você a habilite novamente.',
    invalidPremiumKey: 'Valor não é permitido',
  },
  ImageContext: {
    uploadIcon: 'Subir ícone (Opcional)',
    uploadImage: 'Subir imagem (Opcional)',
    iconsDesc:
      'Os ícones devem estar no formato JPG ou PNG e não podem exceder 1 MB. Veja nossas instruções.',
    imagesDesc:
      'As imagens devem estar no formato JPG ou PNG e não podem exceder 1 MB. Veja nossas instruções.',
    deleteImage: 'Deletar imagem?',
    deleteImageConfirmation: 'Tem certeza de que deseja deletar a imagem atual?',
    uploadGuide: 'Siga estas recomendações para subir as imagens da categoria corretamente:',
    resolutionRecommendation: 'Resoluções recomendadas: {{0}} or {{1}}',
    alertMessage:
      'Tenha certeza que você está subindo uma imagem (JPG or PNG) que tenha menos de 2MB de tamanho.',
    needHelp: 'Precisando de ajuda?',
  },
  Messages: {
    wontAffect: 'Este processo não irá afetar atribuições anteriores',
    confirmationAssignments:
      'Você está associando {{0}} para as categorias abaixo. Você confirma estas atribuições?',
  },
  Toasts: {
    errorGetStores: 'Não foi possível obter as lojas. Por favor, tente novamente.',
    errorProducts: 'Não foi possível obter os produtos. Por favor, tente novamente.',
    errorUpdateCategory: 'Não foi possível atualizar sua categoria. Por favor, tente novamente.',
    errorCreateCategory: 'Não foi possível criar sua categoria. Tente novamente.',
    successfulCreateCategory: 'Tudo certo! Você criou uma nova categoria.',
    successfulUpdatedCategoryThumbnail: 'Você atualizou com sucesso o thumbnail desta categoria!',
    successfulAssignProducts: 'Você atualizou com sucesso sua lista de produtos atribuídos!',
    errorAssignProducts: 'Não foi possível atualizar sua lista de produtos atribuídos.',
    successfulAssignment: 'Tudo certo! Você atualizou as atribuições do produto.',
    errorAssignment: 'Sua atualização de atribuição falhou. Por favor, tente novamente.',
    successUnassignProduct: 'Você removeu {{0}} de {{1}} com sucesso',
    infoUnassignProduct: 'Removendo {{0}} de {{1}}. Isso pode levar alguns segundos.',
    errorUnassignProduct: 'Não foi possível remover o produto. Por favor, tente novamente.',
    successDeleteCategory: 'Você excluiu sua categoria com sucesso.',
    errorDeleteCategory: 'A exclusão da sua categoria falhou. Por favor, tente novamente.',
  },
  Alerts: {
    infoAllVendors: `Somente um SKU pode ser pesquisado por vez quando 'Todos os fornecedores' está selecionado.`,
  },
  Search: {
    singleSearchPlaceholder: 'Insira um SKU',
  },
  CategoryGroups: {
    fieldTitle: 'Grupo',
    fieldTooltip:
      'Se a categoria pertencer à categoria regular de navegação ou especial de coleção',
    NAVIGATION: 'Navegação',
    COLLECTION: 'Coleção',
  },
  CollectionGuideline: {
    collectionPage: 'Página de coleção',
    exploreProducts: 'Explorar produtos',
    recommendationMessage:
      'Siga estas recomendações para fazer upload das imagens da coleção corretamente:',
    recommendedResolution: 'Resolução recomendada: 296x296px',
    recommendedFormat: 'Formato recomendado: PNG',
    recommendedSize: 'Tamanho máximo da imagem: 2MB',
  },
  UseCategoryToggle: {
    toastError: 'Você não pode habilitar esta categoria porque já tem três coleções habilitadas.',
  },
  UseCategoryHome: {
    toastError: 'Você não pode ter mais de três coleções habilitadas ao mesmo tempo.',
  },
};

export default PORTUGUESE_TRANSLATIONS;
