import * as Grid from '@admin-portal-shared-components/grid';
import React from 'react';

const AppContainer = ({ children }: { children: JSX.Element }): JSX.Element => (
  <Grid.Container
    style={{
      rowGap: '1rem',
    }}
    type="fluid"
    sidebar
  >
    <div style={{ padding: '1.5rem 0', width: '100%' }}>{children}</div>
  </Grid.Container>
);

export default AppContainer;
