import React from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: React.ReactElement;
  children: React.ReactElement;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): JSX.Element =>
  condition ? React.createElement(wrapper.type, wrapper.props, children) : children;

export default ConditionalWrapper;
