import { DELETED_GRAY, DISABLED_RED, ENABLED_GREEN, NOT_FOUND_GRAY } from 'themes/customTheme';
import { ItemAPIResponse, DataStatus } from 'types';

type FormatStatusParams = {
  enabled?: boolean;
  deleted?: boolean;
  notFound?: boolean;
};

// eslint-disable-next-line no-shadow
export enum Status {
  DELETED = 'deleted',
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  NOT_FOUND = 'notFound',
  DEFAULT = 'statusDefault',
}

/**
 * Class that format data to display lists of products or categories
 */
export class ListDataDTO {
  /**
   * Create status that keeps the translation and color
   * @param enabled tells if a data is enabled
   * @param deleted tells if a data is deleted
   * @param translation the main name of the translation path
   */
  static formatStatus = (
    { enabled, deleted, notFound }: FormatStatusParams,
    translation: string
  ): DataStatus => {
    if (deleted) {
      return {
        translation: `${translation}.${Status.DELETED}`,
        color: DELETED_GRAY,
        description: `${translation}.deletedDescription`,
        className: Status.DELETED,
      };
    }
    if (enabled) {
      return {
        translation: `${translation}.${Status.ENABLED}`,
        color: ENABLED_GREEN,
        description: '',
        className: Status.DEFAULT,
      };
    }
    if (notFound) {
      return {
        translation: 'ProductsTable.notFound',
        color: NOT_FOUND_GRAY,
        description: 'ProductsTable.notFoundDescription',
        className: Status.NOT_FOUND,
      };
    }

    return {
      translation: `${translation}.${Status.DISABLED}`,
      color: DISABLED_RED,
      description: '',
      className: Status.DEFAULT,
    };
  };

  /**
   * Remove the passed property from the passed object
   * @param data the current object to be changed
   * @param key the property key to be deleted
   */
  static removeProperty = (data: any, key: keyof ItemAPIResponse) => {
    delete data[key];
  };
}
