import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { FormControl, InputAdornment, OutlinedInput, Button as MUIButton } from '@material-ui/core';
import { Icon, IconsNames } from 'components/Icons';
import { Button, ButtonProps, GridContainer, GridItem } from 'components';
import { useStyles, useStylesProductsPage } from './SingleSearchField.styles';

type Props = {
  id: string;
  btnText: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  onClear: () => void;
  clearText: string;
  isProductPage?: boolean;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  variant?: ButtonProps['variant'];
};

const SingleSearchField = ({
  id,
  btnText,
  onSearch,
  placeholder = '',
  onClear,
  clearText,
  isProductPage = false,
  inputValue,
  setInputValue,
  variant = 'primary',
}: Props): JSX.Element => {
  const stylesProductPage = useStylesProductsPage();
  const stylesDefault = useStyles();

  const styles = isProductPage ? stylesProductPage : stylesDefault;
  const [value, setValue] = useState('');

  const getInputValue = () => {
    return inputValue ?? value;
  };

  const handleInputValue = (input: string) => {
    if (setInputValue) {
      setInputValue(input);
    } else {
      setValue(input);
    }
  };

  const handleSearch = () => {
    onSearch(getInputValue());
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.target;
    const trimmedValue = newValue?.trim();
    handleInputValue(trimmedValue);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const ENTER = 'Enter';

    if (e.key === ENTER) handleSearch();
  };

  const handleClear = () => {
    handleInputValue('');
    onClear();
  };

  return (
    <GridContainer className={styles.container}>
      <FormControl className={styles.form}>
        <GridItem className={styles.gridItem}>
          <OutlinedInput
            id={id}
            className={styles.outlinedInput}
            inputProps={{
              className: styles.input,
              'data-testid': id,
            }}
            value={getInputValue()}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                <Icon id="single-search-field_search-icon" name={IconsNames.SEARCH} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment className={styles.clearButton} position="end">
                {!!getInputValue().length && (
                  <MUIButton data-testid="single-search-field_clear-button" onClick={handleClear}>
                    {clearText}
                  </MUIButton>
                )}
              </InputAdornment>
            }
          />
        </GridItem>

        <GridItem>
          <Button
            id={`${id}-button`}
            data-testid={`${id}-button`}
            onClick={handleSearch}
            variant={variant}
          >
            {btnText}
          </Button>
        </GridItem>
      </FormControl>
    </GridContainer>
  );
};

export default SingleSearchField;
