import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button } from 'components';
import {
  Button as MUIButton,
  Chip,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useMultipleSearchFieldStyles } from './MultipleSearchField.style';

type Props = {
  placeholder?: string;
  onSearch: (searchItems: { id: string; value: string }[]) => void;
  onClear?: () => void;
  style?: CSSProperties;
  inputValue?: string;
  setInputValue?: (value: string) => void;
};

const MultipleSearchField = ({
  placeholder,
  onSearch,
  style,
  inputValue,
  setInputValue,
}: Props): JSX.Element => {
  const classes = useMultipleSearchFieldStyles();
  const { t: translate } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [searchItems, setSearchItems] = useState<{ id: string; value: string }[]>([]);

  const getInputValue = () => {
    return inputValue ?? searchValue;
  };

  const handleInputValue = (input: string) => {
    if (setInputValue) {
      setInputValue(input);
    } else {
      setSearchValue(input);
    }
  };

  const addSearchItem = () => {
    if (getInputValue().trim().length) {
      const newSearchItems = [...searchItems, { id: uuidv4(), value: getInputValue() }];
      handleInputValue('');
      setSearchItems(newSearchItems);
    }
  };

  const removeSearchItem = () => {
    const newItems = [...searchItems];
    newItems.pop();
    setSearchItems(newItems);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const SEPARATORS = ['Enter', ' ', ','];
    const DELETE = ['Backspace'];

    const { key } = event;
    if (SEPARATORS.includes(key)) {
      event.preventDefault();
      addSearchItem();
    }

    if (DELETE.includes(key) && !getInputValue().length) {
      removeSearchItem();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isPasted = value.length - getInputValue().length > 1;
    if (isPasted) {
      addPastedSearchItems(value);
    } else {
      handleInputValue(value);
    }
  };

  const extractItems = (input: string) => {
    return _.split(input, /[,\s]/g)
      .filter((item) => !!item.length)
      .map((item) => ({ id: uuidv4(), value: item }));
  };

  const addPastedSearchItems = (input: string) => {
    setSearchItems([...searchItems, ...extractItems(input)]);
  };

  const handleClear = (id: string) => {
    const newSearchItems = searchItems.filter((s) => s.id !== id);
    setSearchItems(newSearchItems);
  };

  const handleClearAll = useCallback(() => {
    setSearchItems([]);
    handleInputValue('');
    onSearch([]);
  }, [onSearch]);

  const handleClick = () => {
    const newSearchItems = [...searchItems];
    addSearchItem();
    onSearch(newSearchItems);
  };

  useEffect(() => {
    addSearchItem();
  }, []);

  return (
    <div className={classes.container} style={{ ...style }}>
      <FormControl variant="outlined" fullWidth style={{ marginRight: 16 }}>
        <OutlinedInput
          multiline
          className={classes.form}
          inputProps={{
            className: classes.input,
            'data-testid': 'multiple-search-field',
          }}
          value={getInputValue()}
          onChange={handleChange}
          startAdornment={
            <>
              <InputAdornment position="start" style={{ margin: 0 }}>
                <Search className={classes.searchIcon} />
              </InputAdornment>
              {searchItems.map(({ id, value: label }) => (
                <Chip
                  className={classes.chip}
                  data-testid="search-item-chip"
                  key={id}
                  label={label}
                  onDelete={() => handleClear(id)}
                  deleteIcon={<Clear fontSize="small" className={classes.chipClear} />}
                />
              ))}
            </>
          }
          {...(searchItems.length && {
            endAdornment: (
              <InputAdornment className={classes.clearButton} position="end">
                <MUIButton data-testid="search-clear-all" onClick={handleClearAll}>
                  {translate('Default.clear')}
                </MUIButton>
              </InputAdornment>
            ),
          })}
          onKeyDown={handleKeyDown}
          {...(!searchItems.length && { placeholder })}
        />
      </FormControl>
      <Button
        id="item-search-button"
        data-testid="item-search-button"
        onClick={handleClick}
        variant="primary"
      >
        {translate('Default.search')}
      </Button>
    </div>
  );
};

export default MultipleSearchField;
