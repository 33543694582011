import { useLogService } from 'admin-portal-shared-services';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error): void {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLogService().error(error);
  }

  render(): React.ReactNode {
    const { children } = this.props;

    return children;
  }
}
