import Api from 'Api/Api';
import GET_API_ENDPOINTS from '../../constants/services';

const uploadFile = async (file: File, env: string): Promise<string> => {
  const formData = new FormData();
  formData.append('File', file);
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  const cmsResp = await Api().post({
    url: API_ENDPOINTS.uploadImage,
    body: formData,
  });

  const envEdgeCases = ['qa', 'sit'];
  return envEdgeCases.includes(env.toLowerCase())
    ? cmsResp.data.sit
    : cmsResp.data[env.toLowerCase()];
};

export default uploadFile;
