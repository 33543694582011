import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVendorContext } from 'context/vendor-context';
import { ALL_VENDORS } from 'hooks/useVendors';
import { MultipleSearchField, SingleSearchField } from '../index';

type SearchProps = {
  handleSearch: (searchItems: { id: string; value: string }[]) => void;
  placeholder: string;
  style?: CSSProperties;
} & typeof defaultProps;

const defaultProps = {
  style: {},
};

const SearchField = ({ handleSearch, placeholder, style }: SearchProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const {
    state: { selectedVendor },
  } = useVendorContext();
  const isAllVendorsSelected = selectedVendor?.vendorId === ALL_VENDORS;
  const [inputValue, setInputValue] = useState('');

  const handleSearchBySKU = (searchValue: string) => {
    handleSearch([{ id: '', value: searchValue }]);
  };

  const isProductsPage = true;

  useEffect(() => {
    if (selectedVendor && isAllVendorsSelected) {
      handleSearch([]);
    }
  }, [selectedVendor, isAllVendorsSelected, handleSearch]);

  return isAllVendorsSelected ? (
    <SingleSearchField
      id="single-search-field"
      btnText={translate('Default.search')}
      placeholder={translate('Products.searchBySingleSKU')}
      onSearch={handleSearchBySKU}
      clearText={translate('Default.clear')}
      onClear={() => handleSearch([])}
      isProductPage={isProductsPage}
      inputValue={inputValue}
      setInputValue={setInputValue}
    />
  ) : (
    <MultipleSearchField
      placeholder={placeholder}
      onSearch={handleSearch}
      style={style}
      inputValue={inputValue}
      setInputValue={setInputValue}
    />
  );
};

SearchField.defaultProps = defaultProps;

export default SearchField;
