/* istanbul ignore file */

import update from 'immutability-helper';

const useSort = (): {
  handleSort: (list: unknown[], dragIndex: number, hoverIndex: number) => any;
} => {
  // Using immutability-helper update method to optimize performance
  // https://www.npmjs.com/package/immutability-helper#update
  const handleSort =
    // eslint-disable-next-line max-params
    (list: unknown[], dragIndex: number, hoverIndex: number) => {
      const dragCategory = list[dragIndex];

      return update(list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCategory],
        ],
      });
    };

  return { handleSort };
};

export default useSort;
