import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  useFeatureToggleServiceV2 as featureToggleV2Service,
  useAuthenticationService as authenticationService,
} from 'admin-portal-shared-services';
import { useStoreContext } from './store-context';
import toggles from '../toggles.config.json';
import { useZoneContext } from './zone-context';

export type ToggleState = {
  useStoreCategoryIdToggle?: boolean;
  filterByAllVendors?: boolean;
  useSwimlane?: boolean;
  useCategoriesColumn?: boolean;
  bulkUpdateStatus?: boolean;
  searchAllItems?: boolean;
  searchUncategorized?: boolean;
  collectionActive?: boolean;
  retrocompabilityActive?: boolean;
  deleteCategoryActive?: boolean;
};

interface Action {
  type: string;
  payload?: ToggleState;
}

const initialState: ToggleState = {
  useStoreCategoryIdToggle: false,
  filterByAllVendors: false,
  useSwimlane: false,
  useCategoriesColumn: false,
  bulkUpdateStatus: false,
  searchAllItems: false,
  searchUncategorized: false,
  collectionActive: false,
  retrocompabilityActive: false,
  deleteCategoryActive: false,
};

type ToggleAttribute = {
  [name: string]: string;
};

type ToggleParams = {
  toggleName: string;
  setToggleStateFunction: (dispatch: React.Dispatch<Action>, newToggleValue: boolean) => void;
  toggleAttribute?: ToggleAttribute;
};

const ToggleContext = createContext<{ state: ToggleState; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => undefined,
});

ToggleContext.displayName = 'ToggleContext';

const toggleReducer = (state: ToggleState, action: Action): ToggleState => {
  const { type, payload } = action;

  if (type === 'update_toggle') {
    return { ...state, ...payload };
  }

  return state;
};

const ToggleProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(toggleReducer, initialState);
  const { children } = props;
  const {
    state: { selectedStore },
  } = useStoreContext();
  const {
    state: { country },
  } = useZoneContext();

  const isFeatureToggleInstanceCreated =
    window.services?.FeatureToggleOptimizelyV2 &&
    // @ts-expect-error allow type any for services object
    window.services?.FeatureToggleOptimizelyV2['admin-portal-browse-category-mfe'];

  const handleFeatureToggle = useCallback(
    ({ toggleName, setToggleStateFunction, toggleAttribute }: ToggleParams) => {
      const featureToggleInstance = featureToggleV2Service('admin-portal-browse-category-mfe');

      featureToggleInstance.onReady().then(() => {
        featureToggleInstance
          .isFeatureEnabledAudiences(
            toggleName,
            authenticationService().getUserId(),
            toggleAttribute
          )
          .then((flagValue) => {
            setToggleStateFunction(dispatch, flagValue);
          });
      });
    },
    []
  );

  useEffect(() => {
    // istanbul ignore else
    if (selectedStore?.storeId && isFeatureToggleInstanceCreated) {
      const toggleAttribute = { store: selectedStore.storeId };

      handleFeatureToggle({
        toggleName: toggles.v2.CAD_BULK_UPDATE_STATUS,
        setToggleStateFunction: setBulkUpdateStatus,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_FILTER_BY_ALL_VENDORS,
        setToggleStateFunction: setFilterByAllVendors,
        toggleAttribute,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_EXPAND_PRODUCT_TABLE_ROW,
        setToggleStateFunction: setSwimlaneFeature,
        toggleAttribute,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_PRODUCTS_CATEGORIES_COLUMN,
        setToggleStateFunction: setCategoriesColumnFeature,
        toggleAttribute,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_SEARCH_ALL_ITEMS,
        setToggleStateFunction: setSearchAllItems,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_FILTER_UNCATEGORIZED,
        setToggleStateFunction: setSearchUncategorized,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_COLLECTION,
        setToggleStateFunction: setCollectionActive,
        toggleAttribute: { zone: country },
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_DELETE_CATEGORY,
        setToggleStateFunction: setDeleteCategoryActive,
      });
      handleFeatureToggle({
        toggleName: toggles.v2.CAD_CATEGORY_GET_GROUPS_RETROCOMPATIBILITY,
        setToggleStateFunction: setRetrocompabilityActive,
        toggleAttribute: { zone: country },
      });
    }
  }, [handleFeatureToggle, isFeatureToggleInstanceCreated, selectedStore, country]);

  return <ToggleContext.Provider value={{ state, dispatch }}>{children}</ToggleContext.Provider>;
};

const useToggleContext = (): { state: ToggleState; dispatch: Dispatch<Action> } => {
  return useContext(ToggleContext);
};

const setUseStoreCategoryIdToggle = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  dispatch({ type: 'update_toggle', payload: { useStoreCategoryIdToggle: newToggleValue } });
};

const setFilterByAllVendors = (dispatch: React.Dispatch<Action>, newToggleValue: boolean): void => {
  setToggleState(dispatch, { filterByAllVendors: newToggleValue });
};

const setSwimlaneFeature = (dispatch: React.Dispatch<Action>, newToggleValue: boolean): void => {
  setToggleState(dispatch, { useSwimlane: newToggleValue });
};

const setCategoriesColumnFeature = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { useCategoriesColumn: newToggleValue });
};

export const setBulkUpdateStatus = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { bulkUpdateStatus: newToggleValue });
};

export const setSearchAllItems = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { searchAllItems: newToggleValue });
};

export const setSearchUncategorized = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { searchUncategorized: newToggleValue });
};

export const setCollectionActive = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { collectionActive: newToggleValue });
};

export const setRetrocompabilityActive = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { retrocompabilityActive: newToggleValue });
};

export const setDeleteCategoryActive = (
  dispatch: React.Dispatch<Action>,
  newToggleValue: boolean
): void => {
  setToggleState(dispatch, { deleteCategoryActive: newToggleValue });
};

const setToggleState = (dispatch: React.Dispatch<Action>, payload: ToggleState): void => {
  dispatch({ type: 'update_toggle', payload });
};

export {
  ToggleProvider,
  ToggleContext,
  setUseStoreCategoryIdToggle,
  setFilterByAllVendors,
  setSwimlaneFeature,
  setCategoriesColumnFeature,
  useToggleContext,
  toggleReducer,
};
