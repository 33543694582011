/* eslint-disable import/prefer-default-export */

interface Item {
  id: string;
  brandId: string;
  brandName: string;
  classification: string;
  container: {
    fullContainerDescription: string;
    itemSize: number;
    name: string;
    returnable: boolean;
    unitOfMeasurement: string;
    material: string;
  };
  createdDate: string;
  deleted: boolean;
  description: string;
  enabled: boolean;
  hidden: boolean;
  isAlcoholic: boolean;
  isNarcotic: boolean;
  palletQuantity: number;
  itemImage: string;
  itemName: string;
  minOrderQuantity: number;
  package: {
    fullPackageDescription: string;
    itemCount: number;
    materialType: string;
    name: string;
    pack: string;
    packageId: string;
    unitCount: number;
  };
  salesRanking: number;
  sku: string;
  subBrandName: string;
  updatedAt: string;
  updatedDate: string;
  vendorId: string;
  upc: string;
  manufacturerId: string;
  hasBeenUpdated: boolean;
  styleType: string;
  distributorProductType: string;
  itemType: string;
  uncategorized: boolean;
  sourceData: {
    vendorItemId: string;
  };
  sortOrder?: number;
}

type ItemSelection = {
  itemPlatformId?: string;
  sortOrder?: number;
  vendorId?: string;
  vendorItemId?: string;
};

// eslint-disable-next-line no-shadow
export enum ExpirationGroup {
  REGULAR = 'Regular',
  YELLOW = 'Yellow',
  ORANGE = 'Orange',
  NOT_FOUND = 'Not found',
}

type ItemAPIResponse = {
  id: string;
  name: string;
  sku: string;
  enabled: boolean;
  vendorItemId: string;
  image: string;
  sortOrder: number;
  itemPlatformId: string;
  vendorName: string;
  vendorId: string;
  expirationGroup: ExpirationGroup;
  deleted: boolean;
};

type ItemsResponse = {
  items: [ItemAPIResponse];
  totalOfItems: number;
};

type DataStatusClassName = 'deleted' | 'notFound' | 'root' | 'statusDefault';

type DataStatus = {
  translation: string;
  color: string;
  description: string;
  className: DataStatusClassName;
};

type CategoriesInfos = {
  path?: string[];
  itemsCount?: number;
  statusColor?: string;
  id?: string;
};

type MarketplaceItem = Omit<ItemAPIResponse, 'enabled' | 'deleted'> & {
  status: DataStatus;
  categoriesInfos?: CategoriesInfos[];
};

export type {
  Item,
  ItemSelection,
  MarketplaceItem,
  ItemsResponse,
  DataStatus,
  ItemAPIResponse,
  CategoriesInfos,
};
