import { Badge as MuiBadge, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import Tooltip from './Tooltip';

type Props = {
  colors: {
    defaultColor: string;
    hover?: string;
  };
  tooltipContent: string;
  value: number | string | ReactNode;
};

const useBadgeStyles = makeStyles({
  root: ({ defaultColor, hover }: { defaultColor: string; hover?: string }) => {
    return {
      '& > span:hover': {
        backgroundColor: hover || defaultColor,
      },
      '& > span': {
        height: '20px',
        width: '17px',
        color: 'rgba(0,0,0,0.9)',
        fontFamily: 'Work Sans',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '0.1px',
        lineHeight: '20px',
        textAlign: 'center',
      },
    };
  },
  badge: ({ defaultColor }: { defaultColor: string; hover?: string }) => {
    return {
      backgroundColor: defaultColor,
      // Override for default positioning on Badge
      transform: 'none',
      position: 'relative',
      marginRight: 8,
      marginLeft: 4,
      // Prevent round badge on smaller values
      minWidth: 32,
      '& svg': {
        width: 14,
      },
    };
  },
});

const Badge = ({ colors, value, tooltipContent }: Props): JSX.Element => {
  const classes = useBadgeStyles(colors);

  return (
    <Tooltip message={tooltipContent}>
      <MuiBadge data-testid="generic-badge" classes={classes} badgeContent={value} max={9999} />
    </Tooltip>
  );
};

export default Badge;
