export const APP_NAME = 'CATEGORYMARKETPLACE';

// eslint-disable-next-line no-shadow
export enum AuditLogOperations {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  VIEW = 'VIEW',
}

// eslint-disable-next-line no-shadow
export enum AuditLogEntities {
  ORDERS = 'ORDERS',
  ACCOUNTS = 'ACCOUNTS',
  TICKETS = 'TICKETS',
  CATEGORIES = 'CATEGORIES',
  DDC = 'DDC',
  INVOICES = 'INVOICES',
  ITEMS = 'ITEMS',
  PRODUCTS = 'PRODUCTS',
  REWARDS = 'REWARDS',
  USERS = 'USERS',
  VENDORS = 'VENDORS',
  PRICES = 'PRICES',
  PROMOTIONS = 'REWARDS',
}

// eslint-disable-next-line no-shadow
export enum CategoryPages {
  CATEGORY_DETAILS = 'category_details_page',
  CATEGORY_HOME = 'category_home_page',
  PRODUCTS_PAGE = 'products_page',
  CATEGORY_CREATION = 'category_creation',
}
