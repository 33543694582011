import { gql, useQuery } from '@apollo/client';
import { Store } from 'types/store';
import { setSelectedVendor, setVendors, useVendorContext, Vendor } from 'context/vendor-context';
import { useEffect } from 'react';
import { useUserMetadata } from 'admin-portal-shared-services';
import useZoneLanguages from 'hooks/useZoneLanguages';
import { useTranslation } from 'react-i18next';
import { useToggleContext } from 'context/toggle-context';

export const GET_VENDORS_BY_STORE = gql`
  query getVendorsByStore($store: String, $zone: String) {
    vendorsByStore(store: $store, zone: $zone) {
      vendorId
      displayName
    }
  }
`;

export type VendorsByStoreResponse = {
  vendorsByStore: Array<Vendor>;
};

type UseVendorsResponse = {
  vendors: Array<Vendor>;
  selectedVendor: Vendor | undefined;
  isLoading: boolean;
  isAllVendorsOptionSelected: boolean;
};

type VendorMetadata = {
  id?: string;
  country?: string;
  displayName?: string;
  serviceModel?: string;
  governmentId?: string;
  abiVendorId?: string;
};

export const ALL_VENDORS = 'all-vendors';

const getVendorsIdsBySelectedZone = (
  userMetadataVendors: VendorMetadata[] | undefined,
  selectedZone: string
): Array<string> => {
  if (!userMetadataVendors || userMetadataVendors?.length === 0) return [];

  return userMetadataVendors
    .filter((vendor: VendorMetadata) => vendor.country === selectedZone)
    .map((vendor: VendorMetadata) => vendor.id as string);
};

const getZoneManagerAllowedVendors = (
  vendorsResponse: VendorsByStoreResponse,
  vendorsIdsBySelectedZone: Array<string>
) => {
  return vendorsResponse.vendorsByStore.filter((vendor) =>
    vendorsIdsBySelectedZone.includes(vendor.vendorId as string)
  );
};

const useVendors = (store: Store | undefined): UseVendorsResponse => {
  const {
    state: { vendors, selectedVendor },
    dispatch,
  } = useVendorContext();
  const {
    state: { filterByAllVendors },
  } = useToggleContext();
  const { t: translate } = useTranslation();

  const { data: userMetadata } = useUserMetadata();
  const isGlobalVendorManager = userMetadata?.authorization?.scopes?.includes(
    'CategoryAdmin.Vendor.All'
  );

  const { country: selectedZone, countryCodeForExternalServices } = useZoneLanguages();

  const userMetadataVendors = userMetadata?.vendors;

  const { data, loading: isLoading } = useQuery<VendorsByStoreResponse>(GET_VENDORS_BY_STORE, {
    notifyOnNetworkStatusChange: true,
    variables: {
      store: store?.storeId,
      zone: selectedZone,
    },
    skip: !store?.storeId,
  });

  const isAllVendorsOptionSelected = () => {
    return selectedVendor?.vendorId === ALL_VENDORS;
  };

  useEffect(() => {
    const allVendorOption = {
      vendorId: ALL_VENDORS,
      displayName: translate('Products.allVendors'),
    };
    if (data && data.vendorsByStore) {
      if (isGlobalVendorManager) {
        const allVendors = filterByAllVendors
          ? [allVendorOption, ...data.vendorsByStore]
          : data.vendorsByStore;
        setVendors(dispatch, allVendors);
      } else {
        const vendorsIdsBySelectedZone = getVendorsIdsBySelectedZone(
          userMetadataVendors,
          countryCodeForExternalServices
        );

        const allowedVendors = getZoneManagerAllowedVendors(data, vendorsIdsBySelectedZone);
        const allVendors = filterByAllVendors
          ? [allVendorOption, ...allowedVendors]
          : allowedVendors;
        setVendors(dispatch, allVendors);
      }
    }
  }, [data, dispatch, filterByAllVendors]);

  useEffect(() => {
    const getDefaultVendor = () => {
      if (!store?.defaultVendorId || !vendors || vendors.length === 0) return undefined;
      const defaultVendor = vendors.find((vendor) => vendor.vendorId === store?.defaultVendorId);
      const firstAvailableVendor = vendors.find((vendor) => vendor.vendorId !== ALL_VENDORS);
      return defaultVendor || firstAvailableVendor;
    };

    const defaultVendor = getDefaultVendor();
    if (defaultVendor) {
      setSelectedVendor(dispatch, defaultVendor?.vendorId);
    }
  }, [vendors, store?.defaultVendorId, dispatch]);

  return {
    vendors,
    selectedVendor,
    isLoading,
    isAllVendorsOptionSelected: isAllVendorsOptionSelected(),
  };
};

export default useVendors;
