import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@material-ui/core';
import { SingleSearchField } from 'components';
import { useToggleContext } from 'context/toggle-context';

type HeaderProps = {
  controls?: JSX.Element;
  hasBottomDivider?: boolean;
  hasSubtitleDivider?: boolean;
  style?: CSSProperties;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
  titleVariant?: 'h4' | 'h6';
  showSearch?: boolean;
  onSearch?: (value: string) => void;
  onClear?: () => void;
};
const defaultProps = {
  controls: null,
  hasBottomDivider: false,
  hasSubtitleDivider: false,
  style: {},
  subtitle: '',
  titleVariant: 'h4',
  showSearch: false,
};

const Header = ({
  controls,
  style,
  subtitle,
  title,
  titleVariant,
  hasBottomDivider,
  hasSubtitleDivider,
  showSearch,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSearch = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClear = () => {},
}: HeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const {
    state: { searchAllItems },
  } = useToggleContext();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...style,
        }}
      >
        <Typography
          variant={titleVariant}
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight: 'auto' }}
        >
          {title}
          {!!subtitle && (
            <Typography style={{ paddingLeft: 4 }} variant="subtitle1">
              {hasSubtitleDivider && <span style={{ paddingRight: 4 }}>/</span>}
              {subtitle}
            </Typography>
          )}
        </Typography>

        {showSearch && searchAllItems && (
          <SingleSearchField
            id="header__single-search-field"
            btnText={translate('Default.search')}
            placeholder={translate('Search.singleSearchPlaceholder')}
            clearText={translate('Default.clear')}
            onSearch={onSearch}
            onClear={onClear}
            variant="secondary"
          />
        )}

        {controls && <>{controls}</>}
      </div>
      {hasBottomDivider && (
        <Divider
          data-testid="header_divider"
          style={{ margin: '32px 0', backgroundColor: '#CCCCCC' }}
        />
      )}
    </>
  );
};

Header.defaultProps = defaultProps;

export default Header;
