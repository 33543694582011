import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import PORTUGUESE_TRANSLATIONS from './languages/pt';
import ENGLISH_TRANSLATIONS from './languages/en';
import KOREAN_TRANSLATIONS from './languages/ko';

export const availableLanguages = {
  shortCodes: ['pt', 'en', 'ko'],
  resources: {
    'pt-BR': {
      translation: PORTUGUESE_TRANSLATIONS,
    },
    'en-US': {
      translation: ENGLISH_TRANSLATIONS,
    },
    'ko-KR': {
      translation: KOREAN_TRANSLATIONS,
    },
  },
};

const i18nInit = (language: string | undefined) => {
  i18next.use(initReactI18next).init({
    fallbackLng: 'en-US',
    lng: language,
    resources: availableLanguages.resources,
  });
};

export default i18nInit;
