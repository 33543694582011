import React, { createContext, useContext } from 'react';
import { useAuthenticationService, useGetCluster } from 'admin-portal-shared-services';
import { Cluster } from 'constants/cluster';
import config from 'countries.config.json';

type UserData = {
  country: string;
  supportedCountries: string[];
  isGlobalManager: boolean;
};

const UserContext = createContext<UserData>({} as UserData);
UserContext.displayName = 'UserContext';

const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const { user_country: country } = useAuthenticationService().getUserCountryAndLanguage();
  const countries = useAuthenticationService().getSupportedCountries();
  const cluster = useGetCluster();
  const zones = getCountriesByCluster(cluster);
  // @ts-expect-error scopes exist on JWT
  const { scopes } = useAuthenticationService().parseJwt();
  const isGlobalManager = Array.isArray(scopes) && scopes.includes('CategoryAdmin.Country.All');
  // TODO find out how to mock scope for isGlobalManager
  // istanbul ignore next
  const supportedCountries = isGlobalManager ? zones : getAllowedZones(zones, countries);

  const value = {
    country,
    supportedCountries,
    isGlobalManager,
  };
  // @ts-expect-error country should never be falsy
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = (): UserData => {
  return useContext(UserContext);
};

const getCountriesByCluster = (cluster: string) => {
  if (cluster === Cluster.DTC) {
    const countries = Object.entries(config);
    const supportedDtc = Object.fromEntries(
      countries.filter((item) => item[1].clusters.find((c) => c === cluster))
    );
    return Object.keys(supportedDtc);
  }
  return Object.keys(config);
};

const getAllowedZones = (zonesByCluster: Array<string>, supportedCountries: Array<string>) => {
  const countries = Object.entries(config);
  const allowedZones = Object.fromEntries(
    countries.filter(
      ([key, countryConfig]) =>
        zonesByCluster.some((zoneByCluster) => zoneByCluster === key) &&
        supportedCountries.some(
          (supportedCountry) => supportedCountry === countryConfig.valueForExternalServices
        )
    )
  );

  return Object.keys(allowedZones);
};

export { UserProvider, useUserContext, UserContext };
