import _ from 'lodash';
/* eslint-disable @typescript-eslint/no-explicit-any */
type Obj = Record<string, never>;

/**
 * getUpdateSortOrders
 * @param originalArray default array of the original items
 * @param updateArray array with items/categories in updated positions/index
 * @returns updatedSortOrders: items whose position changed with correct sortOrder based off index
 */
const getUpdatedSortOrders = (originalArray: any[], updatedArray: any[]): any[] => {
  let updatedSortOrders = <any>[];

  // Checks to see that values have changed
  if (!_.isEqual(originalArray, updatedArray)) {
    updatedSortOrders = updatedArray.map((item: Obj, index: number) => {
      return { ...item, sortOrder: index };
    });
  }

  return updatedSortOrders;
};

export default getUpdatedSortOrders;
